import React from "react";
import instance from "../../instance";
import { authHeader } from "../commonUtility/auth-header";
import MaterialTable, { MTableToolbar, MTableEditRow } from "material-table";
import {
  ExcelViewActionAdd,
  ExcelViewActionUpdate,
  ExcelViewActionDelete,
  GEM_IMPORT,
} from "../commonUtility/string-const";
import moment from "moment";
import PropTypes from "prop-types";
import { 
  isColumnMandatory, 
  returnLookupObject,
  customDropdownFilterAndSearch,
} from "../commonUtility/method-utils";

import NumericInput from "./CustomGridComponents/NumericInput";
import PubSub from "pubsub-js";
import Dropdown from "./CustomGridComponents/Dropdown";
import DateInput from "./CustomGridComponents/DateInput";
import Textbox from "./CustomGridComponents/Textbox";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import _ from "lodash";


export default class GemImport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gemImportList: [],
      selectedRow: null,
      isLoading: false,
      currentPageSize: 10,
      disableAddonEmptySheet: true,
      refreshTable: true,
      gridOptions: {
        search: false,
        actionsColumnIndex: -1,
        showTitle: false,
        addRowPosition: "first",
        columnsButton: true,
        pageSizeOptions: [10, 20, 25],
        emptyRowsWhenPaging: false,
        thirdSortClick: false,
        rowStyle: (rowData) => ({
          backgroundColor:
            !rowData.valid &&
            rowData.highCat !== undefined &&
            parseInt(rowData.highCat) > 3
              ? rowData.tableData.id === this.state.selectedRow
                ? "#F5A9A9"
                : "#F6CECE"
              : rowData.tableData.id === this.state.selectedRow
              ? "#a9f5a9"
              : "#FFF",
        }),
        detailPanelType: "single",
      },
      columns: [],
    };

    this.setColumns();
    this.tableRef = React.createRef();
  }


  setColumns() {
    this.setState({
      columns: [
        { title: "Sr. No.", field: "id", editable: "never" },
        {
          title: "Valid",
          field: "valid",
          editable: "never",
          lookup: {
            true: "true",
            false: "false",
          },
        },
        {
          title: "id_counterpart",
          field: "id_counterpart",
          editComponent: (props) => (
            <NumericInput
              name="id_counterpart"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "id_counterpart")}
              autoFocus={true}
            />
          ),
        },
        {
          title: "id_contract",
          field: "id_contract",
          editComponent: (props) => (
            <NumericInput
              name="id_contract"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "id_contract")}
            />
          ),
        },
        {
          title: "id_default",
          field: "id_default",
          editComponent: (props) => (
            <NumericInput
              name="id_default"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "id_default")}
            />
          ),
        },
        {
          title: "cod_rate_type",
          field: "cod_rate_type",
          editComponent: (props) => (
            <NumericInput
              name="cod_rate_type"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "cod_rate_type")}
            />
          ),
        },
        {
          title: "cod_currency_tra",
          field: "cod_currency_tra",
          editComponent: (props) => (
            <Textbox
              name="cod_currency_tra"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "cod_currency_tra")}
            />
          ),
        },
        {
          title: "id_tranche",
          field: "id_tranche",
          editComponent: (props) => (
            <Textbox
              name="id_tranche"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "id_tranche")}
            />
          ),
        },
        {
          title: "cod_currency_payment",
          field: "cod_currency_payment",
          editComponent: (props) => (
            <Textbox
              name="cod_currency_payment"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "cod_currency_payment")}
            />
          ),
        },
        {
          title: "default_dat",
          field: "default_dat",
          customSort: (a, b) =>
            moment(a.default_dat).format("YYYYMMDD") -
            moment(b.default_dat).format("YYYYMMDD"),
          editComponent: (props) => (
            <DateInput
              name="default_dat"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "default_dat")}
            />
          ),
        },
        {
          title: "amount_due",
          field: "amount_due",
          editComponent: (props) => (
            <NumericInput
              name="amount_due"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "amount_due")}
            />
          ),
        },
        {
          title: "discount_dat",
          field: "discount_dat",
          customSort: (a, b) =>
            moment(a.discount_dat).format("YYYYMMDD") -
            moment(b.discount_dat).format("YYYYMMDD"),
          editComponent: (props) => (
            <DateInput
              name="discount_dat"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "discount_dat")}
            />
          ),
        },
        {
          title: "dat_due",
          field: "dat_due",
          customSort: (a, b) =>
            moment(a.dat_due).format("YYYYMMDD") -
            moment(b.dat_due).format("YYYYMMDD"),
          editComponent: (props) => (
            <DateInput
              name="dat_due"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "dat_due")}
            />
          ),
        },
        {
          title: "base_rate_date",
          field: "base_rate_date",
          customSort: (a, b) =>
            moment(a.base_rate_date).format("YYYYMMDD") -
            moment(b.base_rate_date).format("YYYYMMDD"),
          editComponent: (props) => (
            <DateInput
              name="base_rate_date"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "base_rate_date")}
            />
          ),
        },
        {
          title: "base_rate",
          field: "base_rate",
          editComponent: (props) => (
            <NumericInput
              name="base_rate"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "base_rate")}
            />
          ),
        },
        {
          title: "margin",
          field: "margin",
          editComponent: (props) => (
            <NumericInput
              name="margin"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "margin")}
            />
          ),
        },
        {
          title: "interest_rate",
          field: "interest_rate",
          editComponent: (props) => (
            <NumericInput
              name="interest_rate"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "interest_rate")}
            />
          ),
        },
        {
          title: "dat_paid",
          field: "dat_paid",
          customSort: (a, b) =>
            moment(a.dat_paid).format("YYYYMMDD") -
            moment(b.dat_paid).format("YYYYMMDD"),
          editComponent: (props) => (
            <DateInput
              name="dat_paid"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "dat_paid")}
            />
          ),
        },
        {
          title: "capital_prepaid",
          field: "capital_prepaid",
          editComponent: (props) => (
            <NumericInput
              name="capital_prepaid"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "capital_prepaid")}
            />
          ),
        },
        {
          title: "capital_paid",
          field: "capital_paid",
          editComponent: (props) => (
            <NumericInput
              name="capital_paid"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "capital_paid")}
            />
          ),
        },
        {
          title: "capital_disb",
          field: "capital_disb",
          editComponent: (props) => (
            <NumericInput
              name="capital_disb"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "capital_disb")}
            />
          ),
        },
        {
          title: "interest_paid",
          field: "interest_paid",
          editComponent: (props) => (
            <NumericInput
              name="interest_paid"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "interest_paid")}
            />
          ),
        },
        {
          title: "penalty_paid",
          field: "penalty_paid",
          editComponent: (props) => (
            <NumericInput
              name="penalty_paid"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "penalty_paid")}
            />
          ),
        },
        {
          title: "fees_paid",
          field: "fees_paid",
          editComponent: (props) => (
            <NumericInput
              name="fees_paid"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "fees_paid")}
            />
          ),
        },
        {
          title: "alternative_discount",
          field: "alternative_discount",
          editComponent: (props) => (
            <NumericInput
              name="alternative_discount"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "alternative_discount")}
            />
          ),
        },
        {
          title: "reflow_origin",
          field: "reflow_origin",
          editComponent: (props) => (
            <Dropdown
              name="reflow_origin"
              value={props.value}
              onChange={props.onChange.bind(this)}
              options={
                this.props.paramList === null
                  ? []
                  : this.props.paramList.reflowOriginOptions
              }
              required={isColumnMandatory(GEM_IMPORT, "reflow_origin")}
            />
          ),
          render: (rowData) => <span>{rowData.reflow_origin}</span>,
          lookup:
            this.props.paramList === null
              ? {}
              : returnLookupObject(this.props.paramList.reflowOriginOptions),
          customFilterAndSearch: (term, rowData) =>
            customDropdownFilterAndSearch(term, rowData, "reflow_origin"),
        },
        {
          title: "id_origin",
          field: "id_origin",
          editComponent: (props) => (
            <NumericInput
              name="id_origin"
              value={props.value}
              onChange={props.onChange.bind(this)}
              required={isColumnMandatory(GEM_IMPORT, "id_origin")}
              autoFocus={true}
            />
          ),
        },
      ],
    });
  }

  componentDidUpdate(oldProps) {
    if (Object.keys(this.state.gemImportList).length === 0) {
      this.hidePaging(true);
    } else {
      this.hidePaging(false);
    }

    if (
      oldProps.triggerReload !== this.props.triggerReload &&
      this.props.triggerReload
    ) {
      this.componentDidMount();
    }

    if (!_.isEqual(oldProps.paramList, this.props.paramList)) {
      this.setColumns();
    }
  }

  hidePaging(flag) {
    var pageDiv = document.getElementsByClassName(
      "MuiTablePagination-toolbar"
    )[5];
    if (pageDiv !== undefined) {
      pageDiv.hidden = flag;
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    let sheetName = "GEM_IMPORT";
    let value = sessionStorage.getItem("gems_bearer_token");
    const config = {
      headers: {
        "content-type": "text/plain",
        b_token: value,
        ...authHeader(),
      },
    };
    instance
      .post("/data/upload/uploadedcontent", sheetName, config)
      .then((response) => {
        this.setState({ isLoading: false });
        if (response.data != null && Object.keys(response.data).length > 0) {
          this.setState({
            gemImportList: response.data,
            disableAddonEmptySheet: false,
          });
          this.props.sizeCallbackGI(
            this.state.gemImportList.length,
            GEM_IMPORT
          );
        }
        if (Array.isArray(response.data)) {
          this.setState({
            disableAddonEmptySheet: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            console.log("Unknown error occured");
          }
        } else {
          console.log("Unknown error occured");
        }
      });
    this.returnTableToDefaultState();
  }

  syncObjectWithBackEnd(rowData, action, allList, oldData, resolve, reject) {
    let sheetName = "GEM_IMPORT";
    let value = sessionStorage.getItem("gems_bearer_token");
    const config = {
      headers: {
        "content-type": "application/json",
        b_token: value,
        ...authHeader(),
      },
    };
    instance
      .post(
        "/data/upload/uploadedcontent/edit/" + sheetName + "/" + action,
        rowData,
        config
      )
      .then((response) => {
        if (response != null) {
          this.setState({
            selectedRow: null,
          });
          switch (action) {
            case ExcelViewActionAdd:
              this.setState({
                gemImportList: [],
              });
              allList.forEach((value) => {
                value.id = value.id + 1;
              });
              allList.splice(0, 0, response.data.row);

              this.tableRef.current.dataManager.columns.forEach((items) => {
                items.tableData.filterValue = undefined;
              });
              break;
            case ExcelViewActionUpdate:
              const index = allList.findIndex((x) => x.id === oldData.id);
              allList[index] = response.data.row;
              break;
            case ExcelViewActionDelete:
              const indexDel = allList.findIndex((x) => x.id === rowData.id);
              allList.splice(indexDel, 1);
              allList.forEach((value, index) => {
                if (index >= indexDel) {
                  value.id = value.id - 1;
                }
              });
              if (allList.length === 0) {
                this.setState({
                  refreshTable: false,
                });
              }
              break;
            default:
              break;
          }
          if (
            response.data.statusMap !== undefined &&
            response.data.statusMap !== null
          )
            sessionStorage.setItem(
              "excel_summary",
              JSON.stringify(response.data.statusMap)
            );
          this.setState(
            {
              gemImportList: allList.map((row) => ({
                ...row,
                tableData: {},
              })),
              refreshTable: true,
            },
            () => resolve()
          );
          this.props.sizeCallbackGI(
            this.state.gemImportList.length,
            GEM_IMPORT
          );
        }
      })
      .catch((error) => {
        reject();
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            console.log("Unknown error occured");
          }
        } else {
          console.log("Unknown error occured");
        }
      });
  }

  checkIfColumnIsBlank(dataRow) {
    PubSub.publish("TRIGGER_ROW_VALIDATION", true);
    let validFlag = true;
    Object.keys(dataRow).forEach((item) => {
      if (
        dataRow[item] === undefined ||
        dataRow[item] === null ||
        dataRow[item].toString().trim() === ""
      ) {
        if (isColumnMandatory(GEM_IMPORT, item)) {
          validFlag = validFlag && false;
        }
      }
    });
    return validFlag;
  }

  clearAllFilters() {
    this.setState(
      {
        gemImportList: [],
      },
      () => {
        this.tableRef.current.dataManager.columns.forEach((items) => {
          items.tableData.filterValue = undefined;
        });
        this.setState({
          gemImportList: this.tableRef.current.state.originalData,
        });
      }
    );
  }

  showAllColumns() {
    const originalData = this.tableRef.current.state.originalData;
    this.setState(
      {
        gemImportList: [],
      },
      () => {
        this.tableRef.current.dataManager.columns.forEach((items) => {
          items.hidden = false;
        });
        this.setState({
          gemImportList: originalData,
        });
      }
    );
  }

  clearAllErrors() {
    this.tableRef.current.dataManager.data.forEach((items) => {
      if (items.tableData !== undefined && items.tableData !== null)
        delete items.tableData.showDetailPanel;
    });
  }

  clearEditState() {
    this.setState(
      {
        gemImportList: [],
      },
      () => {
        this.tableRef.current.dataManager.lastEditingRow = undefined;
        this.setState({
          gemImportList: this.tableRef.current.state.originalData,
        });
      }
    );
  }

  returnTableToDefaultState() {
    this.clearAllErrors();
    this.clearAllFilters();
    this.clearEditState();
    this.showAllColumns();
    this.setState({
      selectedRow: null,
    });
  }

  render() {
    if (this.state.refreshTable) {
      return (
        <MaterialTable
          tableRef={this.tableRef}
          columns={this.state.columns}
          isLoading={this.state.isLoading}
          data={this.state.gemImportList}
          localization={{
            body: {
              addRemoveColumns: "Add",
            },
            toolbar: {
              addRemoveColumns: "Show/Hide Columns",
              showColumnsTitle: "Show/Hide Columns",
            },
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setState({ currentPageSize: pageSize });
            let timesRun = 0;
            let firstTimeExecuted = false;
            var hasVScroll = window.innerHeight <= document.body.offsetHeight;
            this.interval = setInterval(() => {
              if (timesRun === 5) {
                clearInterval(this.interval);
              }
              timesRun++;
              if (hasVScroll) {
                window.scrollTo(0, 0);
                clearInterval(this.interval);
              } else {
                if (firstTimeExecuted) {
                  clearInterval(this.interval);
                }
                firstTimeExecuted = true;
              }
            }, 1);
          }}
          actions={[
            {
              icon: "clear_all",
              tooltip: "Clear All Filters",
              hidden: this.state.gemImportList.length === 0,
              isFreeAction: true,
              onClick: () => this.clearAllFilters(),
            },
          ]}
          options={{
            ...this.state.gridOptions,
            draggable: false,
            filtering: this.state.gemImportList.length > 0,
            sorting: this.state.gemImportList.length > 0,
            pageSize: this.state.currentPageSize,
          }}
          onRowClick={(evt, selectedRow, togglePanel) => {
            if (
              this.tableRef.current !== null &&
              this.tableRef.current.state.lastEditingRow === undefined &&
              !this.tableRef.current.state.showAddRow
            ) {
              if (!selectedRow.valid) togglePanel();
              this.setState({
                selectedRow: selectedRow.tableData.id,
              });
            }
          }}
          editable={
            !this.state.disableAddonEmptySheet
              ? {
                  onRowAdd: (newData) =>
                    new Promise((resolve, reject) => {
                      const data = this.state.gemImportList;
                      let newRow = {
                        id_counterpart: newData.id_counterpart,
                        id_contract: newData.id_contract,
                        id_default: newData.id_default,
                        cod_rate_type: newData.cod_rate_type,
                        cod_currency_tra: newData.cod_currency_tra,
                        id_tranche: newData.id_tranche,
                        cod_currency_payment: newData.cod_currency_payment,
                        default_dat:
                          newData.default_dat === undefined
                            ? null
                            : isNaN(Date.parse(newData.default_dat))
                            ? newData.default_dat
                            : moment(newData.default_dat).diff(
                                "1900-01-01",
                                "days"
                              ) + 2,
                        amount_due: newData.amount_due,
                        discount_dat:
                          newData.discount_dat === undefined
                            ? null
                            : isNaN(Date.parse(newData.discount_dat))
                            ? newData.discount_dat
                            : moment(newData.discount_dat).diff(
                                "1900-01-01",
                                "days"
                              ) + 2,
                        dat_due:
                          newData.dat_due === undefined
                            ? null
                            : isNaN(Date.parse(newData.dat_due))
                            ? newData.dat_due
                            : moment(newData.dat_due).diff(
                                "1900-01-01",
                                "days"
                              ) + 2,
                        base_rate_date:
                          newData.base_rate_date === undefined
                            ? null
                            : isNaN(Date.parse(newData.base_rate_date))
                            ? newData.base_rate_date
                            : moment(newData.base_rate_date).diff(
                                "1900-01-01",
                                "days"
                              ) + 2,
                        base_rate: newData.base_rate,
                        margin: newData.margin,
                        interest_rate: newData.interest_rate,
                        dat_paid:
                          newData.dat_paid === undefined
                            ? null
                            : isNaN(Date.parse(newData.dat_paid))
                            ? newData.dat_paid
                            : moment(newData.dat_paid).diff(
                                "1900-01-01",
                                "days"
                              ) + 2,
                        capital_prepaid: newData.capital_prepaid,
                        capital_paid: newData.capital_paid,
                        capital_disb: newData.capital_disb,
                        interest_paid: newData.interest_paid,
                        penalty_paid: newData.penalty_paid,
                        fees_paid: newData.fees_paid,
                        alternative_discount: newData.alternative_discount,
                        reflow_origin: newData.reflow_origin,
                        id_origin: newData.id_origin,
                        valid: true,
                        id: 2,
                        info: null,
                      };
                      if (this.checkIfColumnIsBlank(newRow)) {
                        this.syncObjectWithBackEnd(
                          newRow,
                          ExcelViewActionAdd,
                          data.length === undefined ? [] : data,
                          [],
                          resolve,
                          reject
                        );
                      } else {
                        reject();
                      }
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      const data = this.state.gemImportList;
                      newData = {
                        id_counterpart: newData.id_counterpart,
                        id_contract: newData.id_contract,
                        id_default: newData.id_default,
                        cod_rate_type: newData.cod_rate_type,
                        cod_currency_tra: newData.cod_currency_tra,
                        id_tranche: newData.id_tranche,
                        cod_currency_payment: newData.cod_currency_payment,
                        default_dat:
                          newData.default_dat === null ||
                          newData.default_dat === undefined
                            ? null
                            : isNaN(Date.parse(newData.default_dat))
                            ? newData.default_dat
                            : moment(newData.default_dat).diff(
                                "1900-01-01",
                                "days"
                              ) + 2,
                        amount_due: newData.amount_due,
                        discount_dat:
                          newData.discount_dat === null ||
                          newData.discount_dat === undefined
                            ? null
                            : isNaN(Date.parse(newData.discount_dat))
                            ? newData.discount_dat
                            : moment(newData.discount_dat).diff(
                                "1900-01-01",
                                "days"
                              ) + 2,
                        dat_due:
                          newData.dat_due === null ||
                          newData.dat_due === undefined
                            ? null
                            : isNaN(Date.parse(newData.dat_due))
                            ? newData.dat_due
                            : moment(newData.dat_due).diff(
                                "1900-01-01",
                                "days"
                              ) + 2,
                        base_rate_date:
                          newData.base_rate_date === null ||
                          newData.base_rate_date === undefined
                            ? null
                            : isNaN(Date.parse(newData.base_rate_date))
                            ? newData.base_rate_date
                            : moment(newData.base_rate_date).diff(
                                "1900-01-01",
                                "days"
                              ) + 2,
                        base_rate: newData.base_rate,
                        margin: newData.margin,
                        interest_rate: newData.interest_rate,
                        dat_paid:
                          newData.dat_paid === null ||
                          newData.dat_paid === undefined
                            ? null
                            : isNaN(Date.parse(newData.dat_paid))
                            ? newData.dat_paid
                            : moment(newData.dat_paid).diff(
                                "1900-01-01",
                                "days"
                              ) + 2,
                        capital_prepaid: newData.capital_prepaid,
                        capital_paid: newData.capital_paid,
                        interest_paid: newData.interest_paid,
                        penalty_paid: newData.penalty_paid,
                        fees_paid: newData.fees_paid,
                        alternative_discount: newData.alternative_discount,
                        reflow_origin: newData.reflow_origin,
                        id_origin: newData.id_origin,
                        valid: newData.valid,
                        id: newData.id,
                        info: newData.info,
                      };
                      console.log(newData);
                      if (this.checkIfColumnIsBlank(newData)) {
                        this.syncObjectWithBackEnd(
                          newData,
                          ExcelViewActionUpdate,
                          data,
                          oldData,
                          resolve,
                          reject
                        );
                      } else {
                        reject();
                      }
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      let data = this.state.gemImportList;
                      this.syncObjectWithBackEnd(
                        oldData,
                        ExcelViewActionDelete,
                        data,
                        [],
                        resolve,
                        reject
                      );
                    }),
                }
              : null
          }
          components={{
            Toolbar: (props) => (
              <div className="row">
                <div className="col-auto mr-auto">
                  {this.tableRef.current !== null &&
                  (this.tableRef.current.state.lastEditingRow !== undefined ||
                    this.tableRef.current.state.showAddRow) ? (
                    <div className="alert alert-danger" role="alert">
                      <div className="d-flex flex-wrap">
                        <div className="error_list">
                          Note: Fields marked with * are mandatory
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-4 excel-buttons">
                  <MTableToolbar {...props} />
                </div>
              </div>
            ),

            EditRow: (props) => {
              this.clearAllErrors();
              if (
                this.tableRef.current.dataManager.columns.filter(
                  (item) => item.hidden === true
                ).length > 0
              )
                this.showAllColumns();
              if (this.state.selectedRow !== null) {
                this.setState({
                  selectedRow: null,
                });
              }
              return <MTableEditRow {...props} />;
            },
          }}
          detailPanel={[
            (rowData) => ({
              tooltip: "Show/Hide Error",
              disabled: rowData.valid,
              icon: () => (
                <ErrorOutlineIcon
                  color="error"
                  style={{ display: rowData.valid ? "none" : "" }}
                  onClick={() => {
                    if (
                      this.tableRef.current !== null &&
                      this.tableRef.current.state.lastEditingRow ===
                        undefined &&
                      !this.tableRef.current.state.showAddRow
                    ) {
                      this.setState({ selectedRow: rowData.tableData.id });
                    }
                  }}
                />
              ),
              openIcon: () => (
                <ErrorOutlineIcon
                  color="error"
                  style={{ display: rowData.valid ? "none" : "" }}
                  onClick={() => {
                    this.setState({ selectedRow: null });
                  }}
                />
              ),
              render: () => (
                <div className="error-container">
                  <div className="heading-error">Errors</div>
                  {rowData.info !== undefined && rowData.info !== null
                    ? rowData.info.map((items) => {
                        return (
                          <div className="line-break mt-1 mb-1">
                            <div
                              className={
                                "cat cat" +
                                items.category +
                                " align-self-center"
                              }
                            >
                              {items.category}
                            </div>
                            <div className="error-text">{items.error}</div>
                          </div>
                        );
                      })
                    : null}
                </div>
              ),
            }),
          ]}
        />
      );
    } else {
      return null;
    }
  }
}

GemImport.propTypes = {
  sizeCallbackGI: PropTypes.func,
};

import React, { Component } from "react";
import ParametersCompute from "./Parameters";
import ComputeOption from "./ComputeOption";
import ResultListtable from "./ResultListTable";
import ResultlistPara from "./resultListParameter";
import { authHeader } from "../commonUtility/auth-header";
import instance from "../../instance";
import DynamicCohort from "./DynamicCohort";
import StaticCohort from "./StaticCohort";
import RatingTable from "./RatingTable";
import MMResult from "./MMResults";
import RRResult from "./RRResults";

import {
  PARAM_MEMBERS,
  PARAM_CATEGORY,
  PARAM_CPRT_CROSS_DEFAULT_CLAUSE,
  PARAM_REGION,
  PARAM_CONTRACT_REGION,
  PARAM_COUNTRY,
  PARAM_CONTRACT_COUNTRY,
  PARAM_CONTRACT_CROSS_DEFAULT_CLAUSE,
  PARAM_CURRENCY_TYPE,
  PARAM_LOAN_TYPE,
  PARAM_RESOLUTION_TYPE,
  PARAM_SECTOR_CLASSIFICATION,
  PARAM_ORGINAL_AMT_LOAN,
  PARAM_CONTAGION,
  MEMBERS,
  CATEGORY,
  CROSS_DEFAULT_CLAUSE,
  CONTAGION,
  CURRENCY_TYPE,
  LOAN_TYPE,
  RESOLUTION_TYPE,
  SECTOR_CLASSIFICATION,
  LOAN_AMOUNT,
  TYPE_SUMMARY,
  TYPE_RATING,
  GI1,
  GI2,
  GI3,
  GI4,
  GI5,
  GI6,
  GI7,
  GI8,
  GI9,
  GI10,
  Gs1,
  Gs2,
  Gs3,
  Gs4,
  Gs5,
  Gs6,
  Gs7,
  Gs8,
  Gs9,
  Gs10,
  Gs11,
  NR,
  SD,
  PD,
  MM,
  RR,
  FC,
  DELETE_ONE,
  DELETE_ALL,
  PARAM_SUB_INDUSTRY,
  PARAM_SECTOR,
  PARAM_INDUSTRY,
  ACTION_QUERY_SAVE,
  TOAST_TYPE_SUCCESS,
  TYPE_PD,
  TYPE_MM,
  TYPE_RR,
  SAVED_QUERY_SUCCESS,
  SAVED_QUERY_FAIL,
  PARAM_SOV_FLAG,
  PARAM_SOV_DEFINITION,
  PARAM_EXCLUDE_DEVELOPED,
  PARAM_EXCLUDE_DEVELOPED_CONTRACT,
  PARAM_SUB_SUB_INDUSTRY,
} from "../commonUtility/string-const";
import { formatDate } from "../commonUtility/method-utils";
import { connect } from "react-redux";
import { showToast } from "../commonUtility/NewToast";

class ComputeMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paramList: null,
      defaultCurrencyType: {},
      defaultCrossDefault: {},
      defaultCrossDefaultContract: {},
      defaultLoanType: {},
      defaultResolutionType: {},
      defaultSectorClassification: {},
      defaultMembers: {},
      defaultCategory: {},
      defaultLoanAmount: {},
      dataSector: [],
      defaultContagion: true,
      members: [],
      category: [],
      crossDefaultClauseCounterpart: [],
      crossDefaultClauseContract: [],
      contagion: true,
      currencyType: [],
      loanType: [],
      resolutionType: [],
      sectorClassification: [],
      loanAmount: [],
      allParams: {},
      currentPDResultType: null,
      currentSelectedRating: null,
      isEoy: false,
      isNewPeriod: true,
      isDefault180: false,
      startYear: 1984,
      endYear: new Date().getFullYear() - 1,
      computeResponses: {
        pd: [],
        mm: [],
        rr: [],
        fc: [],
      },
      selectedCalculation: null,
      selectedIndex: 0,
      showResult: false,
      defaultCountry: [],
      defaultCountryContract: [],
      defaultRegion: {},
      defaultRegionContract: {},
      selectedCountry: [],
      selectedCountryContract: [],
      arrSector: [],
      arrIndustry: [],
      arrSubIndustry: [],
      currentSector: [],
      currentIndustry: [],
      currentSubIndustry: [],
      arrSubSubIndustry:[],
      paramSelectedRegion: [],
      paramSelectedRegionContract: [],
      paramSelectedCountry: [],
      paramSelectedCountryContract: [],
      showResultMM: false,
      showResultRR: false,
      renderHiddenMMChart: false,
      defaultSovDefaultDefinition: false,
      defaultSovFlag: 1,
      sovDefaultDefinition: false,
      sovFlag: 1,
      selectedHiddenIndex: 0,
	  defaultexcludeDevelopedCountriesCounterpart: false,
	  defaultexcludeDevelopedCountriesContract: false,
	  excludeDevelopedCountriesCounterpart: false,
	  excludeDevelopedCountriesContract: false,
    };

    this.getParameterList = this.getParameterList.bind(this);
    this.ratingOrSummaryClick = this.ratingOrSummaryClick.bind(this);
    this.computeResultPush = this.computeResultPush.bind(this);
    this.setCurrentComputeResult = this.setCurrentComputeResult.bind(this);
  }

  componentDidMount() {
    // Get all parameters on component mount
    this.getParameterList();
  }

  removeHiddenMMChart() {
    this.setState({
      renderHiddenMMChart: false,
    });
  }

  renderHiddenMMChart(idx) {
    this.setState({
      selectedCalculation: "MM",
      selectedHiddenIndex: idx,
      renderHiddenMMChart: true,
    });
  }

  setCurrentComputeResult(idx, type, name, dateTime) {
    if (type === "MM") {
      this.setState({
        selectedCalculation: "MM",
        selectedIndex: idx,
        showResultRR: false,
        showResultMM: true,
        showResult: false,
      });
    } else if (type === "RR") {
      this.setState({
        selectedCalculation: "RR",
        selectedIndex: idx,
        showResultRR: true,
        showResultMM: false,
        showResult: false,
      });
    } else {
      this.setState({
        selectedCalculation: type,
        selectedIndex: idx,
        showResult: true,
        currentPDResultType: TYPE_SUMMARY,
        showResultMM: false,
        showResultRR: false,
      });
    }
  }

  deleteFromSessionStorage(computeID) {
    let ids = sessionStorage.getItem("computeIds");
    if (ids != null) {
      let computeIdArr = ids.split(",");
      let indexToBeDlt = computeIdArr.indexOf(computeID);
      if (indexToBeDlt >= 0) {
        computeIdArr.splice(indexToBeDlt, 1);
        if (computeIdArr.length > 0) {
          ids = computeIdArr.join(",");
          sessionStorage.setItem("computeIds", ids);
        } else {
          sessionStorage.removeItem("computeIds");
        }
      }
    }

    let savedParams = sessionStorage.getItem("computeParams");
    if (savedParams != null) {
      let savedParamsObj = JSON.parse(savedParams);
      if (savedParamsObj[computeID] != null) {
        delete savedParamsObj[computeID];
        if (Object.keys(savedParamsObj).length > 0) {
          sessionStorage.setItem(
            "computeParams",
            JSON.stringify(savedParamsObj)
          );
        } else {
          sessionStorage.removeItem("computeParams");
        }
      }
    }
  }

  saveQuery(name, idx, type, key) {
    switch (type) {
      case "PD":
        let computeResponses = this.state.computeResponses;
        if (computeResponses != null) {
          let responsePD = computeResponses.pd[idx];
          if (responsePD !== undefined) {
            let params = responsePD.params;
            this.saveParamQueryToDatabase(name, params, TYPE_PD);
          }
        }
        break;
      case "MM":
        computeResponses = this.state.computeResponses;
        if (computeResponses != null) {
          let responseMM = computeResponses.mm[idx];
          if (responseMM !== undefined) {
            let params = responseMM.params;
            this.saveParamQueryToDatabase(name, params, TYPE_MM);
          }
        }
        break;
      case "RR":
        computeResponses = this.state.computeResponses;
        if (computeResponses != null) {
          let responseRR = computeResponses.rr[idx];
          if (responseRR !== undefined) {
            let params = responseRR.params;
            this.saveParamQueryToDatabase(name, params, TYPE_RR);
          }
        }
        break;
      default:
        break;
    }
  }

  saveParamQueryToDatabase(name, params, type) {
    params = {
      dataset_year: params.dataset_year,
      active_year_from: params.startYear,
      active_year_to: params.endYear,
      isWorkEnvironment: params.mode === "Work Environment" ? true : false,
      isFullCompute: false,
      memberInstititute: params.membersId,
      sector: params.sectorId,
      subIndustry: params.subIndustryGrpId,
      industry: params.industryGrpId,
      subSubIndustry: params.subSubIndustryGrpId,
      region: params.regionId,
      regionContract: params.regionIdContract,
      country: params.country,
      countryContract: params.countryContract,
      crossDefaultClause_cprt: params.cdc_cprt,
      crossDefaultClause_cntrct: params.cdc_crt,
      category: params.categoryId,
      include_contagion: params.contagionId,
      currencyType: params.currency_typeId,
      loanType: params.loan_typeId,
      resolutionType: params.resolution_typeId,
      sectorClassification: params.sector_classificationId,
      originalAmountLoan: params.loan_amountId,
      defaultPeriod180: params.default_periodId,
      period: params.period !== undefined ? parseInt(params.period) : null,
      nodaysbeforedefault: params.nodaysbeforedefault,
      thresholdUnresolvedContracts: params.thresholdUnresolvedContracts,
      includeUnresolvedContracts: params.includeUnresolvedContracts,
      excludeDevelopedCountriesCounterpart: params.excludeDevelopedCountriesCounterpart,
      excludeDevelopedCountriesContract: params.excludeDevelopedCountriesContract,
    };
    const config = {
      headers: {
        ...authHeader(),
      },
    };
    instance
      .post(
        "compute/savedquery/" +
          ACTION_QUERY_SAVE +
          "/" +
          type +
          "/" +
          encodeURIComponent(name),
        params,
        config
      )
      .then((response) => {
        if (response != null && response.data.actionDone === true) {
          showToast(SAVED_QUERY_SUCCESS, TOAST_TYPE_SUCCESS);
        } else {
          showToast(SAVED_QUERY_FAIL, TOAST_TYPE_SUCCESS);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            sessionStorage.clear();

            window.location.replace("/");
          } else {
            showToast("An error occured.", TOAST_TYPE_SUCCESS);
          }
        } else {
          showToast("An error occured.", TOAST_TYPE_SUCCESS);
        }
      });
  }

  deleteResult(idx, type, howMany, key) {
    switch (howMany) {
      case DELETE_ONE:
        switch (type) {
          case "PD":
            let computeResponses = this.state.computeResponses;
            let responsePD = computeResponses.pd;
            responsePD.splice(idx, 1);
            computeResponses = {
              pd: responsePD,
              ...computeResponses,
            };
            this.deleteFromSessionStorage(key);
            if (
              this.state.selectedIndex === idx &&
              this.state.selectedCalculation === "PD"
            ) {
              if (this.state.selectedIndex > 0) {
                this.setState({
                  computeResponses: computeResponses,
                  showResult: false,
                  selectedIndex: 0,
                });
              } else {
                this.setState({
                  computeResponses: computeResponses,
                  showResult: false,
                });
              }
            } else {
              if (this.state.selectedIndex > 0) {
                if (idx > this.state.selectedIndex) {
                  this.setState({ computeResponses: computeResponses });
                } else {
                  this.setState({
                    computeResponses: computeResponses,
                    selectedIndex: this.state.selectedIndex - 1,
                  });
                }
              } else {
                this.setState({ computeResponses: computeResponses });
              }
            }
            break;
          case "MM":
            let computeResponsesMM = this.state.computeResponses;
            let responseMM = computeResponsesMM.mm;
            responseMM.splice(idx, 1);
            computeResponsesMM = {
              mm: responseMM,
              ...computeResponsesMM,
            };
            this.deleteFromSessionStorage(key);
            if (
              this.state.selectedIndex === idx &&
              this.state.selectedCalculation === "MM"
            ) {
              if (this.state.selectedIndex > 0) {
                this.setState({
                  computeResponses: computeResponsesMM,
                  showResultMM: false,
                  selectedIndex: 0,
                });
              } else {
                this.setState({
                  computeResponses: computeResponsesMM,
                  showResultMM: false,
                });
              }
            } else {
              if (this.state.selectedIndex > 0) {
                if (idx > this.state.selectedIndex) {
                  this.setState({ computeResponses: computeResponsesMM });
                } else {
                  this.setState({
                    computeResponses: computeResponsesMM,
                    selectedIndex: this.state.selectedIndex - 1,
                  });
                }
              } else {
                this.setState({ computeResponses: computeResponsesMM });
              }
            }
            break;
          case "RR":
            let computeResponsesRR = this.state.computeResponses;
            let responseRR = computeResponsesRR.rr;
            responseRR.splice(idx, 1);
            computeResponsesRR = {
              rr: responseRR,
              ...computeResponsesRR,
            };
            this.deleteFromSessionStorage(key);
            if (
              this.state.selectedIndex === idx &&
              this.state.selectedCalculation === "RR"
            ) {
              if (this.state.selectedIndex > 0) {
                this.setState({
                  computeResponses: computeResponsesRR,
                  showResultRR: false,
                  selectedIndex: 0,
                });
              } else {
                this.setState({
                  computeResponses: computeResponsesRR,
                  showResultRR: false,
                });
              }
            } else {
              if (this.state.selectedIndex > 0) {
                if (idx > this.state.selectedIndex) {
                  this.setState({ computeResponses: computeResponsesRR });
                } else {
                  this.setState({
                    computeResponses: computeResponsesRR,
                    selectedIndex: this.state.selectedIndex - 1,
                  });
                }
              } else {
                this.setState({ computeResponses: computeResponsesRR });
              }
            }
            break;
          default:
            break;
        }
        break;
      case DELETE_ALL:
        this.setState({
          computeResponses: { pd: [], mm: [], rr: [], fc: [] },
          showResult: false,
          showResultMM: false,
          showResultRR: false,
          selectedIndex: 0,
        });
        sessionStorage.removeItem("computeIds");
        sessionStorage.removeItem("computeParams");
        sessionStorage.removeItem("fullComputeIds");
        break;
      default:
        break;
    }
  }

  removeBeingExecutedFromQueue(type, dateTime) {
    switch (type) {
      case PD:
        let computeResponses = this.state.computeResponses;
        let computePDRes = computeResponses.pd;
        let index = computePDRes.indexOf(dateTime);
        if (index >= 0) {
          computePDRes.splice(index, 1);
          computeResponses = {
            pd: computePDRes,
            ...computeResponses,
          };
          this.setState({ computeResponses: computeResponses });
        }
        break;
      case MM:
        let computeResponsesMM = this.state.computeResponses;
        let computeMMRes = computeResponsesMM.mm;
        let indexMM = computeMMRes.indexOf(dateTime);
        if (indexMM >= 0) {
          computeMMRes.splice(indexMM, 1);
          computeResponsesMM = {
            mm: computeMMRes,
            ...computeResponsesMM,
          };
          this.setState({ computeResponses: computeResponsesMM });
        }
        break;
      case RR:
        let computeResponsesRR = this.state.computeResponses;
        let computeRRRes = computeResponsesRR.rr;
        let indexRR = computeRRRes.indexOf(dateTime);
        if (indexRR >= 0) {
          computeRRRes.splice(indexRR, 1);
          computeResponsesRR = {
            rr: computeRRRes,
            ...computeResponsesRR,
          };
          this.setState({ computeResponses: computeResponsesRR });
        }
        break;
      case FC:
        let computeResponsesFC = this.state.computeResponses;
        let computeFCRes = computeResponsesFC.fc;
        let indexFC = computeFCRes.indexOf(dateTime);
        if (indexFC >= 0) {
          computeFCRes.splice(indexFC, 1);
          computeResponsesFC = {
            fc: computeFCRes,
            ...computeResponsesFC,
          };
          this.setState({ computeResponses: computeResponsesFC });
        }
        break;
      default:
        break;
    }
  }

  addBeingExecutedToQueue(type, dateTime, fcId) {
    switch (type) {
      case PD:
        let computeResponses = this.state.computeResponses;
        let computePDRes = computeResponses.pd;
        computePDRes.push(dateTime);
        computeResponses = {
          ...computeResponses,
          pd: computePDRes,
        };
        this.setState({
          computeResponses: computeResponses,
          selectedCalculation: "PD",
        });
        break;
      case MM:
        let computeResponsesMM = this.state.computeResponses;
        let computeMMRes = computeResponsesMM.mm;
        computeMMRes.push(dateTime);
        computeResponsesMM = {
          ...computeResponsesMM,
          mm: computeMMRes,
        };
        this.setState({
          computeResponses: computeResponsesMM,
          selectedCalculation: "MM",
        });
        break;
      case RR:
        let computeResponsesRR = this.state.computeResponses;
        let computeRRRes = computeResponsesRR.rr;
        computeRRRes.push(dateTime);
        computeResponsesRR = {
          ...computeResponsesRR,
          rr: computeRRRes,
        };
        this.setState({
          computeResponses: computeResponsesRR,
          selectedCalculation: "RR",
        });
        break;
      case FC:
        let computeResponsesFC = this.state.computeResponses;
        let computeFCRes = computeResponsesFC.fc;
        computeFCRes.push(dateTime);
        computeResponsesFC = {
          ...computeResponsesFC,
          fc: computeFCRes,
        };
        this.setState({
          computeResponses: computeResponsesFC,
          selectedCalculation: "FC",
        });
        break;
      default:
        break;
    }
  }

  computeResultPush(type, res) {
    switch (type) {
      case PD:
        let computeResponses = this.state.computeResponses;
        let computePDRes = computeResponses.pd;
        let index = computePDRes.indexOf(res.dateTime);
        if (index >= 0) {
          computePDRes[index] = res;
          computeResponses = {
            ...computeResponses,
            pd: computePDRes,
          };
        } else {
          computePDRes.push(res);
          computeResponses = {
            ...computeResponses,
            pd: computePDRes,
          };
        }
        this.setState({
          computeResponses: computeResponses,
          selectedCalculation: PD,
        });
        break;
      case MM:
        let computeResponsesMM = this.state.computeResponses;
        let computeMMRes = computeResponsesMM.mm;
        let indexMM = computeMMRes.indexOf(res.dateTime);
        if (indexMM >= 0) {
          computeMMRes[indexMM] = res;
          computeResponsesMM = {
            ...computeResponsesMM,
            mm: computeMMRes,
          };
        } else {
          computeMMRes.push(res);
          computeResponsesMM = {
            ...computeResponsesMM,
            mm: computeMMRes,
          };
        }
        this.setState({
          computeResponses: computeResponsesMM,
          selectedCalculation: "MM",
        });
        break;
      case RR:
        let computeResponsesRR = this.state.computeResponses;
        let computeRRRes = computeResponsesRR.rr;
        let indexRR = computeRRRes.indexOf(res.dateTime);
        if (indexRR >= 0) {
          computeRRRes[indexRR] = res;
          computeResponsesRR = {
            ...computeResponsesRR,
            rr: computeRRRes,
          };
        } else {
          computeRRRes.push(res);
          computeResponsesRR = {
            ...computeResponsesRR,
            rr: computeRRRes,
          };
        }
        this.setState({
          computeResponses: computeResponsesRR,
          selectedCalculation: "RR",
        });
        break;
      default:
        break;
    }
  }

  getParameterList() {
    let allParams = {};
    var config = {
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    instance
      .get("/compute/getParamList", config)
      .then((response) => {
        if (response.data !== undefined) {
          let data = response.data;
          let defaultCurrencyType = {};
          let defaultCrossDefault = {};
          let defaultCrossDefaultContract = {};
          let defaultLoanType = {};
          let defaultResolutionType = {};
          let defaultSectorClassification = {};
          let defaultMembers = {};
          let defaultCategory = {};
          let defaultLoanAmount = {};
          let defaultContagion = true;
          let arrCtryList = {};
          let arrRgnList = [];
          let selectedCountry = [];
          let selectedCountryContract = [];
          let arrSector = [];
          let arrIndustry = [];
          let arrSubIndustry = [];
          let arrSubSubIndustry = [];
          Object.keys(data).forEach((type) => {
            switch (type) {
              case "currencyType":
                let arrCcType = [];
                Object.keys(data.currencyType).forEach((key) => {
                  defaultCurrencyType[key] = true;
                  arrCcType.push(data.currencyType[key]);
                });
                allParams[type] = arrCcType;
                break;
              case "crossDefaultClause":
                let arrCdc = [];
                Object.keys(data.crossDefaultClause).forEach((key) => {
                  defaultCrossDefault[key] = true;
                  defaultCrossDefaultContract[key] = true;
                  arrCdc.push(data.crossDefaultClause[key]);
                });
                allParams[type + "Counterpart"] = arrCdc;
                allParams[type + "Contract"] = arrCdc;
                break;
              case "loanType":
                let arrLnType = [];
                Object.keys(data.loanType).forEach((key) => {
                  defaultLoanType[key] = true;
                  arrLnType.push(data.loanType[key]);
                });
                allParams[type] = arrLnType;
                break;
                case "resolutionType":
                  let arrRslnType = [];
                  Object.keys(data.resolutionType).forEach((key) => {
                    defaultResolutionType[key] = true;
                    arrRslnType.push(data.resolutionType[key]);
                  });
                  allParams[type] = arrRslnType;
                  break;
              case "sectorClassification":
                let arrScCls = [];
                Object.keys(data.sectorClassification).forEach((key) => {
                  defaultSectorClassification[key] = true;
                  arrScCls.push(data.sectorClassification[key]);
                });
                allParams[type] = arrScCls;
                break;
                case "members":
                let arrMem = [];
                Object.keys(data.members).forEach((key) => {
                  defaultMembers[key] = true;
                  arrMem.push(data.members[key]);
                });
                allParams[type] = arrMem;
                break;
              case "category":
                let arrCategory = [];
                Object.keys(data.category).forEach((key) => {
                  defaultCategory[key] = true;
                  arrCategory.push(data.category[key]);
                });
                allParams[type] = arrCategory;
                break;
              case "loanAmount":
                let arrLoanAmt = [];
                Object.keys(data.loanAmount).forEach((key) => {
                  defaultLoanAmount[key] = true;
                  arrLoanAmt.push(data.loanAmount[key]);
                });
                allParams[type] = arrLoanAmt;
                break;
              case "sectorList":
                  let scL = [];
                  let indL = [];
                  let subInL = [];
                  let subSubInL = [];
                  Object.keys(data.sectorList).forEach((key) => {
                    arrSector.push({
                      label: key,
                      value: data.sectorList[key]["id"],
                    });
                    scL.push(data.sectorList[key]["id"]);
                    let innerIndustry = [];
                    Object.keys(data.sectorList[key]["industryGroups"]).forEach(
                      (i) => {
                        let innerSubIndustry = [];
                        innerIndustry.push({
                          label:
                            data.sectorList[key]["industryGroups"][i][
                              "groupLabel"
                            ],
                          value:
                            data.sectorList[key]["industryGroups"][i]["groupId"],
                          sector: key,
                        });
                        indL.push(
                          data.sectorList[key]["industryGroups"][i]["groupId"]
                        );
                        Object.keys(
                          data.sectorList[key]["industryGroups"][i][
                            "subIndustryGroups"
                          ]
                        ).forEach((si) => {
                          innerSubIndustry.push({
                            label:
                              data.sectorList[key]["industryGroups"][i][
                                "subIndustryGroups"
                              ][si]["subIndustryName"],
                            value:
                              data.sectorList[key]["industryGroups"][i][
                                "subIndustryGroups"
                              ][si]["id"],
                            sector: key,
                            industry:
                              data.sectorList[key]["industryGroups"][i][
                                "groupLabel"
                              ],
                          });
                          subInL.push(
                            data.sectorList[key]["industryGroups"][i][
                              "subIndustryGroups"
                            ][si]["id"]
                          );

                          let innerSubSubIndustry = [];
                          Object.keys(data.sectorList[key]["industryGroups"][i][
                            "subIndustryGroups"
                          ][si]["subSubIndustry"]).forEach((ssi)=>{
                            if(data.sectorList[key]["label"]=="Utilities"){
                              console.log(data.sectorList[key]["industryGroups"][i][
                                "subIndustryGroups"
                              ][si]["subSubIndustry"][ssi])
                            }
                            innerSubSubIndustry.push({label:data.sectorList[key]["industryGroups"][i][
                              "subIndustryGroups"
                            ][si]["subSubIndustry"][ssi]["subSubIndustryName"],
                              value:data.sectorList[key]["industryGroups"][i][
                                "subIndustryGroups"
                              ][si]["subSubIndustry"][ssi]["subsubindustryId"],
                              sector:key,
                              industry: data.sectorList[key]["industryGroups"][i][
                                "groupLabel"
                              ],
                              subIndustry:data.sectorList[key]["industryGroups"][i][
                                "subIndustryGroups"
                              ][si]["subIndustryName"]
                            });
                            subSubInL.push(data.sectorList[key]["industryGroups"][i][
                              "subIndustryGroups"
                            ][si]["subSubIndustry"][ssi]["subsubindustryId"]);
                          });
                          arrSubSubIndustry = arrSubSubIndustry.concat(innerSubSubIndustry);
                        });
                        arrSubIndustry = arrSubIndustry.concat(innerSubIndustry);
                      }
                    );
                    arrIndustry = arrIndustry.concat(innerIndustry);
                  });
                  allParams[type] = { arrSector, arrIndustry, arrSubIndustry,arrSubSubIndustry };
                  allParams[PARAM_SECTOR] = scL;
                  allParams[PARAM_INDUSTRY] = indL;
                  allParams[PARAM_SUB_INDUSTRY] = subInL;
                  allParams[PARAM_SUB_SUB_INDUSTRY] = [];  // for initial selection the sub sub industry values should not be passed
                break;
              case "contagion":
                defaultContagion = true;
                allParams[type] = true;
                break;
              case "countryRegionList":
                let rgnSl = [];
                let ctrySl = [];
                Object.keys(data.countryRegionList).forEach((key) => {
                  let countries = [];
                  rgnSl.push(data.countryRegionList[key]["id"]);
                  arrRgnList.push({
                    label: data.countryRegionList[key]["name"],
                    value: data.countryRegionList[key]["id"],
                    checked: true,
                  });
                  Object.keys(data.countryRegionList[key]["countries"]).forEach(
                    (k) => {
                      countries.push({
                        label:
                          data.countryRegionList[key]["countries"][k]["name"] +
                          " (" +
                          data.countryRegionList[key]["countries"][k]["code"] +
                          ")",
                        value:
                          data.countryRegionList[key]["countries"][k]["code"],
                        region: data.countryRegionList[key]["name"],
                        checked: true,
                      });
                      ctrySl.push(
                        data.countryRegionList[key]["countries"][k]["code"]
                      );
                    }
                  );
                  selectedCountry = selectedCountry.concat(countries);
                  selectedCountryContract = selectedCountry.concat(countries);
                  arrCtryList[data.countryRegionList[key]["name"]] = countries;
                });
                allParams[type] = {
                  arrCtryList: arrCtryList,
                  arrRgnList: arrRgnList,
                };
                allParams[PARAM_REGION] = rgnSl;
                allParams[PARAM_CONTRACT_REGION] = rgnSl;
                allParams[PARAM_COUNTRY] = ctrySl;
                allParams[PARAM_CONTRACT_COUNTRY] = ctrySl;
                break;
              default:
                break;
            }
          });

          allParams[PARAM_SOV_DEFINITION] =
            this.state.defaultSovDefaultDefinition;
			
          allParams[PARAM_EXCLUDE_DEVELOPED] =
            this.state.excludeDevelopedCountriesCounterpart;
			
          allParams[PARAM_EXCLUDE_DEVELOPED_CONTRACT] =
            this.state.excludeDevelopedCountriesContract;
			
          allParams[PARAM_SOV_FLAG] = this.state.defaultSovFlag;
          this.setState({
            paramList: data,
            defaultCurrencyType: defaultCurrencyType,
            defaultCrossDefault: defaultCrossDefault,
            defaultCrossDefaultContract: defaultCrossDefaultContract,
            defaultLoanType: defaultLoanType,
            defaultResolutionType: defaultResolutionType,
            defaultSectorClassification: defaultSectorClassification,
            defaultMembers: defaultMembers,
            defaultCategory: defaultCategory,
            defaultLoanAmount: defaultLoanAmount,
            defaultContagion: defaultContagion,
            allParams: allParams,
            defaultCountry: arrCtryList,
            defaultCountryContract: arrCtryList,  
            defaultRegion: arrRgnList,
            defaultRegionContract: arrRgnList, 
            selectedCountry: selectedCountry,
            selectedCountryContract: selectedCountryContract,
            arrSector: arrSector,
            arrIndustry: arrIndustry,
            arrSubIndustry: arrSubIndustry,
            currentSector: arrSector,
            currentIndustry: arrIndustry,
            currentSubIndustry: arrSubIndustry,
            arrSubSubIndustry:arrSubSubIndustry,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            sessionStorage.clear();
            window.location.replace("/");
          }
        }
      });
  }

  synchronizeParamOnEnvChange(isEoy) {
    let membersCode = this.state.paramList.members;
    let allParams = this.state.allParams;
    if (membersCode != null && allParams != null) {
      if (isEoy) {
        let members = [];
        Object.keys(membersCode).forEach((key) => {
          members.push(membersCode[key]);
        });
        allParams[MEMBERS] = members;
        this.setState({ allParams: allParams });
      } else {
        let member = [membersCode[this.props.userData.institutionAbbr]];
        allParams[MEMBERS] = member;
        this.setState({ allParams: allParams });
      }
    }
  }

//  sectorMappingToggle(){
//    let allParams = this.state.allParams;
//    let currentSector=[];
//    let currentIndustry=[];
//    let currentSubIndustry=[];
//
//    currentSector = this.state.arrSector;
//    currentIndustry = this.state.arrIndustry;
//    currentSubIndustry = this.state.arrSubIndustry;
//
//    this.setState({allParams: allParams,
//      currentSector:currentSector,
//      currentIndustry:currentIndustry,
//      currentSubIndustry:currentSubIndustry,
//
//    });
//  }

  // Get Parameters input
  getParamInput(params, type) {
    let allParams = this.state.allParams;
    switch (type) {
      case PARAM_MEMBERS:
        let members = [];
        let membersCode = this.state.paramList.members;
        Object.keys(params).forEach((key) => {
          if (params[key] === true) {
            members.push(membersCode[key]);
          }
        });
        allParams[MEMBERS] = members;
        this.setState({ members: members, allParams: allParams });
        break;
      case PARAM_CATEGORY:
        let category = [];
        let categoryCode = this.state.paramList.category;
        Object.keys(params).forEach((key) => {
          if (params[key] === true) {
            category.push(categoryCode[key]);
          }
        });
        allParams[CATEGORY] = category;
        this.setState({ category: category, allParams: allParams });
        break;
      case PARAM_CPRT_CROSS_DEFAULT_CLAUSE:
        let crossDefaultClauseCounterpart = [];
        let crossDefaultClauseCounterpartCode =
          this.state.paramList.crossDefaultClause;
        Object.keys(params).forEach((key) => {
          if (params[key] === true) {
            crossDefaultClauseCounterpart.push(
              crossDefaultClauseCounterpartCode[key]
            );
          }
        });
        allParams[CROSS_DEFAULT_CLAUSE + "Counterpart"] =
          crossDefaultClauseCounterpart;
        this.setState({
          crossDefaultClauseCounterpart: crossDefaultClauseCounterpart,
          allParams: allParams,
        });
        break;
      case PARAM_REGION:
        let regionList = [];
        let countryList = [];
        Object.keys(params.region).forEach((r) => {
          regionList.push(params.region[r]["value"]);
        });
        Object.keys(params.country).forEach((c) => {
          countryList.push(params.country[c]["value"]);
        });

        allParams[PARAM_REGION] = regionList;
        allParams[PARAM_COUNTRY] = countryList;
		

        this.setState({
          paramSelectedRegion: regionList,
          paramSelectedCountry: countryList,
          allParams: allParams,
        });
        break;
        case PARAM_CONTRACT_REGION:
          let regionContractList = [];
          let countryContractList = [];
          Object.keys(params.regionContract).forEach((r) => {
            regionContractList.push(params.regionContract[r]["value"]);
          });
          Object.keys(params.countryContract).forEach((c) => {
            countryContractList.push(params.countryContract[c]["value"]);
          });
  
          allParams[PARAM_CONTRACT_REGION] = regionContractList;
          allParams[PARAM_CONTRACT_COUNTRY] = countryContractList;
          this.setState({
            paramSelectedRegionContract: regionContractList,
            paramSelectedCountryContract: countryContractList,
            allParams: allParams,
          });
          break;
        case PARAM_SUB_INDUSTRY:
        let sectorList = [];
        let industryList = [];
        let subIndustryList = [];
        let subSubIndustryList = [];

        if (params.sector != null) {
          Object.keys(params.sector).forEach((s) => {
            if (params.sector[s]["value"] !== "*") {
              sectorList.push(params.sector[s]["value"]);
            }
          });
        }

        if (params.industry != null) {
          Object.keys(params.industry).forEach((i) => {
            if (params.industry[i]["value"] !== "*") {
              industryList.push(params.industry[i]["value"]);
            }
          });
        }

        if (params.subIndustry != null) {
          Object.keys(params.subIndustry).forEach((m) => {
            if (params.subIndustry[m]["value"] !== "*") {
              subIndustryList.push(params.subIndustry[m]["value"]);
            }
          });
        }

        if(params.subSubIndustry!=null){
          Object.keys(params.subSubIndustry).forEach((mm)=>{
            if (params.subSubIndustry[mm]["value"] !== "*") {
              subSubIndustryList.push(params.subSubIndustry[mm]["value"]);
            }
          });
        }


          allParams[PARAM_SECTOR] = sectorList;
          allParams[PARAM_INDUSTRY] = industryList;
          allParams[PARAM_SUB_INDUSTRY] = subIndustryList;
          allParams[PARAM_SUB_SUB_INDUSTRY] = subSubIndustryList;
        break;
      case PARAM_COUNTRY:
        regionList = [];
        countryList = [];
        if (params.region != null) {
          Object.keys(params.region).forEach((r) => {
            if (params.region[r]["value"] !== "*") {
              regionList.push(params.region[r]["value"]);
            }
          });
        }

        if (params.country != null) {
          Object.keys(params.country).forEach((c) => {
            if (params.country[c]["value"] !== "*") {
              countryList.push(params.country[c]["value"]);
            }
          });
        }

        allParams[PARAM_REGION] = regionList;
        allParams[PARAM_COUNTRY] = countryList;
        this.setState({
          paramSelectedRegion: regionList,
          paramSelectedCountry: countryList,
          allParams: allParams,
        });
        break;
        case PARAM_CONTRACT_COUNTRY:
          regionContractList = [];
          countryContractList = [];
          if (params.regionContract != null) {
            Object.keys(params.regionContract).forEach((r) => {
              if (params.regionContract[r]["value"] !== "*") {
                regionContractList.push(params.regionContract[r]["value"]);
              }
            });
          }
  
          if (params.countryContract != null) {
            Object.keys(params.countryContract).forEach((c) => {
              if (params.countryContract[c]["value"] !== "*") {
                countryContractList.push(params.countryContract[c]["value"]);
              }
            });
          }
  
          allParams[PARAM_CONTRACT_REGION] = regionContractList;
          allParams[PARAM_CONTRACT_COUNTRY] = countryContractList;
          this.setState({
            paramSelectedRegionContract: regionContractList,
            paramSelectedCountryContract: countryContractList,
            allParams: allParams,
          });
          break;
        case PARAM_CONTAGION:
        allParams[CONTAGION] = params;
        this.setState({ contagion: params, allParams: allParams });
        break;
      case PARAM_CONTRACT_CROSS_DEFAULT_CLAUSE:
        let crossDefaultClauseContract = [];
        let crossDefaultClauseContractCode =
          this.state.paramList.crossDefaultClause;
        Object.keys(params).forEach((key) => {
          if (params[key] === true) {
            crossDefaultClauseContract.push(
              crossDefaultClauseContractCode[key]
            );
          }
        });
        allParams[CROSS_DEFAULT_CLAUSE + "Contract"] =
          crossDefaultClauseContract;
        this.setState({
          crossDefaultClauseContract: crossDefaultClauseContract,
          allParams: allParams,
        });
        break;
      case PARAM_CURRENCY_TYPE:
        let currencyType = [];
        let currencyTypeCode = this.state.paramList.currencyType;
        Object.keys(params).forEach((key) => {
          if (params[key] === true) {
            currencyType.push(currencyTypeCode[key]);
          }
        });
        allParams[CURRENCY_TYPE] = currencyType;
        this.setState({ currencyType: currencyType, allParams: allParams });
        break;
      case PARAM_LOAN_TYPE:
        let loanType = [];
        let loanTypeCode = this.state.paramList.loanType;
        Object.keys(params).forEach((key) => {
          if (params[key] === true) {
            loanType.push(loanTypeCode[key]);
          }
        });
        allParams[LOAN_TYPE] = loanType;
        this.setState({ loanType: loanType, allParams: allParams });
        break;
        case PARAM_RESOLUTION_TYPE:
          let resolutionType = [];
          let resolutionTypeCode = this.state.paramList.resolutionType;
          Object.keys(params).forEach((key) => {
            if (params[key] === true) {
              resolutionType.push(resolutionTypeCode[key]);
            }
          });
          allParams[RESOLUTION_TYPE] = resolutionType;
          this.setState({ resolutionType: resolutionType, allParams: allParams });
          break;
      case PARAM_SECTOR_CLASSIFICATION:
          let sectorClassification = [];
          let sectorClassificationCode = this.state.paramList.sectorClassification;
          Object.keys(params).forEach((key) => {
            if (params[key] === true) {
              sectorClassification.push(sectorClassificationCode[key]);
            }
          });
          allParams[SECTOR_CLASSIFICATION] = sectorClassification;
          this.setState({ sectorClassification: sectorClassification, allParams: allParams });
          break;
      case PARAM_ORGINAL_AMT_LOAN:
        let loanAmount = [];
        let loanAmountCode = this.state.paramList.loanAmount;
        Object.keys(params).forEach((key) => {
          if (params[key] === true) {
            loanAmount.push(loanAmountCode[key]);
          }
        });
        allParams[LOAN_AMOUNT] = loanAmount;
        this.setState({ loanAmount: loanAmount, allParams: allParams });
        break;
      case PARAM_SOV_DEFINITION:
        allParams[PARAM_SOV_DEFINITION] = params;
        this.setState({ sovDefaultDefinition: params, allParams: allParams });
        break;
		
      case PARAM_SOV_FLAG:
        allParams[PARAM_SOV_FLAG] = params;
        this.setState({  sovFlag: params, allParams: allParams });
        break;

      case PARAM_EXCLUDE_DEVELOPED:
        allParams[PARAM_EXCLUDE_DEVELOPED] = params;
        this.setState({ excludeDevelopedCountriesCounterpart: params, allParams: allParams });
        break;
		
      case PARAM_EXCLUDE_DEVELOPED_CONTRACT:
        allParams[PARAM_EXCLUDE_DEVELOPED_CONTRACT] = params;
        this.setState({ excludeDevelopedCountriesContract: params, allParams: allParams });
        break;

      default:
        break;
    }
  }

  getObject(list, search, attribute) {
    for (var i = 0; i < list.length; i++) {
      if (list[i][attribute] === search) {
        return list[i];
      }
    }
    return null;
  }

  ratingOrSummaryClick(type, data, rating) {
    this.setState({ currentPDResultType: type, currentSelectedRating: rating });
  }

  isDataInQueue(list) {
    for (var cmp in list) {
      if (list[cmp].length > 0) {
        return true;
      }
    }
    return false;
  }

  getMMChartSVG() {
    return this.refs.mmResult.getMMChartSVG();
  }

  getParams(name, value) {
    this.setState({ [name]: value });
  }

  isFullCompute() {
    if (
      this.state.computeResponses &&
      this.state.computeResponses.pd &&
      this.state.computeResponses.pd[this.state.selectedIndex] &&
      this.state.computeResponses.pd[this.state.selectedIndex].queryOp &&
      this.state.computeResponses.pd[this.state.selectedIndex].queryOp === "fc"
    ) {
      return true;
    }
    return false;
  }

  isUserSuperAdmin() {
    if (
      this.props.userData &&
      this.props.userData.userRole &&
      this.props.userData.userRole === 1
    ) {
      return true;
    }
    return false;
  }

  isWorkEnvironmentMode() {
    if (
      this.state.computeResponses &&
      this.state.computeResponses.pd &&
      this.state.computeResponses.pd[this.state.selectedIndex] &&
      this.state.computeResponses.pd[this.state.selectedIndex].params &&
      this.state.computeResponses.pd[this.state.selectedIndex].params.mode &&
      this.state.computeResponses.pd[this.state.selectedIndex].params.mode ===
        "Work Environment"
    ) {
      return true;
    }
  }

  isRatingCountEnabled() {
    if (
      !this.isFullCompute() &&
      (this.isUserSuperAdmin() || this.isWorkEnvironmentMode())
    ) {
      return true;
    }
    return false;
  }

  clearFocusDd() {
    if (this.onCompute != null) {
      this.onCompute.onCompute();
    }
  }

  render() {
    let resultSummary =
      this.state.computeResponses.pd.length > 0 &&
      typeof this.state.computeResponses.pd[this.state.selectedIndex] ==
        "object" ? (
        <div className="row result-for-table">
          <div className="col-md-6">
            <h5 className="rating-head">
              Annual Default Rate - <span>Dynamic Cohort Approach</span>{" "}
            </h5>
            <DynamicCohort
              dynamic_cohort={
                this.state.computeResponses.pd[this.state.selectedIndex]
                  .dynamicCohort
              }
            />
          </div>
          <div className="col-md-6">
            <h5 className="rating-head">
              Annual Default Rate - <span>Static Cohort Approach</span>{" "}
            </h5>
            <StaticCohort
              static_cohort={
                this.state.computeResponses.pd[this.state.selectedIndex]
                  .staticCohort
              }
            />
          </div>
        </div>
      ) : null;

    let resultRating = null;
    if (
      this.state.computeResponses.pd.length > 0 &&
      this.state.showResult &&
      typeof this.state.computeResponses.pd[this.state.selectedIndex] ==
        "object"
    ) {
      resultRating = (
        <RatingTable
          rating_data={
            this.state.computeResponses.pd[this.state.selectedIndex]
              .rating_data[this.state.currentSelectedRating]
          }
          rating={this.state.currentSelectedRating}
          mdr_dynamic={
            this.state.computeResponses.pd[this.state.selectedIndex]
              .rating_mdr_dynamic_data[this.state.currentSelectedRating]
          }
          mdr_static={
            this.state.computeResponses.pd[this.state.selectedIndex]
              .rating_mdr_static_data[this.state.currentSelectedRating]
          }
          dynamic_wmdr_cdr={
            this.state.computeResponses.pd[this.state.selectedIndex]
              .dynamic_wmdr_cdr_data[this.state.currentSelectedRating]
          }
          static_wmdr_cdr={
            this.state.computeResponses.pd[this.state.selectedIndex]
              .static_wmdr_cdr_data[this.state.currentSelectedRating]
          }
          enableRating={this.isRatingCountEnabled()}
        />
      );
    }

    let currentPDResultTypeToShow = null;
    if (this.state.currentPDResultType == null) {
      currentPDResultTypeToShow = null;
    } else if (this.state.currentPDResultType === TYPE_SUMMARY) {
      currentPDResultTypeToShow = resultSummary;
    } else {
      currentPDResultTypeToShow = resultRating;
    }

    let resultListModal = null;
    if (this.isDataInQueue(this.state.computeResponses)) {
      resultListModal = (
        <div>
          <h1 className="heading mt-4">Results List</h1>

          <div className="row">
            <div className="col-7 mr-auto">
              <div className="comm-section">
                <ResultListtable
                  computeResponses={this.state.computeResponses}
                  currentComputeShowCallback={this.setCurrentComputeResult.bind(
                    this
                  )}
                  deleteResult={this.deleteResult.bind(this)}
                  getMMChartSVG={this.getMMChartSVG.bind(this)}
                  saveQuery={this.saveQuery.bind(this)}
                  renderHiddenMMChart={this.renderHiddenMMChart.bind(this)}
                  removeHiddenMMChart={this.removeHiddenMMChart.bind(this)}
                />
              </div>
            </div>
            {this.state.showResult ? (
              <div className="col-5">
                <div className="comm-section">
                  <ResultlistPara
                    computeResponses={
                      this.state.computeResponses.pd[this.state.selectedIndex]
                    }
                  />
                </div>
              </div>
            ) : null}
            {this.state.showResultMM ? (
              <div className="col-5">
                <div className="comm-section">
                  <ResultlistPara
                    computeResponses={
                      this.state.computeResponses.mm[this.state.selectedIndex]
                    }
                  />
                </div>
              </div>
            ) : null}
            {this.state.showResultRR ? (
              <div className="col-5">
                <div className="comm-section">
                  <ResultlistPara
                    computeResponses={
                      this.state.computeResponses.rr[this.state.selectedIndex]
                    }
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      );
    }

    return (
      <div className="parameters">
        <div className="comm-section">
          <div className="row">
            <div className="col-9 mr-auto">
              <ParametersCompute
                paramList={this.state.paramList}
                defaultCurrencyType={this.state.defaultCurrencyType}
                defaultCrossDefault={this.state.defaultCrossDefault}
                defaultCrossDefaultContract={
                  this.state.defaultCrossDefaultContract
                }
                defaultLoanType={this.state.defaultLoanType}
                defaultResolutionType={this.state.defaultResolutionType}
                defaultSectorClassification={this.state.defaultSectorClassification}
                defaultMembers={this.state.defaultMembers}
                defaultCategory={this.state.defaultCategory}
                defaultLoanAmount={this.state.defaultLoanAmount}
                defaultContagion={this.state.defaultContagion}
                parametersCallback={this.getParamInput.bind(this)}
                countryList={this.state.defaultCountry}
                countryContractList={this.state.defaultCountryContract}
                regionList={this.state.defaultRegion}
                regionContractList={this.state.defaultRegionContract}
                selectedCountry={this.state.selectedCountry}
                selectedCountryContract={this.state.selectedCountryContract}
                sectorList={this.state.arrSector}
                industryList={this.state.arrIndustry}
                subIndustryList={this.state.arrSubIndustry}
                subSubIndustryList={this.state.arrSubSubIndustry}
                isEoy={this.state.isEoy}
                onCompute={(ref) => (this.onCompute = ref)}
                defaultSovDefaultDefinition={
                  this.state.defaultSovDefaultDefinition
                }
                excludeDevelopedCountriesCounterpart={this.state.defaultexcludeDevelopedCountriesCounterpart}
				excludeDevelopedCountriesContract={this.state.defaultexcludeDevelopedCountriesContract}
                defaultSovFlag={this.state.defaultSovFlag}
              />
            </div>
            <div className="col-3">
              <ComputeOption
                allParams={this.state.allParams}
                clearFocusDd={this.clearFocusDd.bind(this)}
                computeCallback={this.computeResultPush.bind(this)}
                memberMap={
                  this.state.paramList != null
                    ? this.state.paramList.members
                    : []
                }
                categoryMap={
                  this.state.paramList != null
                    ? this.state.paramList.category
                    : []
                }
                crossDefaultClauseMap={
                  this.state.paramList != null
                    ? this.state.paramList.crossDefaultClause
                    : []
                }
                currencyTypeMap={
                  this.state.paramList != null
                    ? this.state.paramList.currencyType
                    : []
                }
                loanTypeMap={
                  this.state.paramList != null
                    ? this.state.paramList.loanType
                    : []
                }
                resolutionTypeMap={
                  this.state.paramList != null
                    ? this.state.paramList.resolutionType
                    : []
                }
                sectorClassificationMap={
                  this.state.paramList != null
                    ? this.state.paramList.sectorClassification
                    : []
                }
                loanAmounMap={
                  this.state.paramList != null
                    ? this.state.paramList.loanAmount
                    : []
                }
                beingExecutedCallback={this.addBeingExecutedToQueue.bind(this)}
                deleteBeingExecutedCallbackIfError={this.removeBeingExecutedFromQueue.bind(
                  this
                )}
                envCallback={this.getParams.bind(this)}
                synchronizeParamOnEnvChange={this.synchronizeParamOnEnvChange.bind(
                  this
                )}
              />
            </div>
          </div>
        </div>
        {resultListModal}
        {this.state.showResult ? (
          <span>
            <h1 className="heading mt-4">
              Results For :{" "}
              <span className="normal-weight">
                {this.state.computeResponses.pd[this.state.selectedIndex].name +
                  " (" +
                  this.state.computeResponses.pd[this.state.selectedIndex]
                    .accr +
                  ") " +
                  formatDate(
                    this.state.computeResponses.pd[this.state.selectedIndex]
                      .dateTime
                  )}
              </span>
            </h1>
            <div className="rating-summary">
              Ratings:{" "}
              <a
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_SUMMARY, null, null)
                }
              >
                Summary
              </a>{" "}
              <a
                hidden={
                  (this.props.userData != null
                    ? this.props.userData.userRole !== 1 &&
                      this.state.computeResponses &&
                      this.state.computeResponses &&
                      this.state.computeResponses.pd &&
                      this.state.computeResponses.pd[
                        this.state.selectedIndex
                      ] &&
                      this.state.computeResponses.pd[this.state.selectedIndex]
                        .params.mode === "Annual Report Environment"
                      ? true
                      : false
                    : true) ||
                  (this.state.computeResponses &&
                    this.state.computeResponses.pd &&
                    this.state.computeResponses.pd[this.state.selectedIndex]
                      .noData === true)
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, GI1)
                }
              >
                GI1
              </a>{" "}
              <a
                hidden={
                  (this.props.userData != null
                    ? this.props.userData.userRole !== 1 &&
                      this.state.computeResponses &&
                      this.state.computeResponses &&
                      this.state.computeResponses.pd &&
                      this.state.computeResponses.pd[
                        this.state.selectedIndex
                      ] &&
                      this.state.computeResponses.pd[this.state.selectedIndex]
                        .params.mode === "Annual Report Environment"
                      ? true
                      : false
                    : true) ||
                  (this.state.computeResponses &&
                    this.state.computeResponses.pd &&
                    this.state.computeResponses.pd[this.state.selectedIndex]
                      .noData === true)
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, GI2)
                }
              >
                GI2
              </a>{" "}
              <a
                hidden={
                  (this.props.userData != null
                    ? this.props.userData.userRole !== 1 &&
                      this.state.computeResponses &&
                      this.state.computeResponses &&
                      this.state.computeResponses.pd &&
                      this.state.computeResponses.pd[
                        this.state.selectedIndex
                      ] &&
                      this.state.computeResponses.pd[this.state.selectedIndex]
                        .params.mode === "Annual Report Environment"
                      ? true
                      : false
                    : true) ||
                  (this.state.computeResponses &&
                    this.state.computeResponses.pd &&
                    this.state.computeResponses.pd[this.state.selectedIndex]
                      .noData === true)
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, GI3)
                }
              >
                GI3
              </a>{" "}
              <a
                hidden={
                  (this.props.userData != null
                    ? this.props.userData.userRole !== 1 &&
                      this.state.computeResponses &&
                      this.state.computeResponses &&
                      this.state.computeResponses.pd &&
                      this.state.computeResponses.pd[
                        this.state.selectedIndex
                      ] &&
                      this.state.computeResponses.pd[this.state.selectedIndex]
                        .params.mode === "Annual Report Environment"
                      ? true
                      : false
                    : true) ||
                  (this.state.computeResponses &&
                    this.state.computeResponses.pd &&
                    this.state.computeResponses.pd[this.state.selectedIndex]
                      .noData === true)
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, GI4)
                }
              >
                GI4
              </a>{" "}
              <a
                hidden={
                  (this.props.userData != null
                    ? this.props.userData.userRole !== 1 &&
                      this.state.computeResponses &&
                      this.state.computeResponses &&
                      this.state.computeResponses.pd &&
                      this.state.computeResponses.pd[
                        this.state.selectedIndex
                      ] &&
                      this.state.computeResponses.pd[this.state.selectedIndex]
                        .params.mode === "Annual Report Environment"
                      ? true
                      : false
                    : true) ||
                  (this.state.computeResponses &&
                    this.state.computeResponses.pd &&
                    this.state.computeResponses.pd[this.state.selectedIndex]
                      .noData === true)
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, GI5)
                }
              >
                GI5
              </a>{" "}
              <a
                hidden={
                  (this.props.userData != null
                    ? this.props.userData.userRole !== 1 &&
                      this.state.computeResponses &&
                      this.state.computeResponses &&
                      this.state.computeResponses.pd &&
                      this.state.computeResponses.pd[
                        this.state.selectedIndex
                      ] &&
                      this.state.computeResponses.pd[this.state.selectedIndex]
                        .params.mode === "Annual Report Environment"
                      ? true
                      : false
                    : true) ||
                  (this.state.computeResponses &&
                    this.state.computeResponses.pd &&
                    this.state.computeResponses.pd[this.state.selectedIndex]
                      .noData === true)
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, GI6)
                }
              >
                GI6
              </a>{" "}
              <a
                hidden={
                  (this.props.userData != null
                    ? this.props.userData.userRole !== 1 &&
                      this.state.computeResponses &&
                      this.state.computeResponses &&
                      this.state.computeResponses.pd &&
                      this.state.computeResponses.pd[
                        this.state.selectedIndex
                      ] &&
                      this.state.computeResponses.pd[this.state.selectedIndex]
                        .params.mode === "Annual Report Environment"
                      ? true
                      : false
                    : true) ||
                  (this.state.computeResponses &&
                    this.state.computeResponses.pd &&
                    this.state.computeResponses.pd[this.state.selectedIndex]
                      .noData === true)
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, GI7)
                }
              >
                GI7
              </a>{" "}
              <a
                hidden={
                  (this.props.userData != null
                    ? this.props.userData.userRole !== 1 &&
                      this.state.computeResponses &&
                      this.state.computeResponses &&
                      this.state.computeResponses.pd &&
                      this.state.computeResponses.pd[
                        this.state.selectedIndex
                      ] &&
                      this.state.computeResponses.pd[this.state.selectedIndex]
                        .params.mode === "Annual Report Environment"
                      ? true
                      : false
                    : true) ||
                  (this.state.computeResponses &&
                    this.state.computeResponses.pd &&
                    this.state.computeResponses.pd[this.state.selectedIndex]
                      .noData === true)
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, GI8)
                }
              >
                GI8
              </a>{" "}
              <a
                hidden={
                  (this.props.userData != null
                    ? this.props.userData.userRole !== 1 &&
                      this.state.computeResponses &&
                      this.state.computeResponses &&
                      this.state.computeResponses.pd &&
                      this.state.computeResponses.pd[
                        this.state.selectedIndex
                      ] &&
                      this.state.computeResponses.pd[this.state.selectedIndex]
                        .params.mode === "Annual Report Environment"
                      ? true
                      : false
                    : true) ||
                  (this.state.computeResponses &&
                    this.state.computeResponses.pd &&
                    this.state.computeResponses.pd[this.state.selectedIndex]
                      .noData === true)
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, GI9)
                }
              >
                GI9
              </a>{" "}
              <a
                hidden={
                  (this.props.userData != null
                    ? this.props.userData.userRole !== 1 &&
                      this.state.computeResponses &&
                      this.state.computeResponses &&
                      this.state.computeResponses.pd &&
                      this.state.computeResponses.pd[
                        this.state.selectedIndex
                      ] &&
                      this.state.computeResponses.pd[this.state.selectedIndex]
                        .params.mode === "Annual Report Environment"
                      ? true
                      : false
                    : true) ||
                  (this.state.computeResponses &&
                    this.state.computeResponses.pd &&
                    this.state.computeResponses.pd[this.state.selectedIndex]
                      .noData === true)
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, GI10)
                }
              >
                GI10
              </a>{" "}
              <a
                hidden={
                  this.state.computeResponses &&
                  this.state.computeResponses.pd &&
                  this.state.computeResponses.pd[this.state.selectedIndex]
                    .noData === true
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, Gs1)
                }
              >
                Gs1
              </a>{" "}
              <a
                hidden={
                  this.state.computeResponses &&
                  this.state.computeResponses.pd &&
                  this.state.computeResponses.pd[this.state.selectedIndex]
                    .noData === true
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, Gs2)
                }
              >
                Gs2
              </a>{" "}
              <a
                hidden={
                  this.state.computeResponses &&
                  this.state.computeResponses.pd &&
                  this.state.computeResponses.pd[this.state.selectedIndex]
                    .noData === true
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, Gs3)
                }
              >
                Gs3
              </a>{" "}
              <a
                hidden={
                  this.state.computeResponses &&
                  this.state.computeResponses.pd &&
                  this.state.computeResponses.pd[this.state.selectedIndex]
                    .noData === true
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, Gs4)
                }
              >
                Gs4
              </a>{" "}
              <a
                hidden={
                  this.state.computeResponses &&
                  this.state.computeResponses.pd &&
                  this.state.computeResponses.pd[this.state.selectedIndex]
                    .noData === true
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, Gs5)
                }
              >
                Gs5
              </a>{" "}
              <a
                hidden={
                  this.state.computeResponses &&
                  this.state.computeResponses.pd &&
                  this.state.computeResponses.pd[this.state.selectedIndex]
                    .noData === true
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, Gs6)
                }
              >
                Gs6
              </a>{" "}
              <a
                hidden={
                  this.state.computeResponses &&
                  this.state.computeResponses.pd &&
                  this.state.computeResponses.pd[this.state.selectedIndex]
                    .noData === true
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, Gs7)
                }
              >
                Gs7
              </a>{" "}
              <a
                hidden={
                  this.state.computeResponses &&
                  this.state.computeResponses.pd &&
                  this.state.computeResponses.pd[this.state.selectedIndex]
                    .noData === true
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, Gs8)
                }
              >
                Gs8
              </a>{" "}
              <a
                hidden={
                  this.state.computeResponses &&
                  this.state.computeResponses.pd &&
                  this.state.computeResponses.pd[this.state.selectedIndex]
                    .noData === true
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, Gs9)
                }
              >
                Gs9
              </a>{" "}
              <a
                hidden={
                  this.state.computeResponses &&
                  this.state.computeResponses.pd &&
                  this.state.computeResponses.pd[this.state.selectedIndex]
                    .noData === true
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, Gs10)
                }
              >
                Gs10
              </a>{" "}
              <a
                hidden={
                  this.state.computeResponses &&
                  this.state.computeResponses.pd &&
                  (this.state.computeResponses.pd[this.state.selectedIndex]
                    .noData === true ||
                    !this.state.computeResponses.pd[this.state.selectedIndex]
                      .displayRating === false)
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, Gs11)
                }
              >
                Gs11
              </a>{" "}
              <a
                hidden={
                  this.state.computeResponses &&
                  this.state.computeResponses.pd &&
                  (this.state.computeResponses.pd[this.state.selectedIndex]
                    .noData === true ||
                    !this.state.computeResponses.pd[this.state.selectedIndex]
                      .displayRating === false)
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, NR)
                }
              >
                NR
              </a>{" "}
              <a
                hidden={
                  this.state.computeResponses &&
                  this.state.computeResponses.pd &&
                  (this.state.computeResponses.pd[this.state.selectedIndex]
                    .noData === true ||
                    !this.state.computeResponses.pd[this.state.selectedIndex]
                      .displayRating === false)
                }
                onClick={(e) =>
                  this.ratingOrSummaryClick(TYPE_RATING, null, SD)
                }
              >
                SD
              </a>
            </div>
          </span>
        ) : null}
        {this.state.showResult ? currentPDResultTypeToShow : null}
        {this.state.showResultMM ? (
          <span>
            <h1 className="heading mt-4">
              Results For :{" "}
              <span className="normal-weight">
                {this.state.computeResponses.mm[this.state.selectedIndex].name +
                  " (" +
                  this.state.computeResponses.mm[this.state.selectedIndex]
                    .accr +
                  ") " +
                  formatDate(
                    this.state.computeResponses.mm[this.state.selectedIndex]
                      .dateTime
                  )}
              </span>
            </h1>
          </span>
        ) : null}
        {this.state.showResultMM ? (
          <MMResult
            ref="mmResult"
            mmMapData={this.state.computeResponses.mm[this.state.selectedIndex]}
          />
        ) : null}
        {this.state.showResultRR ? (
          <span>
            <h1 className="heading mt-4">
              Results For :{" "}
              <span className="normal-weight">
                {this.state.computeResponses.rr[this.state.selectedIndex].name +
                  " (" +
                  this.state.computeResponses.rr[this.state.selectedIndex]
                    .accr +
                  ") " +
                  formatDate(
                    this.state.computeResponses.rr[this.state.selectedIndex]
                      .dateTime
                  )}
              </span>
            </h1>
          </span>
        ) : null}
        {this.state.showResultRR ? (
          <RRResult
            rrMapData={this.state.computeResponses.rr[this.state.selectedIndex]}
          />
        ) : null}
        {this.state.renderHiddenMMChart ? (
          <MMResult
            ref="mmResult"
            mmMapData={
              this.state.computeResponses.mm[this.state.selectedHiddenIndex]
            }
            renderHiddenMMChart={true}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,
  };
};

export default connect(mapStateToProps, null)(ComputeMain);

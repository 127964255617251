export const ExcelViewActionAdd = "add";
export const ExcelViewActionUpdate = "update";
export const ExcelViewActionDelete = "delete";
export const LoginInvalidEmailPassword = "Invalid Email or Password.";
export const DateFormatDMMMYYYY = "D-MMM-YY";
export const UNKNOWN_ERROR = "Unknown error occured.";
export const ERROR_OCCURED = "An error occured. Please try again.";
export const ACTION_USER_ADD = "USR_ADD";
export const ACTION_USER_UPDATE = "USR_UPDATE";
export const ACTION_INSTITUTE_ADD = "INST_ADD";
export const ACTION_INSTITUTE_UPDATE = "INST_UPDATE";
export const UPLOAD_TOOLTIP =
  "Please ensure that the data being uploaded belongs to your institution.";
export const ALLOWED = "Allowed";
export const NOT_ALLOWED = "Not allowed";
export const ENABLED = "Enabled";
export const DISABLED = "Disabled";
export const REQUEST_CHECK_MY_DATA = "review";
export const REVOKE_CHECK_MY_DATA = "revokeReview";
export const ADD = 1;
export const REMOVE = 0;
export const ACT_COUNTERPART = "ACT_COUNTERPART";
export const REF_COUNTERPART = "REF_COUNTERPART";
export const DEF_CONTRACT = "DEF_CONTRACT";
export const DEF_COUNTERPART = "DEF_COUNTERPART";
export const REF_CONTRACT = "REF_CONTRACT";
export const GEM_IMPORT = "GEM_IMPORT";
export const REF_GUARANTOR = "REF_GUARANTOR";
export const REF_GUARANTEE = "REF_GUARANTEE";
export const REF_COLLATERAL = "REF_COLLATERAL";
export const FIFTY_MB = 50000000;
export const FILE_SIZE_LIMIT_EXCEED = "File size should not exceed 50 MB.";
export const FILE_NAME_SIZE_LIMIT_EXCEED = "File name is too long.";
export const UPLOAD_FORBIDDEN = "Upload is currently not allowed.";
export const YEAR_MIN = 1900;
export const YEAR_MAX = 2100;
export const MANDATORY_ACT_COUNTERPART = ["active_year", "id_counterpart"];
export const MANDATORY_REF_COUNTERPART = [
  "id_counterpart",
  "cod_category_ctp",
  "cod_sector_ctp",
  "cod_country_ctp",

  "maturity_ctp",
  "cod_sector_ref_ctp",
];
export const MANDATORY_REF_CONTRACT = [
  "id_contract",
  "id_counterpart",
  "cod_sector",
  "cod_country",
  "cod_loan",

  "signature_date",
  "maturity",
  "sovereign_flag",
  "currency_type",
  "cod_contract",
  "signed_amount",
  "cod_sector_ref",
  "outstanding_amt"
];
export const MANDATORY_DEF_COUNTERPART = [
  "id_counterpart",
  "id_default",
  "dat_default",
  "default_type",
  "resolution_type",
  "dat_resolution",
];
export const MANDATORY_DEF_CONTRACT = [
  "id_contract",
  "id_counterpart",
  "id_default",
  "dat_default_contract",
  "default_type",
  "resolution_type",
  "dat_resolution",
];
export const MANDATORY_GEM_IMPORT = [
  "id_counterpart",
  "id_contract",
  "id_default",
  "cod_rate_type",
  "cod_currency_tra",
  "id_tranche",
  "cod_currency_payment",
  "default_dat",
  "discount_dat",
  "dat_due",
  "interest_rate",
  "dat_paid",
  "reflow_origin",
  "origin_id"
];

export const MANDATORY_REF_GUARANTOR = [
  "id_gtor",
  "gtor_cod_country",
  "gtor_cod_region",
  "gtor_cod_sector",
  "gtor_cod_sector_ref",
];

export const MANDATORY_REF_GUARANTEE = [
  "id_gtor",
  "id_contract",
  "id_counterpart",
  "gtor_year",
  "gtor_cod_rating_boy",
  "coverage_pct"
];

export const MANDATORY_REF_COLLATERAL = [
  "id_counterpart",
  "id_contract",
  "id_collateral",
  "collateral_type"
];

export const TOAST_TYPE_SUCCESS = "success";
export const TOAST_TYPE_INFO = "info";
export const TOAST_TYPE_ERROR = "error";
export const SERVER_TIME_ZONE = "Asia/Kolkata";
export const RUN_CHECK_LIST = {
  checkHeader: "Check Header",
  checkMandatoryFields: "Check Mandatory Fields",
  checkDataConsistency: "Check Data Consistency",
  checkDRating: "Check D Rating",
  checkDataProperties: "Check Data Properties",
  checkVoluntaryFields: "Check Voluntary Fields",
  checkTableRelationships: "Check Table Relationships",
  checkDefaultAfterMaturity: "Check Default After Maturity",
  checkDates: "Check Dates",
  checkDataAccuracy: "Check Data Accuracy",
  checkDefaults: "Check Defaults",
  checkMissingELine: "Check Missing E Line",
};
export const DATA_VALIDATION_COMPLETED = "Data validation has been completed.";
export const DATA_VALIDATION_COMPLETED_WITH_ALL_RC_ERROR_CAT_4_5 =
  "Data validation has been completed. Please remove the BLOCK errors in order to proceed with data insertion.";
export const DATA_VALIDATION_COMPLETED_NO_ERROR_ALL_RC =
  "Data validation has been completed. You can proceed with data insertion.";
export const PRIVILEGE_ALL = 1;
export const TAG_TYPE_EMAIL = "EMAIL";
export const TAG_TYPE_LIBRARY = "LIBRARY";

export const PARAM_TYPE_LIST_COUNTERPART = [
  "Category",
  "Cross-Default Clause",
  "Region-Country",
  "Sector-Industry Group",
  "Sector Classification",
  "Sovereign Default Definition",
];
export const PARAM_TYPE_LIST_CONTRACT = [
  "Contagion",
  "Cross-Default Clause",
  "Region-Country",
  "Currency Type",
  "Loan Type",
  "Original Amount of Loan",
  "Sovereign Flag",
  "Resolution Type"
];

export const PARAM_MEMBERS = "member";
export const PARAM_CATEGORY = "category";
export const PARAM_CPRT_CROSS_DEFAULT_CLAUSE = "cprt_cdc";
export const PARAM_REGION = "region";
export const PARAM_CONTRACT_REGION = "regionContract";
export const PARAM_SECTOR_INDUSTRY = "sector_industry";
export const PARAM_SECTOR = "sector";
export const PARAM_INDUSTRY = "industry";
export const PARAM_SUB_INDUSTRY = "sub_industry";
export const PARAM_SUB_SUB_INDUSTRY = "sub_sub_industry";
export const PARAM_SECTOR_CLASSIFICATION = "sector_classification";
export const PARAM_COUNTRY = "country";
export const PARAM_CONTRACT_COUNTRY = "countryContract";
export const PARAM_CONTAGION = "contagion";
export const PARAM_CONTRACT_CROSS_DEFAULT_CLAUSE = "contract_cdc";
export const PARAM_CURRENCY_TYPE = "currency_type";
export const PARAM_LOAN_TYPE = "loan_type";
export const PARAM_RESOLUTION_TYPE = "resolution_type";
export const PARAM_ORGINAL_AMT_LOAN = "original_amt_loan";
export const ALL = "all";
export const NONE = "none";
export const ONLY_ME = "onlyme";
export const EXCEPT_ME = "exceptme";
export const PARAM_SOV_DEFINITION = "sovDefaultDefinition";
export const PARAM_SOV_FLAG = "sovFlag";
export const PARAM_EXCLUDE_DEVELOPED = "excludeDevelopedCountriesCounterpart";
export const PARAM_EXCLUDE_DEVELOPED_CONTRACT = "excludeDevelopedCountriesContract";


export const CURRENCY_TYPE = "currencyType";
export const CROSS_DEFAULT_CLAUSE = "crossDefaultClause";
export const LOAN_TYPE = "loanType";
export const RESOLUTION_TYPE = "resolutionType"
export const MEMBERS = "members";
export const CATEGORY = "category";
export const LOAN_AMOUNT = "loanAmount";
export const SECTOR_LIST = "sectorList";
export const SECTOR_CLASSIFICATION = "sectorClassification";
export const CONTAGION = "contagion";

export const TYPE_SUMMARY = "summary";
export const TYPE_RATING = "rating";

export const GI1 = "GI1";
export const GI2 = "GI2";
export const GI3 = "GI3";
export const GI4 = "GI4";
export const GI5 = "GI5";
export const GI6 = "GI6";
export const GI7 = "GI7";
export const GI8 = "GI8";
export const GI9 = "GI9";
export const GI10 = "GI10";

export const Gs1 = "Gs1";
export const Gs2 = "Gs2";
export const Gs3 = "Gs3";
export const Gs4 = "Gs4";
export const Gs5 = "Gs5";
export const Gs6 = "Gs6";
export const Gs7 = "Gs7";
export const Gs8 = "Gs8";
export const Gs9 = "Gs9";
export const Gs10 = "Gs10";
export const Gs11 = "Gs11";
export const NR = "NR";
export const SD = "SD";

export const PD = "PROBABILITY_DEFAULT";
export const RR = "RECOVERY_RATE";
export const MM = "MIGRATION_MATRIX";
export const FC = "FULL_COMPUTE";

export const PROBABILITY_OF_DEFAULT = "Probability of Default";
export const MIGRATION_MATRIX = "Migration Matrix";
export const RECOVERY_RATE = "Recovery Rate";
export const FULL_COMPUTE = "Full Compute";

export const DELETE_ALL = "all";
export const DELETE_ONE = "one";

export const UPLOAD_DISABLED_TITLE = "Upload Disabled";
export const UPLOAD_INSERT_TITLE = "Insert Disabled";

export const RR_PARAMS_HEADERS = [
  "Number of results",
  "Simple Average Recovery Rate",
  "Recovery Rate Standard Deviation",
];

export const RR_HEADERS = [
  "Category",
  "Contract Region",
  "Contract Sector",
  "Counterpart Region",
  "Counterpart Sector",
  "Counterpart ID",
  "Contract ID",
  "Default ID",
  "Default Date",
  "Exposure at Default",
  "Received + Expected Cashflows",
  "Recovery Rate",
];

export const RR_DISPLAY_RESULT =
  "The resultset is too small to be displayable.";
export const ACTION_EVENT_ADD = "EVENT_ADD";
export const ACTION_EVENT_UPDATE = "EVENT_UPDATE";

export const ANNUAL_MODE_SWITCH = "annual_mode_sw";
export const FILTER_INSTITUTE = "institute_filter";
export const FILTER_DATASET_YEAR = "dataset_filter";

export const ACTION_UPLOAD = "UP";
export const ACTION_MOD = "MOD";
export const ACTION_DB = "DB";
export const SUBMISSION_PENDING =
  "Data submission is pending! Please submit your data for the current year.";
export const SUBMISSION_DONE =
  "Data has been submitted to GEMs Support Team for review.";

export const NO_DISPLAY_RESULT = " No Results";

export const ACTION_QUERY_SAVE = "save_query";
export const ACTION_QUERY_DELETE = "delete_query";

export const TYPE_PD = "PD";
export const TYPE_MM = "MM";
export const TYPE_RR = "RR";
export const TYPE_FC = "FC";

export const SAVED_QUERY_LIMIT_EXCEED_MSG =
  "Only 10 queries can be executed at a time.";

export const CAL_ACTION_COMPUTE_TAP = "tap";
export const CAL_ACTION_SAVED_QUERY_TAP = "savedQueryTap";

export const SAVED_QUERY_SUCCESS = "Query has been saved successfully.";
export const SAVED_QUERY_FAIL = "Unable to save query.";
export const DELETE_QUERY_SUCCESS = "Query deleted successfully.";
export const DELETE_QUERY_FAIL = "Unable to delete query.";

export const AUDIT_UPLOAD = "UPLOAD";
export const AUDIT_INSERT = "INSERT";
export const AUDIT_CHK_MY_DATA = "CMD";

export const INVALID_QUERY_NAME = "Please enter Query Name";

export const DATE_RANGE_NOT_MRE_THN_5 =
  "Date range should not be more than 5 years.";
export const START_DATE_END_DATE_NOT_BLNK =
  "Start date and End date cannot be blank.";

export const DELETE_ONE_MESSAGE =
  "Do you want to delete the selected query result?";
export const DELETE_ALL_MESSAGE = "Do you want to delete all query results?";

export const EMPTY_COLUMN = "EMPTY_COLUMN";
export const MISSING_COLUMN = "MISSING_COLUMN";
export const DUPLICATE_COLUMN = "DUPLICATE_COLUMN";
export const EMPTY_COLUMN_MESSAGE = "Some columns in the sheet are blank.";
export const MISSING_COLUMN_MESSAGE = "Some columns in the sheet are missing.";
export const DUPLICATE_COLUMN_MESSAGE =
  "Duplicate columns are present in the sheet.";
export const ACTION_MAIL_ADD = "MAIL_ADD";
export const ACTION_MAIL_UPDATE = "MAIL_UPDATE";

export const SOV_DEFAULT_RESTRICT =
  "Restrict to corresponding sovereign internal default definition";
export const SOV_DEFAULT_NOT_RESTRICT =
  "Do not restrict to corresponding sovereign internal default definition";
export const SOV_FLAG_ALL = "All";
export const SOV_FLAG_YES = "Only sovereign flag set to Y";
export const SOV_FLAG_NO = "Only sovereign flag set to N";


import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
} from "reactstrap";
import instance from "../../instance";
import { authHeader } from "../commonUtility/auth-header";
import PropTypes from "prop-types";
import {
  PD,
  PROBABILITY_OF_DEFAULT,
  TOAST_TYPE_SUCCESS,
  MIGRATION_MATRIX,
  MM,
  RECOVERY_RATE,
  RR,
  TYPE_PD,
  TYPE_MM,
  TYPE_RR,
  CAL_ACTION_SAVED_QUERY_TAP,
  CAL_ACTION_COMPUTE_TAP,
  FC,
  PARAM_CONTRACT_REGION,
} from "../commonUtility/string-const";
import { showToast } from "../commonUtility/NewToast";
import { Promise } from "q";
import { FormErrors } from "../commonUtility/FormErrors";
import { connect } from "react-redux";
import moment from "moment";
import * as actions from "../../store/actions/index";
import {
  firtCharToUpperCaseWithoutSpace,
  randomComputeIDGenerator,
} from "../commonUtility/method-utils";
import _ from "lodash";

const yearValidationRegex = new RegExp("^198[4-9]|199[0-9]|20[0-9][0-9]|2100$");
const periodValidationRegex = new RegExp("^[1-9][0-9]?$|^100$");
const nbDefaultValidationRegex = new RegExp(
  "^(?:36[0-5]|3[0-5][0-9]|[12][0-9][0-9]|[1-9][0-9]|[1-9])$"
);

class ComputeOption extends Component {
  constructor(props) {
    super(props);

    
    this.state = {
      isEoy: false,
      isNewPeriod: true,
      isDefault180: false,
      modal: false,
      startYear: 1984,
      endYear: new Date().getFullYear() - 1,
      period: 1,
      nodaysbeforedefault: 90,
      thresholdUnresolvedContracts: 8,
      includeUnresolvedContracts: false,
      savedParams: [],
      formErrors: {
        startYear: "",
        endYear: "",
        period: "",
        thresholdUnresolvedContracts: "",
        nodaysbeforedefault: "",
      },
      startYearValid: true,
      endYearValid: true,
      periodValid: true,
      nodaysbeforedefaultValid: true,
      thresholdUnresolvedContractsValid: true,
      MMmodal: false,
      RRmodal: false,
      FCmodal: false,
      computedResultKeys: {},
      isPaused: false,
      fetchedAllSavedRequest: false,
      readyToQuery: false,
      computeFromSavedQuery: this.computeFromSavedQuery.bind(this),
      uselessStateVar: null,
      includeOutlook: false,
    };
    this.handleInput = this.handleInput.bind(this);
    this.openParamsModal = this.openParamsModal.bind(this);
    this.computePD = this.computePD.bind(this);
    this.toggle = this.toggle.bind(this);
    this.cancelToggle = this.cancelToggle.bind(this);
    this.getSavedComputePDResult = this.getSavedComputePDResult.bind(this);
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  focusOutOfDropdown() {
    this.props.clearFocusDd();
  }

  componentDidMount() {
    this.props.envCallback("isEoy", this.state.isEoy);

    let savedFCIds = sessionStorage.getItem("fullComputeIds");
    if (savedFCIds != null) {
      let fcToCompute = {};
      JSON.parse(savedFCIds).forEach((key) => {
        this.props.beingExecutedCallback(FC, key.dateTime, key.id);
        fcToCompute = {
          ...fcToCompute,
          [key.id]: [],
        };
      });
      this.setState({
        computedResultKeys: fcToCompute,
      });
    }
    this.interval = setInterval(() => {
      let savedFCIds = sessionStorage.getItem("fullComputeIds");
      if (savedFCIds != null && !this.state.isPaused)
        this.getSavedComputeFCResult(
          JSON.parse(savedFCIds).map((item) => item.id)
        );
    }, 10000);

    this.setState({
      endYear: new Date().getFullYear() - (this.state.isNewPeriod ? 1 : 2),
    });
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(
        prevProps.allParams,
        this.props.allParams
      )
    ) {
      let savedIds = this.getComputeId();
      let savedParamsObj = [];
      if (savedIds != null) {
        
        let savedParams = sessionStorage.getItem("computeParams");
        if (savedParams != null) {
          savedParamsObj = JSON.parse(savedParams);
        }
        this.setState(
          {
            savedParams: savedParamsObj,
          },
          () => {
            this.getSavedComputePDResult(savedIds.split(","));
            this.getSavedComputeMMResult(savedIds.split(","));
            this.getSavedComputeRRResult(savedIds.split(","));
          }
        );
      }
      if (
        this.props.savedQueryPlaylist !== null &&
        this.props.savedQueryPlaylist.length > 0
      ) {
        this.computeFromSavedQuery(this.props.savedQueryPlaylist);
      }
    }
  }

 

  computeFromSavedQuery(savedQueryPlaylist) {
    for (var i = 0; i < savedQueryPlaylist.length; i++) {
      let type = savedQueryPlaylist[i].type;
      switch (type) {
        case TYPE_PD:
          this.computePD(
            null,
            savedQueryPlaylist[i].param,
            CAL_ACTION_SAVED_QUERY_TAP
          );
          break;
        case TYPE_MM:
          this.computeMM(
            null,
            savedQueryPlaylist[i].param,
            CAL_ACTION_SAVED_QUERY_TAP
          );
          break;
        case TYPE_RR:
          this.computeRR(
            null,
            savedQueryPlaylist[i].param,
            CAL_ACTION_SAVED_QUERY_TAP
          );
          break;
        default:
      }
    }
    this.props.setSavedQueryPlaylist([]);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.props.setSavedQueryPlaylist([]);
  }

  
  handleInput(e) {
    let name = e.target.name;
    let value = e.target.value === "true" ? true : false;
    if (name === "isNewPeriod") {
      if (value) {
        this.setState({ endYear: new Date().getFullYear() - 1 });
      } else {
        this.setState({ endYear: new Date().getFullYear() - 2 });
      }
    }
    if (name === "isEoy") {
      this.props.envCallback(name, value);
      if (this.props.userData.userRole !== 1) {
        this.props.synchronizeParamOnEnvChange(value);
      }
    }
    this.setState({ [name]: value });
  }

  
  openParamsModal(modal) {
    this.setState({
      [modal]: true,
      startYear: this.state.isDefault180 ? 1984 : 1994 ,
      endYear: this.state.isNewPeriod
        ? new Date().getFullYear() - 1
        : new Date().getFullYear() - 2,
      period: 1,
      nodaysbeforedefault: this.state.isDefault180 ? 180 : 90,
      thresholdUnresolvedContracts: 8,
      includeUnresolvedContracts: false,
      startYearValid: true,
      endYearValid: true,
      periodValid: true,
      thresholdUnresolvedContractsValid: true,
      nodaysbeforedefaultValid: true,
      includeOutlook: false,
      formErrors: {
        startYear: "",
        endYear: "",
        period: "",
        nodaysbeforedefault: "",
        thresholdUnresolvedContracts: "",
      },
    });
  }

  
  getMemberListFromIds(ids) {
    let memberName = [];
    let membersMap = this.props.memberMap;
    for (var i = 0; i < ids.length; i++) {
      memberName.push(
        
        Object.keys(membersMap).find((key) => membersMap[key] == ids[i]) 
      );
    }
    return memberName;
  }

  
  getCategoryListFromIds(ids) {
    let categoryName = [];
    let categoryMap = this.props.categoryMap;
    for (var i = 0; i < ids.length; i++) {
      categoryName.push(
        firtCharToUpperCaseWithoutSpace(
          Object.keys(categoryMap).find((key) => categoryMap[key] === ids[i])
        )
      );
    }
    return categoryName;
  }

  
  getRegionListFromIds(ids) {
    let regionMapSelelected = [];
    let regionMap = this.props.allParams["countryRegionList"]["arrRgnList"];
    for (var i = 0; i < ids.length; i++) {
      Object.keys(regionMap).forEach((key) => {
        if (regionMap[key]["value"] === ids[i]) {
          regionMapSelelected.push(regionMap[key]["label"]);
        }
      });
    }
    return regionMapSelelected;
  }

  getSectorListFromIds(ids) {
    let sectorMapSelected = [];
    let sectorMap = this.props.allParams["sectorList"]["arrSector"];
    for (var i = 0; i < ids.length; i++) {
      Object.keys(sectorMap).forEach((key) => {
        if (sectorMap[key]["value"] === ids[i]) {
          sectorMapSelected.push(sectorMap[key]["label"]);
        }
      });
    }
    return sectorMapSelected;
  }

  getIndustryListFroIds(ids) {
    let industryMapSelected = [];
    let industryMap = this.props.allParams["sectorList"]["arrIndustry"];
    for (var i = 0; i < ids.length; i++) {
      Object.keys(industryMap).forEach((key) => {
        if (industryMap[key]["value"] === ids[i]) {
          industryMapSelected.push(industryMap[key]["label"]);
        }
      });
    }
    return industryMapSelected;
  }

  getSubIndustryListFromIds(ids) {
    let subIndustryMapSelected = [];
    let subIndustryMap = this.props.allParams["sectorList"]["arrSubIndustry"];
    for (var i = 0; i < ids.length; i++) {
      Object.keys(subIndustryMap).forEach((key) => {
        if (subIndustryMap[key]["value"] === ids[i]) {
          subIndustryMapSelected.push(subIndustryMap[key]["label"]);
        }
      });
    }
    return subIndustryMapSelected;
  }

  getSubSubIndustryListFromIds(ids) {
    let subSubIndustryMapSelected = [];
    let subSubIndustryMap = this.props.allParams["sectorList"]["arrSubSubIndustry"];
    for (var i = 0; i < ids.length; i++) {
      Object.keys(subSubIndustryMap).forEach((key) => {
        if (subSubIndustryMap[key]["value"] === ids[i]) {
          subSubIndustryMapSelected.push(subSubIndustryMap[key]["label"]);
        }
      });
    }
    return subSubIndustryMapSelected;
  }

  getCrossDefaultListFromIds(ids) {
    let cdcName = [];
    let cdcMap = this.props.crossDefaultClauseMap;
    for (var i = 0; i < ids.length; i++) {
      cdcName.push(Object.keys(cdcMap).find((key) => cdcMap[key] === ids[i]));
    }
    return cdcName;
  }

  
  getCurrencyTypeListFromIds(ids) {
    let currencyTypeName = [];
    let currencyTypeMap = this.props.currencyTypeMap;
    for (var i = 0; i < ids.length; i++) {
      currencyTypeName.push(
        Object.keys(currencyTypeMap).find(
          (key) => currencyTypeMap[key] === ids[i]
        )
      );
    }
    return currencyTypeName;
  }

  
  getLoanTypeListFromIds(ids) {
    let loanTypeName = [];
    let loanTypeMap = this.props.loanTypeMap;
    for (var i = 0; i < ids.length; i++) {
      loanTypeName.push(
        firtCharToUpperCaseWithoutSpace(
          Object.keys(loanTypeMap).find((key) => loanTypeMap[key] === ids[i])
        )
      );
    }
    return loanTypeName;
  }

  getResolutionTypeListFromIds(ids) {
    let resolutionTypeName = [];
    let resolutionTypeMap = this.props.resolutionTypeMap;
    for (var i = 0; i < ids.length; i++) {
      resolutionTypeName.push(
        firtCharToUpperCaseWithoutSpace(
          Object.keys(resolutionTypeMap).find((key) => resolutionTypeMap[key] === ids[i])
        )
      );
    }
    return resolutionTypeName;
  }


getSectorClassificationListFromIds(ids) {
    let sectorClassificationName = [];
    let sectorClassificationMap = this.props.sectorClassificationMap;
    for (var i = 0; i < ids.length; i++) {
      sectorClassificationName.push(
        firtCharToUpperCaseWithoutSpace(
          Object.keys(sectorClassificationMap).find((key) => sectorClassificationMap[key] === ids[i])
        )
      );
    }
    return sectorClassificationName;
  }
  
  getLoanAmountTypeListFromIds(ids) {
    let loanAmountTypeName = [];
    let loanAmountTypeMap = this.props.loanAmounMap;
    for (var i = 0; i < ids.length; i++) {
      loanAmountTypeName.push(
        Object.keys(loanAmountTypeMap).find(
          (key) => loanAmountTypeMap[key] === ids[i]
        )
      );
    }
    return loanAmountTypeName;
  }

  
  saveComputeId(computeID, queryParams) {
    let savedId = sessionStorage.getItem("computeIds");
    if (savedId == null) {
      sessionStorage.setItem("computeIds", computeID);
      
      let paramsObj = {};
      paramsObj[computeID] = queryParams;
      sessionStorage.setItem("computeParams", JSON.stringify(paramsObj));
    } else {
      sessionStorage.setItem("computeIds", savedId + "," + computeID);
      
      let savedParams = sessionStorage.getItem("computeParams");
      let savedParamsObj = JSON.parse(savedParams);
      savedParamsObj[computeID] = queryParams;
      sessionStorage.setItem("computeParams", JSON.stringify(savedParamsObj));
    }
  }

  
  saveFullComputeId(fullComputeObj) {
    let savedId = sessionStorage.getItem("fullComputeIds");
    if (savedId == null) {
      let computeObj = [];
      computeObj.push(fullComputeObj);
      sessionStorage.setItem("fullComputeIds", JSON.stringify(computeObj));
    } else {
      let computeParams = sessionStorage.getItem("fullComputeIds");
      let computeParamsObj = JSON.parse(computeParams);
      computeParamsObj.push(fullComputeObj);
      sessionStorage.setItem(
        "fullComputeIds",
        JSON.stringify(computeParamsObj)
      );
    }
  }

  
  getComputeId() {
    return sessionStorage.getItem("computeIds");
  }

  
  getComputeNameByKey(k) {
    if (k.toLowerCase().indexOf("computepd") !== -1) {
      k = "pd";
    } else if (k.toLowerCase().indexOf("computemm") !== -1) {
      k = "mm";
    } else if (k.toLowerCase().indexOf("computerr") !== -1) {
      k = "rr";
    }

    switch (k) {
      case "pd":
        return { name: PROBABILITY_OF_DEFAULT, type: PD, accr: "PD" };
      case "mm":
        return { name: MIGRATION_MATRIX, type: MM, accr: "MM" };
      case "rr":
        return { name: RECOVERY_RATE, type: RR, accr: "RR" };
      default:
        return { name: "", type: "", accr: "" };
    }
  }

  
  getSavedComputePDResult(computeIds) {
    const config = {
      headers: {
        ...authHeader(),
      },
    };

    let promises = [];
    let computeRes = [];
    for (var i = 0; i < computeIds.length; i++) {
      if (computeIds[i].toLowerCase().indexOf("computepd") !== -1) {
        this.props.beingExecutedCallback(
          PD,
          this.state.savedParams[computeIds[i]].dateTime,
          null
        );

        promises.push(
          instance
            .post(
              "/compute/defaultProbability/true/" + computeIds[i],
              {},
              config
            )
            .then((response) => {
              computeRes.push(response.data);
            })
            .catch((error) => {
              this.props.deleteBeingExecutedCallbackIfError(
                PD,
                this.state.savedParams[computeIds[i]].dateTime
              );
              if (error.response) {
                if (error.response.status === 401) {
                  sessionStorage.clear();
                  window.location.replace("/");
                } else {
                  showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
                }
              } else {
                showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
              }
            })
        );
      }
    }

    Promise.all(promises).then(() => {
      
      computeRes.sort(function(a, b) {
        let dtArr1 = a["dateTime"].split(" ");
        let date1 = dtArr1[0];
        let time1 = dtArr1[1];
        let dateArr1 = date1.split("-");
        let year1 = dateArr1[0];
        let month1 = dateArr1[1];
        let day1 = dateArr1[2];

        let timeArr1 = time1.split(":");
        let hour1 = timeArr1[0];
        let min1 = timeArr1[1];
        let sec1 = timeArr1[2];

        let dateObjA = new Date(year1, month1, day1, hour1, min1, sec1);

        let dtArr2 = b["dateTime"].split(" ");
        let date2 = dtArr2[0];
        let time2 = dtArr2[1];
        let dateArr2 = date2.split("-");
        let year2 = dateArr2[0];
        let month2 = dateArr2[1];
        let day2 = dateArr2[2];

        let timeArr2 = time2.split(":");
        let hour2 = timeArr2[0];
        let min2 = timeArr2[1];
        let sec2 = timeArr2[2];

        let dateObjB = new Date(year2, month2, day2, hour2, min2, sec2);

        return dateObjA - dateObjB;
      });
      for (var i = 0; i < computeRes.length; i++) {
        let _rating_data = null;
        let _rating_mdr_dynamic_data = null;
        let _rating_mdr_static_data = null;
        let _dynamic_wmdr_cdr_data = null;
        let _static_wmdr_cdr_data = null;
        let _dynamicCohort = null;
        let _dynamicCohortAverage = null;
        let _dynamicCohortStdDev = null;
        let _staticCohort = null;
        let _staticCohortAverage = null;
        let _staticCohortStdDev = null;
        let resPD = computeRes[i];
        _dynamicCohort = resPD.dynamicCohort;
        _dynamicCohortAverage = resPD.average;
        _dynamicCohortStdDev = resPD.stdDev;
        _staticCohort = resPD.staticCohort;
        _staticCohortAverage = resPD.averageStatic;
        _staticCohortStdDev = resPD.stdDevStatic;
        _rating_data = resPD.cohortMap;
        _rating_mdr_dynamic_data = resPD.mdrFinal;
        _rating_mdr_static_data = resPD.mdrStaticFinal;
        _dynamic_wmdr_cdr_data = resPD.wmdrCdrFinal;
        _static_wmdr_cdr_data = resPD.wmdrCdrStaticFinal;

        _dynamicCohort = {
          ..._dynamicCohort,
          average: _dynamicCohortAverage,
          stdDev: _dynamicCohortStdDev,
        };

        _staticCohort = {
          ..._staticCohort,
          averageStatic: _staticCohortAverage,
          stdDevStatic: _staticCohortStdDev,
        };

        let cmpInfo = this.getComputeNameByKey(resPD.key);

        let paramsCmp = this.state.savedParams[resPD.key];
        let selectedMemberInstitute = this.getMemberListFromIds(
          paramsCmp.memberInstititute
        );
        let selectedCategory = this.getCategoryListFromIds(paramsCmp.category);
        let selectedCurrencyType = this.getCurrencyTypeListFromIds(
          paramsCmp.currencyType
        );
        let selectedLoanType = this.getLoanTypeListFromIds(paramsCmp.loanType);
        let selectedResolutionType = this.getResolutionTypeListFromIds(paramsCmp.resolutionType);
        let selectedSectorClassification = this.getSectorClassificationListFromIds(paramsCmp.sectorClassification);
        let selectedLoanAmountType = this.getLoanAmountTypeListFromIds(
          paramsCmp.originalAmountLoan
        );
        let regionListSelected = this.getRegionListFromIds(paramsCmp.region);
        let regionContractListSelected = this.getRegionListFromIds(paramsCmp.regionContract);
        let sectorListSelected = this.getSectorListFromIds(paramsCmp.sector);
        let industryListSelected = this.getIndustryListFroIds(
          paramsCmp.industry
        );

        let subIndustryListSelected = this.getSubIndustryListFromIds(
          paramsCmp.subIndustry
        );

        let subSubIndustryListSelected = this.getSubSubIndustryListFromIds(
          paramsCmp.subSubIndustry
        );
        let pdResponse = {
          dynamicCohort: _dynamicCohort,
          staticCohort: _staticCohort,
          rating_data: _rating_data,
          rating_mdr_dynamic_data: _rating_mdr_dynamic_data,
          rating_mdr_static_data: _rating_mdr_static_data,
          dynamic_wmdr_cdr_data: _dynamic_wmdr_cdr_data,
          static_wmdr_cdr_data: _static_wmdr_cdr_data,
          dateTime: resPD.dateTime,
          name: cmpInfo.name,
          type: cmpInfo.type,
          accr: cmpInfo.accr,
          computeKey: resPD.key,
          params: {
            members: selectedMemberInstitute,
            mode: paramsCmp.isWorkEnvironment
              ? "Work Environment"
              : "Annual Report Environment",
            startYear: paramsCmp.active_year_from,
            endYear: paramsCmp.active_year_to,
            category: selectedCategory,
            cdc_cprt: paramsCmp.crossDefaultClause_cprt,
            subSubIndustryGrp: subSubIndustryListSelected,
            subIndustryGrp: subIndustryListSelected,
            industryGrp: industryListSelected,
            sector: sectorListSelected,
            region: regionListSelected,
            regionContract: regionContractListSelected,
            country: paramsCmp.country,
            countryContract: paramsCmp.countryContract,
            contagion: paramsCmp.include_contagion ? "Included" : "Excluded",
            cdc_crt: paramsCmp.crossDefaultClause_cntrct,
            currency_type: selectedCurrencyType,
            loan_type: selectedLoanType,
            resolution_type: selectedResolutionType,
            sector_classification: selectedSectorClassification,
            loan_amount: selectedLoanAmountType,
            default_period:
              paramsCmp.defaultPeriod180 === 1 ? "180 days" : "90 days",
            isNewPeriod:
              paramsCmp.dataset_year === new Date().getFullYear() - 1
                ? "New data"
                : "Old data",
            calType: PD,
            dataset_year: paramsCmp.dataset_year,
            membersId: paramsCmp.memberInstititute,
            categoryId: paramsCmp.category,
            cdc_cprtId: paramsCmp.crossDefaultClause_cprt,
            subSubIndustryGrpId: paramsCmp.subSubIndustry,
            subIndustryGrpId: paramsCmp.subIndustry,
            industryGrpId: paramsCmp.industry,
            sectorId: paramsCmp.sector,
            regionId: paramsCmp.region,
            regionIdContract: paramsCmp.regionContract,
            contagionId: paramsCmp.include_contagion,
            cdc_crtId: paramsCmp.crossDefaultClause_cntrct,
            currency_typeId: paramsCmp.currencyType,
            loan_typeId: paramsCmp.loanType,
            resolution_typeId: paramsCmp.resolutionType,
            sector_classificationId: paramsCmp.sectorClassification,
            loan_amountId: paramsCmp.originalAmountLoan,
            default_periodId: paramsCmp.defaultPeriod180,
            sovDefaultDefinition: paramsCmp.sovDefaultDefinition,
            excludeDevelopedCountriesCounterpart: paramsCmp.excludeDevelopedCountriesCounterpart ? "Included" : "Excluded",
            excludeDevelopedCountriesContract: paramsCmp.excludeDevelopedCountriesContract ? "Included" : "Excluded",
            sovFlag: paramsCmp.sovFlag,
            includeOutlook: paramsCmp.includeOutlook,
          },
          noData: !resPD.resultDisplay,
          displayRating: resPD.sovereginRating,
        };
        this.props.computeCallback(cmpInfo.type, pdResponse);
      }
    });
  }

  
  getSavedComputeMMResult(computeIds) {
    const config = {
      headers: {
        ...authHeader(),
      },
    };

    let promises = [];
    let computeRes = [];
    for (var i = 0; i < computeIds.length; i++) {
      if (computeIds[i].toLowerCase().indexOf("computemm") !== -1) {
        this.props.beingExecutedCallback(
          MM,
          this.state.savedParams[computeIds[i]].dateTime,
          null
        );
        promises.push(
          instance
            .post("/compute/marginalMatrix/true/" + computeIds[i], {}, config)
            .then((response) => {
              computeRes.push(response.data);
            })
            .catch((error) => {
              this.props.deleteBeingExecutedCallbackIfError(
                MM,
                this.state.savedParams[computeIds[i]].dateTime
              );
              if (error.response) {
                if (error.response.status === 401) {
                  sessionStorage.clear();
                  window.location.replace("/");
                } else {
                  showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
                }
              } else {
                showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
              }
            })
        );
      }
    }

    Promise.all(promises).then(() => {
      
      computeRes.sort(function(a, b) {
        let dtArr1 = a["dateTime"].split(" ");
        let date1 = dtArr1[0];
        let time1 = dtArr1[1];
        let dateArr1 = date1.split("-");
        let year1 = dateArr1[0];
        let month1 = dateArr1[1];
        let day1 = dateArr1[2];

        let timeArr1 = time1.split(":");
        let hour1 = timeArr1[0];
        let min1 = timeArr1[1];
        let sec1 = timeArr1[2];

        let dateObjA = new Date(year1, month1, day1, hour1, min1, sec1);

        let dtArr2 = b["dateTime"].split(" ");
        let date2 = dtArr2[0];
        let time2 = dtArr2[1];
        let dateArr2 = date2.split("-");
        let year2 = dateArr2[0];
        let month2 = dateArr2[1];
        let day2 = dateArr2[2];

        let timeArr2 = time2.split(":");
        let hour2 = timeArr2[0];
        let min2 = timeArr2[1];
        let sec2 = timeArr2[2];

        let dateObjB = new Date(year2, month2, day2, hour2, min2, sec2);

        return dateObjA - dateObjB;
      });
      for (var i = 0; i < computeRes.length; i++) {
        let resMM = computeRes[i];

        let cmpInfo = this.getComputeNameByKey(resMM.key);

        let paramsCmp = this.state.savedParams[resMM.key];
        let selectedMemberInstitute = this.getMemberListFromIds(
          paramsCmp.memberInstititute
        );
        let selectedCategory = this.getCategoryListFromIds(paramsCmp.category);

        let selectedCurrencyType = this.getCurrencyTypeListFromIds(
          paramsCmp.currencyType
        );
        let selectedLoanType = this.getLoanTypeListFromIds(paramsCmp.loanType);
        let selectedResolutionType = this.getResolutionTypeListFromIds(paramsCmp.resolutionType);
        let selectedSectorClassification = this.getSectorClassificationListFromIds(paramsCmp.sectorClassification);
        let selectedLoanAmountType = this.getLoanAmountTypeListFromIds(
          paramsCmp.originalAmountLoan
        );
        let regionListSelected = this.getRegionListFromIds(paramsCmp.region);
        let regionContractListSelected = this.getRegionListFromIds(paramsCmp.regionContract);
        let sectorListSelected = this.getSectorListFromIds(paramsCmp.sector);
        let industryListSelected = this.getIndustryListFroIds(
          paramsCmp.industry
        );
        let subIndustryListSelected = this.getSubIndustryListFromIds(
          paramsCmp.subIndustry
        );
        let subSubIndustryListSelected = this.getSubSubIndustryListFromIds(
          paramsCmp.subSubIndustry
        );
        let mmResponse = {
          mmMaps: resMM,
          dateTime: resMM.dateTime,
          name: cmpInfo.name,
          type: cmpInfo.type,
          accr: cmpInfo.accr,
          computeKey: resMM.key,
          params: {
            members: selectedMemberInstitute,
            mode: paramsCmp.isWorkEnvironment
              ? "Work Environment"
              : "Annual Report Environment",
            startYear: paramsCmp.active_year_from,
            endYear: paramsCmp.active_year_to,
            period: paramsCmp.period,
            category: selectedCategory,
            cdc_cprt: paramsCmp.crossDefaultClause_cprt,
            subSubIndustryGrp:subSubIndustryListSelected,
            subIndustryGrp: subIndustryListSelected,
            industryGrp: industryListSelected,
            sector: sectorListSelected,
            region: regionListSelected,
            regionContract: regionContractListSelected,
            country: paramsCmp.country,
            countryContract: paramsCmp.countryContract,
            contagion: paramsCmp.include_contagion ? "Included" : "Excluded",
            cdc_crt: paramsCmp.crossDefaultClause_cntrct,
            currency_type: selectedCurrencyType,
            loan_type: selectedLoanType,
            resolution_type: selectedResolutionType,
            sector_classification: selectedSectorClassification,
            loan_amount: selectedLoanAmountType,
            default_period:
              paramsCmp.defaultPeriod180 === 1 ? "180 days" : "90 days",
            isNewPeriod:
              paramsCmp.dataset_year === new Date().getFullYear() - 1
                ? "New data"
                : "Old data",
            calType: MM,
            dataset_year: paramsCmp.dataset_year,
            membersId: paramsCmp.memberInstititute,
            categoryId: paramsCmp.category,
            cdc_cprtId: paramsCmp.crossDefaultClause_cprt,
            subSubIndustryGrpId: paramsCmp.subSubIndustry,
            subIndustryGrpId: paramsCmp.subIndustry,
            industryGrpId: paramsCmp.industry,
            sectorId: paramsCmp.sector,
            regionId: paramsCmp.region,
            regionIdContract: paramsCmp.regionContract,
            contagionId: paramsCmp.include_contagion,
            cdc_crtId: paramsCmp.crossDefaultClause_cntrct,
            currency_typeId: paramsCmp.currencyType,
            loan_typeId: paramsCmp.loanType,
            resolution_typeId: paramsCmp.resolutionType,
            sector_classificationId: paramsCmp.sectorClassification,
            loan_amountId: paramsCmp.originalAmountLoan,
            default_periodId: paramsCmp.defaultPeriod180,
            sovDefaultDefinition: paramsCmp.sovDefaultDefinition,
            excludeDevelopedCountriesCounterpart: paramsCmp.excludeDevelopedCountriesCounterpart ? "Included" : "Excluded",
            excludeDevelopedCountriesContract: paramsCmp.excludeDevelopedCountriesContract ? "Included" : "Excluded",
            sovFlag: paramsCmp.sovFlag,
            includeOutlook: paramsCmp.includeOutlook,
          },
        };
        this.props.computeCallback(cmpInfo.type, mmResponse);
      }
    });
  }

  
  getSavedComputeRRResult(computeIds) {
    const config = {
      headers: {
        ...authHeader(),
      },
    };

    let promises = [];
    let computeRes = [];
    for (var i = 0; i < computeIds.length; i++) {
      if (computeIds[i].toLowerCase().indexOf("computerr") !== -1) {
        this.props.beingExecutedCallback(
          RR,
          this.state.savedParams[computeIds[i]].dateTime,
          null
        );
        promises.push(
          instance
            .post("/compute/recoveryRate/true/" + computeIds[i], {}, config)
            .then((response) => {
              computeRes.push(response.data);
            })
            .catch((error) => {
              this.props.deleteBeingExecutedCallbackIfError(
                RR,
                this.state.savedParams[computeIds[i]].dateTime
              );
              if (error.response) {
                if (error.response.status === 401) {
                  sessionStorage.clear();
                  window.location.replace("/");
                } else {
                  showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
                }
              } else {
                showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
              }
            })
        );
      }
    }

    Promise.all(promises).then(() => {
      
      computeRes.sort(function(a, b) {
        let dtArr1 = a["dateTime"].split(" ");
        let date1 = dtArr1[0];
        let time1 = dtArr1[1];
        let dateArr1 = date1.split("-");
        let year1 = dateArr1[0];
        let month1 = dateArr1[1];
        let day1 = dateArr1[2];

        let timeArr1 = time1.split(":");
        let hour1 = timeArr1[0];
        let min1 = timeArr1[1];
        let sec1 = timeArr1[2];

        let dateObjA = new Date(year1, month1, day1, hour1, min1, sec1);

        let dtArr2 = b["dateTime"].split(" ");
        let date2 = dtArr2[0];
        let time2 = dtArr2[1];
        let dateArr2 = date2.split("-");
        let year2 = dateArr2[0];
        let month2 = dateArr2[1];
        let day2 = dateArr2[2];

        let timeArr2 = time2.split(":");
        let hour2 = timeArr2[0];
        let min2 = timeArr2[1];
        let sec2 = timeArr2[2];

        let dateObjB = new Date(year2, month2, day2, hour2, min2, sec2);

        return dateObjA - dateObjB;
      });
      if (computeRes.length === 0) {
        this.setState({ fetchedAllSavedRequest: true });
      }
      for (var i = 0; i < computeRes.length; i++) {
        let resRR = computeRes[i];

        let cmpInfo = this.getComputeNameByKey(resRR.key);

        let paramsCmp = this.state.savedParams[resRR.key];
        let selectedMemberInstitute = this.getMemberListFromIds(
          paramsCmp.memberInstititute
        );
        let selectedCategory = this.getCategoryListFromIds(paramsCmp.category);

        let selectedCurrencyType = this.getCurrencyTypeListFromIds(
          paramsCmp.currencyType
        );
        let selectedLoanType = this.getLoanTypeListFromIds(paramsCmp.loanType);
        let selectedResolutionType = this.getResolutionTypeListFromIds(paramsCmp.resolutionType);
        let selectedSectorClassification = this.getSectorClassificationListFromIds(paramsCmp.sectorClassification);
        let selectedLoanAmountType = this.getLoanAmountTypeListFromIds(
          paramsCmp.originalAmountLoan
        );

        let regionListSelected = this.getRegionListFromIds(paramsCmp.region);
        let regionContractListSelected = this.getRegionListFromIds(paramsCmp.regionContract);
        let sectorListSelected = this.getSectorListFromIds(paramsCmp.sector);
        let industryListSelected = this.getIndustryListFroIds(
          paramsCmp.industry
        );
        let subIndustryListSelected = this.getSubIndustryListFromIds(
          paramsCmp.subIndustry
        );
        let subSubIndustryListSelected = this.getSubSubIndustryListFromIds(
          paramsCmp.subSubIndustry
        );
        let rrResponse = {
          rrMaps: resRR,
          dateTime: resRR.dateTime,
          name: cmpInfo.name,
          type: cmpInfo.type,
          accr: cmpInfo.accr,
          computeKey: resRR.key,
          params: {
            members: selectedMemberInstitute,
            mode: paramsCmp.isWorkEnvironment
              ? "Work Environment"
              : "Annual Report Environment",
            startYear: paramsCmp.active_year_from,
            endYear: paramsCmp.active_year_to,
            nodaysbeforedefault: paramsCmp.nodaysbeforedefault,
            thresholdUnresolvedContracts: paramsCmp.thresholdUnresolvedContracts,
            includeUnresolvedContracts: paramsCmp.includeUnresolvedContracts,
            category: selectedCategory,
            cdc_cprt: paramsCmp.crossDefaultClause_cprt,
            subSubIndustryGrp:subSubIndustryListSelected,
            subIndustryGrp: subIndustryListSelected,
            industryGrp: industryListSelected,
            sector: sectorListSelected,
            region: regionListSelected,
            regionContract: regionContractListSelected,
            country: paramsCmp.country,
            countryContract: paramsCmp.countryContract,
            contagion: paramsCmp.include_contagion ? "Included" : "Excluded",
            cdc_crt: paramsCmp.crossDefaultClause_cntrct,
            currency_type: selectedCurrencyType,
            loan_type: selectedLoanType,
            resolution_type: selectedResolutionType,
            sector_classification: selectedSectorClassification,
            loan_amount: selectedLoanAmountType,
            default_period:
              paramsCmp.defaultPeriod180 === 1 ? "180 days" : "90 days",
            isNewPeriod:
              paramsCmp.dataset_year === new Date().getFullYear() - 1
                ? "New data"
                : "Old data",
            calType: RR,
            dataset_year: paramsCmp.dataset_year,
            membersId: paramsCmp.memberInstititute,
            categoryId: paramsCmp.category,
            cdc_cprtId: paramsCmp.crossDefaultClause_cprt,
            subSubIndustryGrpId: paramsCmp.subSubIndustry,
            subIndustryGrpId: paramsCmp.subIndustry,
            industryGrpId: paramsCmp.industry,
            sectorId: paramsCmp.sector,
            regionId: paramsCmp.region,
            regionIdContract: paramsCmp.regionContract,
            contagionId: paramsCmp.include_contagion,
            cdc_crtId: paramsCmp.crossDefaultClause_cntrct,
            currency_typeId: paramsCmp.currencyType,
            loan_typeId: paramsCmp.loanType,
            resolution_typeId: paramsCmp.resolutionType,
            sector_classificationId: paramsCmp.sectorClassification,
            loan_amountId: paramsCmp.originalAmountLoan,
            default_periodId: paramsCmp.defaultPeriod180,
            sovDefaultDefinition: paramsCmp.sovDefaultDefinition,
            excludeDevelopedCountriesCounterpart: paramsCmp.excludeDevelopedCountriesCounterpart ? "Included" : "Excluded",
            excludeDevelopedCountriesContract: paramsCmp.excludeDevelopedCountriesContract ? "Included" : "Excluded",
            sovFlag: paramsCmp.sovFlag,
          },
        };
        this.props.computeCallback(cmpInfo.type, rrResponse);
        this.setState({ fetchedAllSavedRequest: true });
      }
    });
  }

  
  getSavedComputeFCResult(computeIds) {
    try {
      this.setState({ isPaused: true });
      const config = {
        headers: {
          ...authHeader(),
        },
      };
      let promises = [];
      let computeRes = [];
      for (var i = 0; i < computeIds.length; i++) {
        const computeIdtoCalc = computeIds[i];
        const indexToFetch = this.state.computedResultKeys[computeIdtoCalc]
          .length;
        if (indexToFetch < 104) {
          promises.push(
            instance
              .post(
                "/compute/fullCompute/true/" +
                  computeIdtoCalc +
                  "/" +
                  indexToFetch,
                {},
                config
              )
              .then((response) => {
                computeRes.push({
                  id: computeIdtoCalc,
                  data: response.data.length === 0 ? [] : response.data,
                });
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/");
                  } else {
                    showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
                  }
                } else {
                  showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
                }
              })
          );
        } else {
          let dateTime = null;
          let savedFCIds = sessionStorage.getItem("fullComputeIds");
          if (savedFCIds != null) {
            JSON.parse(savedFCIds).forEach((key) => {
              if (key.id === computeIdtoCalc) {
                dateTime = key.dateTime;
              }
            });
          }
          this.props.deleteBeingExecutedCallbackIfError(FC, dateTime);
        }
      }

      Promise.all(promises).then(() => {
        computeRes.forEach((computeItemsParent) => {
          computeItemsParent.data.forEach((computeItems) => {
            switch (computeItems.operationType) {
              case "MM":
                if (
                  this.state.computedResultKeys[computeItemsParent.id].indexOf(
                    computeItems.mmResponse.key
                  ) === -1
                ) {
                  let resMM = computeItems.mmResponse;
                  let cmpInfo = this.getComputeNameByKey(resMM.key);

                  let paramsCmp = computeItems.params;
                  let selectedMemberInstitute = this.getMemberListFromIds(
                    paramsCmp.memberInstititute.map((item) => parseInt(item))
                  );
                  let selectedCategory = this.getCategoryListFromIds(
                    paramsCmp.category
                  );

                  let selectedCurrencyType = this.getCurrencyTypeListFromIds(
                    paramsCmp.currencyType
                  );
                  let selectedLoanType = this.getLoanTypeListFromIds(
                    paramsCmp.loanType
                  );
                  let selectedResolutionType = this.getResolutionTypeListFromIds(
                    paramsCmp.resolutionType
                  );
                  let selectedSectorClassification = this.getSectorClassificationListFromIds(
                    paramsCmp.sectorClassification
                  );
                  let selectedLoanAmountType = this.getLoanAmountTypeListFromIds(
                    paramsCmp.originalAmountLoan
                  );
                  let regionListSelected = this.getRegionListFromIds(
                    paramsCmp.region
                  );
                  let regionContractListSelected = this.getRegionListFromIds(
                    paramsCmp.regionContract
                  );
                  let sectorListSelected = this.getSectorListFromIds(
                    paramsCmp.sector
                  );
                  let industryListSelected = this.getIndustryListFroIds(
                    paramsCmp.industry
                  );
                  let subIndustryListSelected = this.getSubIndustryListFromIds(
                    paramsCmp.subIndustry
                  );
                  let subSubIndustryListSelected = this.getSubSubIndustryListFromIds(
                    paramsCmp.subSubIndustry
                  );

                  let mmResponse = {
                    queryOp: "fc",
                    queryOpKey: computeItemsParent.id,
                    mmMaps: resMM,
                    dateTime: moment(computeItems.time).format(
                      "YYYY-D-M H:m:s"
                    ),
                    name: cmpInfo.name,
                    type: cmpInfo.type,
                    accr: cmpInfo.accr,
                    computeKey: resMM.key,
                    params: {
                      members: selectedMemberInstitute,
                      mode: paramsCmp.isWorkEnvironment
                        ? "Work Environment"
                        : "Annual Report Environment",
                      startYear: paramsCmp.active_year_from,
                      endYear: paramsCmp.active_year_to,
                      period: paramsCmp.period,
                      category: selectedCategory,
                      cdc_cprt: paramsCmp.crossDefaultClause_cprt,
                      subSubIndustryGrp:subSubIndustryListSelected,
                      subIndustryGrp: subIndustryListSelected,
                      industryGrp: industryListSelected,
                      sector: sectorListSelected,
                      region: regionListSelected,
                      regionContract: regionContractListSelected,
                      country: paramsCmp.country,
                      countryContract: paramsCmp.countryContract,
                      contagion: paramsCmp.include_contagion
                        ? "Included"
                        : "Excluded",
                      cdc_crt: paramsCmp.crossDefaultClause_cntrct,
                      currency_type: selectedCurrencyType,
                      loan_type: selectedLoanType,
                      resolution_type: selectedResolutionType,
                      sector_classification: selectedSectorClassification,
                      loan_amount: selectedLoanAmountType,
                      default_period:
                        paramsCmp.defaultPeriod180 === 1
                          ? "180 days"
                          : "90 days",
                      isNewPeriod:
                        paramsCmp.dataset_year === new Date().getFullYear() - 1
                          ? "New data"
                          : "Old data",
                      calType: MM,
                      dataset_year: paramsCmp.dataset_year,
                      membersId: paramsCmp.memberInstititute,
                      categoryId: paramsCmp.category,
                      cdc_cprtId: paramsCmp.crossDefaultClause_cprt,
                      subSubIndustryGrpId: paramsCmp.subSubIndustry,
                      subIndustryGrpId: paramsCmp.subIndustry,
                      industryGrpId: paramsCmp.industry,
                      sectorId: paramsCmp.sector,
                      regionId: paramsCmp.region,
                      regionIdContract: paramsCmp.regionContract,
                      contagionId: paramsCmp.include_contagion,
                      cdc_crtId: paramsCmp.crossDefaultClause_cntrct,
                      currency_typeId: paramsCmp.currencyType,
                      loan_typeId: paramsCmp.loanType,
                      resolution_typeId: paramsCmp.resolutionType,
                      sector_classificationId: paramsCmp.sectorClassification,
                      loan_amountId: paramsCmp.originalAmountLoan,
                      default_periodId: paramsCmp.defaultPeriod180,
                      sovDefaultDefinition: paramsCmp.sovDefaultDefinition,
					  excludeDevelopedCountriesCounterpart: paramsCmp.excludeDevelopedCountriesCounterpart ? "Included" : "Excluded",
					  excludeDevelopedCountriesContract: paramsCmp.excludeDevelopedCountriesContract ? "Included" : "Excluded",
                      sovFlag: paramsCmp.sovFlag,
                      includeOutlook: paramsCmp.includeOutlook,
                    },
                  };

                  let computedKeys = this.state.computedResultKeys;
                  computedKeys[computeItemsParent.id].push(resMM.key);
                  this.setState({
                    computedResultKeys: computedKeys,
                  });
                  this.props.computeCallback(cmpInfo.type, mmResponse);
                }
                break;

              case "PD":
                if (
                  this.state.computedResultKeys[computeItemsParent.id].indexOf(
                    computeItems.pdResponse.key
                  ) === -1
                ) {
                  let _rating_data = null;
                  let _rating_mdr_dynamic_data = null;
                  let _rating_mdr_static_data = null;
                  let _dynamic_wmdr_cdr_data = null;
                  let _static_wmdr_cdr_data = null;
                  let _dynamicCohort = null;
                  let _dynamicCohortAverage = null;
                  let _dynamicCohortStdDev = null;
                  let _staticCohort = null;
                  let _staticCohortAverage = null;
                  let _staticCohortStdDev = null;
                  let resPD = computeItems.pdResponse;

                  _dynamicCohort = resPD.dynamicCohort;
                  _dynamicCohortAverage = resPD.average;
                  _dynamicCohortStdDev = resPD.stdDev;
                  _staticCohort = resPD.staticCohort;
                  _staticCohortAverage = resPD.averageStatic;
                  _staticCohortStdDev = resPD.stdDevStatic;
                  _rating_data = resPD.cohortMap;
                  _rating_mdr_dynamic_data = resPD.mdrFinal;
                  _rating_mdr_static_data = resPD.mdrStaticFinal;
                  _dynamic_wmdr_cdr_data = resPD.wmdrCdrFinal;
                  _static_wmdr_cdr_data = resPD.wmdrCdrStaticFinal;

                  _dynamicCohort = {
                    ..._dynamicCohort,
                    average: _dynamicCohortAverage,
                    stdDev: _dynamicCohortStdDev,
                  };

                  _staticCohort = {
                    ..._staticCohort,
                    averageStatic: _staticCohortAverage,
                    stdDevStatic: _staticCohortStdDev,
                  };

                  let cmpInfo = this.getComputeNameByKey(resPD.key);

                  let paramsCmp = computeItems.params;
                  let selectedMemberInstitute = this.getMemberListFromIds(
                    paramsCmp.memberInstititute.map((item) => parseInt(item))
                  );
                  let selectedCategory = this.getCategoryListFromIds(
                    paramsCmp.category
                  );
                  let selectedCurrencyType = this.getCurrencyTypeListFromIds(
                    paramsCmp.currencyType
                  );
                  let selectedLoanType = this.getLoanTypeListFromIds(
                    paramsCmp.loanType
                  );
                  let selectedResolutionType = this.getResolutionTypeListFromIds(
                    paramsCmp.resolutionType
                  );
                  let selectedSectorClassification = this.getSectorClassificationListFromIds(
                    paramsCmp.sectorClassification
                  );
                  let selectedLoanAmountType = this.getLoanAmountTypeListFromIds(
                    paramsCmp.originalAmountLoan
                  );
                  let regionListSelected = this.getRegionListFromIds(
                    paramsCmp.region
                  );
                  let regionContractListSelected = this.getRegionListFromIds(
                    paramsCmp.regionContract
                  );
                  let sectorListSelected = this.getSectorListFromIds(
                    paramsCmp.sector
                  );
                  let industryListSelected = this.getIndustryListFroIds(
                    paramsCmp.industry
                  );
                  let subIndustryListSelected = this.getSubIndustryListFromIds(
                    paramsCmp.subIndustry
                  );

                  let subSubIndustryListSelected = this.getSubSubIndustryListFromIds(
                    paramsCmp.subSubIndustry
                  );

                  let pdResponse = {
                    queryOp: "fc",
                    queryOpKey: computeItemsParent.id,
                    dynamicCohort: _dynamicCohort,
                    staticCohort: _staticCohort,
                    rating_data: _rating_data,
                    rating_mdr_dynamic_data: _rating_mdr_dynamic_data,
                    rating_mdr_static_data: _rating_mdr_static_data,
                    dynamic_wmdr_cdr_data: _dynamic_wmdr_cdr_data,
                    static_wmdr_cdr_data: _static_wmdr_cdr_data,
                    dateTime: moment(computeItems.time).format(
                      "YYYY-D-M H:m:s"
                    ),
                    name: cmpInfo.name,
                    type: cmpInfo.type,
                    accr: cmpInfo.accr,
                    computeKey: resPD.key,
                    params: {
                      members: selectedMemberInstitute,
                      mode: paramsCmp.isWorkEnvironment
                        ? "Work Environment"
                        : "Annual Report Environment",
                      startYear: paramsCmp.active_year_from,
                      endYear: paramsCmp.active_year_to,
                      category: selectedCategory,
                      cdc_cprt: paramsCmp.crossDefaultClause_cprt,
                      subSubIndustryGrp: subSubIndustryListSelected,
                      subIndustryGrp: subIndustryListSelected,
                      industryGrp: industryListSelected,
                      sector: sectorListSelected,
                      region: regionListSelected,
                      regionContract: regionContractListSelected,
                      country: paramsCmp.country,
                      countryContract: paramsCmp.countryContract,
                      contagion: paramsCmp.include_contagion
                        ? "Included"
                        : "Excluded",
                      cdc_crt: paramsCmp.crossDefaultClause_cntrct,
                      currency_type: selectedCurrencyType,
                      loan_type: selectedLoanType,
                      resolution_type: selectedResolutionType,
                      sector_classification: selectedSectorClassification,
                      loan_amount: selectedLoanAmountType,
                      default_period:
                        paramsCmp.defaultPeriod180 === 1
                          ? "180 days"
                          : "90 days",
                      isNewPeriod:
                        paramsCmp.dataset_year === new Date().getFullYear() - 1
                          ? "New data"
                          : "Old data",
                      calType: PD,
                      dataset_year: paramsCmp.dataset_year,
                      membersId: paramsCmp.memberInstititute,
                      categoryId: paramsCmp.category,
                      cdc_cprtId: paramsCmp.crossDefaultClause_cprt,
                      subSubIndustryGrpId: paramsCmp.subSubIndustry,
                      subIndustryGrpId: paramsCmp.subIndustry,
                      industryGrpId: paramsCmp.industry,
                      sectorId: paramsCmp.sector,
                      regionId: paramsCmp.region,
                      regionIdContract: paramsCmp.regionContract,
                      contagionId: paramsCmp.include_contagion,
                      cdc_crtId: paramsCmp.crossDefaultClause_cntrct,
                      currency_typeId: paramsCmp.currencyType,
                      loan_typeId: paramsCmp.loanType,
                      resolution_typeId: paramsCmp.resolutionType,
                      sector_classificationId: paramsCmp.sectorClassification,
                      loan_amountId: paramsCmp.originalAmountLoan,
                      default_periodId: paramsCmp.defaultPeriod180,
                      sovDefaultDefinition: paramsCmp.sovDefaultDefinition,
					  excludeDevelopedCountriesCounterpart: paramsCmp.excludeDevelopedCountriesCounterpart ? "Included" : "Excluded",
					  excludeDevelopedCountriesContract: paramsCmp.excludeDevelopedCountriesContract ? "Included" : "Excluded",
                      sovFlag: paramsCmp.sovFlag,
                      includeOutlook: paramsCmp.includeOutlook,
                    },
                    noData: !resPD.resultDisplay,
                    displayRating: resPD.sovereginRating,
                  };
                  let computedKeys = this.state.computedResultKeys;
                  computedKeys[computeItemsParent.id].push(resPD.key);
                  this.setState({
                    computedResultKeys: computedKeys,
                  });
                  this.props.computeCallback(cmpInfo.type, pdResponse);
                }
                break;
              case "RR":
                if (
                  this.state.computedResultKeys[computeItemsParent.id].indexOf(
                    computeItems.rrResponse.key
                  ) === -1
                ) {
                  let resRR = computeItems.rrResponse;

                  let cmpInfo = this.getComputeNameByKey(resRR.key);

                  let paramsCmp = computeItems.params;
                  let selectedMemberInstitute = this.getMemberListFromIds(
                    paramsCmp.memberInstititute.map((item) => parseInt(item))
                  );
                  let selectedCategory = this.getCategoryListFromIds(
                    paramsCmp.category
                  );

                  let selectedCurrencyType = this.getCurrencyTypeListFromIds(
                    paramsCmp.currencyType
                  );
                  let selectedLoanType = this.getLoanTypeListFromIds(
                    paramsCmp.loanType
                  );
                  let selectedResolutionType = this.getResolutionTypeListFromIds(
                    paramsCmp.resolutionType
                  );
                  let selectedSectorClassification = this.getSectorClassificationListFromIds(
                    paramsCmp.sectorClassification
                  );
                  let selectedLoanAmountType = this.getLoanAmountTypeListFromIds(
                    paramsCmp.originalAmountLoan
                  );

                  let regionListSelected = this.getRegionListFromIds(
                    paramsCmp.region
                  );
                  let regionContractListSelected = this.getRegionListFromIds(
                    paramsCmp.regionContract
                  );
                  let sectorListSelected = this.getSectorListFromIds(
                    paramsCmp.sector
                  );
                  let industryListSelected = this.getIndustryListFroIds(
                    paramsCmp.industry
                  );
                  let subIndustryListSelected = this.getSubIndustryListFromIds(
                    paramsCmp.subIndustry
                  );

                  let subSubIndustryListSelected = this.getSubSubIndustryListFromIds(
                    paramsCmp.subSubIndustry
                  );

                  let rrResponse = {
                    queryOp: "fc",
                    queryOpKey: computeItemsParent.id,
                    rrMaps: resRR,
                    dateTime: moment(computeItems.time).format(
                      "YYYY-D-M H:m:s"
                    ),
                    name: cmpInfo.name,
                    type: cmpInfo.type,
                    accr: cmpInfo.accr,
                    computeKey: resRR.key,
                    params: {
                      members: selectedMemberInstitute,
                      mode: paramsCmp.isWorkEnvironment
                        ? "Work Environment"
                        : "Annual Report Environment",
                      startYear: paramsCmp.active_year_from,
                      endYear: paramsCmp.active_year_to,
                      nodaysbeforedefault: paramsCmp.nodaysbeforedefault,
                      thresholdUnresolvedContracts: paramsCmp.thresholdUnresolvedContracts,
                      includeUnresolvedContracts: paramsCmp.includeUnresolvedContracts,
                      category: selectedCategory,
                      cdc_cprt: paramsCmp.crossDefaultClause_cprt,
                      subSubIndustryGrp: subSubIndustryListSelected,
                      subIndustryGrp: subIndustryListSelected,
                      industryGrp: industryListSelected,
                      sector: sectorListSelected,
                      region: regionListSelected,
                      regionContract: regionContractListSelected,
                      country: paramsCmp.country,
                      countryContract: paramsCmp.countryContract,
                      contagion: paramsCmp.include_contagion
                        ? "Included"
                        : "Excluded",
                      cdc_crt: paramsCmp.crossDefaultClause_cntrct,
                      currency_type: selectedCurrencyType,
                      loan_type: selectedLoanType,
                      resolution_type: selectedResolutionType,
                      sector_classification: selectedSectorClassification,
                      loan_amount: selectedLoanAmountType,
                      default_period:
                        paramsCmp.defaultPeriod180 === 1
                          ? "180 days"
                          : "90 days",
                      isNewPeriod:
                        paramsCmp.dataset_year === new Date().getFullYear() - 1
                          ? "New data"
                          : "Old data",
                      calType: RR,
                      dataset_year: paramsCmp.dataset_year,
                      membersId: paramsCmp.memberInstititute,
                      categoryId: paramsCmp.category,
                      cdc_cprtId: paramsCmp.crossDefaultClause_cprt,
                      subSubIndustryGrpId: paramsCmp.subSubIndustry,
                      subIndustryGrpId: paramsCmp.subIndustry,
                      industryGrpId: paramsCmp.industry,
                      sectorId: paramsCmp.sector,
                      regionId: paramsCmp.region,
                      regionIdContract: paramsCmp.regionContract,
                      contagionId: paramsCmp.include_contagion,
                      cdc_crtId: paramsCmp.crossDefaultClause_cntrct,
                      currency_typeId: paramsCmp.currencyType,
                      loan_typeId: paramsCmp.loanType,
                      resolution_typeId: paramsCmp.resolutionType,
                      sector_classificationId: paramsCmp.sectorClassification,
                      loan_amountId: paramsCmp.originalAmountLoan,
                      default_periodId: paramsCmp.defaultPeriod180,
                      sovDefaultDefinition: paramsCmp.sovDefaultDefinition,
					  excludeDevelopedCountriesCounterpart: paramsCmp.excludeDevelopedCountriesCounterpart ? "Included" : "Excluded",
					  excludeDevelopedCountriesContract: paramsCmp.excludeDevelopedCountriesContract ? "Included" : "Excluded",
                      sovFlag: paramsCmp.sovFlag,
                    },
                  };
                  let computedKeys = this.state.computedResultKeys;
                  computedKeys[computeItemsParent.id].push(resRR.key);
                  this.setState({
                    computedResultKeys: computedKeys,
                  });
                  this.props.computeCallback(cmpInfo.type, rrResponse);
                }
                break;
              default:
                break;
            }
          });
        });
        this.setState({ isPaused: false });
      });
    } catch (error) {
      console.log(error);
    }
  }

  
  validateYears(startYear, endYear) {
    let fieldValidationErrors = this.state.formErrors;
    let startYearValid = this.state.startYearValid;
    let endYearValid = this.state.endYearValid;
    let periodValid = this.state.periodValid;
    let nodaysbeforedefaultValid = this.state.nodaysbeforedefaultValid;
    let thresholdUnresolvedContractsValid = this.state.thresholdUnresolvedContractsValid;
    const periodValue = this.state.period;
    const nodaysbeforedefaultValue = this.state.nodaysbeforedefault;
    const thresholdUnresolvedContractsValue = this.state.thresholdUnresolvedContracts;

    if (!yearValidationRegex.test(startYear)) {
      if (startYear.length === 0) {
        fieldValidationErrors.startYear = "Field cannot be blank";
      } else {
        fieldValidationErrors.startYear = "Valid values between 1984 and 2100";
      }
      startYearValid = false;
    }

    if (!yearValidationRegex.test(endYear)) {
      if (endYear.length === 0) {
        fieldValidationErrors.endYear = "Field cannot be blank";
      } else {
        fieldValidationErrors.endYear = "Valid values between 1984 and 2100";
      }
      endYearValid = false;
    }

    if (
      this.state.isNewPeriod &&
      endYear > moment().year() - 1 &&
      endYearValid
    ) {
      fieldValidationErrors.endYear =
        "End Year should not be greater than " + (moment().year() - 1);
      endYearValid = false;
    }

    if (
      !this.state.isNewPeriod &&
      endYear > moment().year() - 2 &&
      endYearValid
    ) {
      fieldValidationErrors.endYear =
        "End Year should not be greater than " + (moment().year() - 2);
      endYearValid = false;
    }

    if (!periodValidationRegex.test(periodValue)) {
      if (periodValue === "" || periodValue === null) {
        fieldValidationErrors.period = "Field cannot be blank";
      } else {
        fieldValidationErrors.period = "Valid values between 1 and 100";
      }
      periodValid = false;
    }

    if (!nbDefaultValidationRegex.test(nodaysbeforedefaultValue)) {
      if (
        nodaysbeforedefaultValue === "" ||
        nodaysbeforedefaultValue === null
      ) {
        fieldValidationErrors.nodaysbeforedefault = "Field cannot be blank";
      } else {
        fieldValidationErrors.nodaysbeforedefault =
          "Valid values between 1 and 365";
      }
      nodaysbeforedefaultValid = false;
    }

    if (!nbDefaultValidationRegex.test(thresholdUnresolvedContractsValue)) {
      if (
        thresholdUnresolvedContractsValue === "" ||
        thresholdUnresolvedContractsValue === null
      ) {
        fieldValidationErrors.nodaysbeforedefault = "Field cannot be blank";
      } else {
        fieldValidationErrors.nodaysbeforedefault =
          "Valid values between 1 and 20";
      }
      thresholdUnresolvedContractsValid = false;
    }

    this.setState({
      formErrors: fieldValidationErrors,
      startYearValid: startYearValid,
      endYearValid: endYearValid,
      periodValid: periodValid,
      nodaysbeforedefaultValid: nodaysbeforedefaultValid,
      thresholdUnresolvedContractsValid: thresholdUnresolvedContractsValid
    });

    if (
      startYearValid &&
      endYearValid &&
      periodValid &&
      nodaysbeforedefaultValid &&
      thresholdUnresolvedContractsValid
    ) {
      if (startYear <= endYear) {
        return true;
      } else {
        let fieldValidationErrors = this.state.formErrors;
        fieldValidationErrors.startYear =
          "Starting Year should not be greater than End Year";
        this.setState({
          formErrors: fieldValidationErrors,
        });
        return false;
      }
    }
    return false;
  }

  
  putAndGetComputeSeqNo() {
    let seqNo = sessionStorage.getItem("pdSeq");
    if (seqNo == null) {
      sessionStorage.setItem("pdSeq", 1);
      return 1;
    } else {
      seqNo = parseInt(seqNo) + 1;
      sessionStorage.setItem("pdSeq", seqNo);
      return seqNo;
    }
  }

  
  computePD(e, params, calAction) {
    this.focusOutOfDropdown();
    let startYear = null;
    let endYear = null;
    let isEoy = null;
    if (calAction === CAL_ACTION_SAVED_QUERY_TAP) {
      startYear = params.active_year_from;
      endYear = params.active_year_to;
      isEoy = !params.isWorkEnvironment;
    } else {
      e.preventDefault();
      startYear = this.state.startYear;
      endYear = this.state.endYear;
      isEoy = this.state.isEoy;
    }
    if (this.validateYears(startYear, endYear)) {
      if (calAction === CAL_ACTION_COMPUTE_TAP) {
        if (!isEoy && this.props.userData.userRole !== 1) {
          let userInst = [
            this.props.memberMap[this.props.userData.institutionAbbr],
          ];
          params.members = userInst;
        }
      }

      let date = new Date();

      let _rating_data = null;
      let _rating_mdr_dynamic_data = null;
      let _rating_mdr_static_data = null;
      let _dynamic_wmdr_cdr_data = null;
      let _static_wmdr_cdr_data = null;
      let _dynamicCohort = null;
      let _dynamicCohortAverage = null;
      let _dynamicCohortStdDev = null;
      let _staticCohort = null;
      let _staticCohortAverage = null;
      let _staticCohortStdDev = null;

      let dateTime =
        date.getFullYear() +
        "-" +
        date.getDate() +
        "-" +
        (date.getMonth() + 1) +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds();

      let queryParams = null;
      if (calAction === CAL_ACTION_SAVED_QUERY_TAP) {
        queryParams = {
          dataset_year: params.dataset_year,
          active_year_from: startYear,
          active_year_to: endYear,
          isWorkEnvironment: !isEoy,
          memberInstititute: params.memberInstititute,
          sector: params.sector,
          industry: params.industry,
          subIndustry: params.subIndustry,
          subSubIndustry : params.subSubIndustry,
          region: params.region,
          regionContract: params.regionContract,
          country: params.country,
          countryContract: params.countryContract,
          crossDefaultClause_cprt: params.crossDefaultClause_cprt,
          crossDefaultClause_cntrct: params.crossDefaultClause_cntrct,
          category: params.category,
          include_contagion: params.include_contagion,
          currencyType: params.currencyType,
          loanType: params.loanType,
          resolutionType: params.resolutionType,
          sectorClassification: params.sectorClassification,
          originalAmountLoan: params.originalAmountLoan,
          defaultPeriod180: params.defaultPeriod180 ? 1 : 0,
          dateTime: dateTime,
          seqNum: this.putAndGetComputeSeqNo(),
          sovDefaultDefinition: params.sovDefaultDefinition,
          excludeDevelopedCountriesCounterpart: params.excludeDevelopedCountriesCounterpart,
          excludeDevelopedCountriesContract: params.excludeDevelopedCountriesContract,
          sovFlag: params.sovFlag,
          includeOutlook: params.includeOutlook,
        };
      } else {

//          params.sector = params.sector;
//          params.industry = params.industry;
//          params.sub_industry = params.sub_industry;

        queryParams = {
          dataset_year:
            new Date().getFullYear() - (this.state.isNewPeriod ? 1 : 2),
          active_year_from: this.state.startYear,
          active_year_to: this.state.endYear,
          isWorkEnvironment: !this.state.isEoy,
          memberInstititute: params.members,
          sector: params.sector,
          industry: params.industry,
          subIndustry: params.sub_industry,
          subSubIndustry : params.sub_sub_industry,
          region: params.region,
          regionContract: params.regionContract,
          country: params.country,
          countryContract: params.countryContract,
          crossDefaultClause_cprt: this.getCrossDefaultListFromIds(
            params.crossDefaultClauseCounterpart
          ),
          crossDefaultClause_cntrct: this.getCrossDefaultListFromIds(
            params.crossDefaultClauseContract
          ),
          category: params.category,
          include_contagion: params.contagion,
          currencyType: params.currencyType,
          loanType: params.loanType,
          resolutionType: params.resolutionType,
          sectorClassification: params.sectorClassification,
          originalAmountLoan: params.loanAmount,
          defaultPeriod180: this.state.isDefault180 ? 1 : 0,
          dateTime: dateTime,
          seqNum: this.putAndGetComputeSeqNo(),
          sovDefaultDefinition: params.sovDefaultDefinition,
          excludeDevelopedCountriesCounterpart: params.excludeDevelopedCountriesCounterpart,
          excludeDevelopedCountriesContract: params.excludeDevelopedCountriesContract,
          sovFlag: params.sovFlag,
          includeOutlook: this.state.includeOutlook,
        };
      }
      const config = {
        headers: {
          ...authHeader(),
        },
      };
      if (calAction === CAL_ACTION_COMPUTE_TAP) {
        this.setState({ ...queryParams });
        this.cancelToggle();
      }

      let selectedMemberInstitute = null;
      let selectedCategory = null;
      let selectedCdcCprt = null;
      let selectedCdcCrt = null;
      let selectedCurrencyType = null;
      let selectedLoanType = null;
      let selectedResolutionType = null;
      let selectedSectorClassification = null;
      let selectedLoanAmountType = null;
      let regionListSelected = null;
      let regionContractListSelected = null;

      let sectorListSelected = null;
      let industryListSelected = null;
      let subIndustryListSelected = null;
      let subSubIndustryListSelected = null;
      if (calAction === CAL_ACTION_SAVED_QUERY_TAP) {
        selectedMemberInstitute = this.getMemberListFromIds(
          params.memberInstititute
        );
        selectedCategory = this.getCategoryListFromIds(params.category);
        selectedCdcCprt = params.crossDefaultClause_cprt;
        selectedCdcCrt = params.crossDefaultClause_cntrct;
        selectedCurrencyType = this.getCurrencyTypeListFromIds(
          params.currencyType
        );
        selectedLoanType = this.getLoanTypeListFromIds(params.loanType);
        selectedResolutionType = this.getResolutionTypeListFromIds(params.resolutionType);
        selectedSectorClassification = this.getSectorClassificationListFromIds(params.sectorClassification);
        selectedLoanAmountType = this.getLoanAmountTypeListFromIds(
          params.originalAmountLoan
        );
        regionListSelected = this.getRegionListFromIds(params.region);
        regionContractListSelected = this.getRegionListFromIds(params.regionContract);

        sectorListSelected = this.getSectorListFromIds(params.sector);
        industryListSelected = this.getIndustryListFroIds(params.industry);
        subIndustryListSelected = this.getSubIndustryListFromIds(
          params.subIndustry
        );
        subSubIndustryListSelected = this.getSubSubIndustryListFromIds(params.subSubIndustry);
      } else {
        selectedMemberInstitute = this.getMemberListFromIds(params.members);
        selectedCategory = this.getCategoryListFromIds(params.category);
        selectedCdcCprt = this.getCrossDefaultListFromIds(
          params.crossDefaultClauseCounterpart
        );
        selectedCdcCrt = this.getCrossDefaultListFromIds(
          params.crossDefaultClauseContract
        );
        selectedCurrencyType = this.getCurrencyTypeListFromIds(
          params.currencyType
        );
        selectedLoanType = this.getLoanTypeListFromIds(params.loanType);
        selectedResolutionType = this.getResolutionTypeListFromIds(params.resolutionType);
        selectedSectorClassification = this.getSectorClassificationListFromIds(params.sectorClassification);
        selectedLoanAmountType = this.getLoanAmountTypeListFromIds(
          params.loanAmount
        );
        regionListSelected = this.getRegionListFromIds(params.region);
        regionContractListSelected = this.getRegionListFromIds(params.regionContract);

        sectorListSelected = this.getSectorListFromIds(params.sector);
        industryListSelected = this.getIndustryListFroIds(params.industry);
        subIndustryListSelected = this.getSubIndustryListFromIds(
          params.sub_industry
        );
        subSubIndustryListSelected = this.getSubSubIndustryListFromIds(params.sub_sub_industry);
      }
      this.props.beingExecutedCallback(PD, dateTime, null);

      const pdComputeUniqueID = "ComputePD" + randomComputeIDGenerator();
      this.saveComputeId(pdComputeUniqueID, queryParams);
      instance
        .post(
          "/compute/defaultProbability/false/" + pdComputeUniqueID,
          queryParams,
          config
        )
        .then((response) => {
          if (
            response != null &&
            response.data != null &&
            Object.keys(response.data).length > 0
          ) {
            let resPD = response.data;
            if (response.data.cohortMap !== undefined) {
              _dynamicCohort = resPD.dynamicCohort;
              _dynamicCohortAverage = resPD.average;
              _dynamicCohortStdDev = resPD.stdDev;
              _staticCohort = resPD.staticCohort;
              _staticCohortAverage = resPD.averageStatic;
              _staticCohortStdDev = resPD.stdDevStatic;
              _rating_data = resPD.cohortMap;
              _rating_mdr_dynamic_data = resPD.mdrFinal;
              _rating_mdr_static_data = resPD.mdrStaticFinal;
              _dynamic_wmdr_cdr_data = resPD.wmdrCdrFinal;
              _static_wmdr_cdr_data = resPD.wmdrCdrStaticFinal;

              _dynamicCohort = {
                ..._dynamicCohort,
                average: _dynamicCohortAverage,
                stdDev: _dynamicCohortStdDev,
              };

              _staticCohort = {
                ..._staticCohort,
                averageStatic: _staticCohortAverage,
                stdDevStatic: _staticCohortStdDev,
              };
              let savedParams = {};
              if (savedParams != null) {
                savedParams = JSON.parse(
                  sessionStorage.getItem("computeParams")
                );
              }
              let pdResponse = {
                dynamicCohort: _dynamicCohort,
                staticCohort: _staticCohort,
                rating_data: _rating_data,
                rating_mdr_dynamic_data: _rating_mdr_dynamic_data,
                rating_mdr_static_data: _rating_mdr_static_data,
                dynamic_wmdr_cdr_data: _dynamic_wmdr_cdr_data,
                static_wmdr_cdr_data: _static_wmdr_cdr_data,
                dateTime: dateTime,
                name: PROBABILITY_OF_DEFAULT,
                type: PD,
                accr: "PD",
                computeKey: resPD.key,
                params: {
                  members: selectedMemberInstitute,
                  membersId:
                    calAction === CAL_ACTION_COMPUTE_TAP
                      ? params.members
                      : params.memberInstititute,
                  mode: isEoy
                    ? "Annual Report Environment"
                    : "Work Environment",
                  startYear: startYear,
                  endYear: endYear,
                  category: selectedCategory,
                  categoryId: params.category,
                  cdc_cprt: selectedCdcCprt,
                  cdc_cprtId:
                    calAction === CAL_ACTION_COMPUTE_TAP
                      ? params.crossDefaultClauseCounterpart
                      : params.crossDefaultClause_cprt,
                  subSubIndustryGrp : subSubIndustryListSelected,
                  subSubIndustryGrpId :   calAction === CAL_ACTION_COMPUTE_TAP
                  ? params.sub_sub_industry
                  : params.subSubIndustry,
                  subIndustryGrp: subIndustryListSelected,
                  subIndustryGrpId:
                    calAction === CAL_ACTION_COMPUTE_TAP
                      ? params.sub_industry
                      : params.subIndustry,
                  industryGrp: industryListSelected,
                  industryGrpId: params.industry,
                  sector: sectorListSelected,
                  sectorId: params.sector,
                  region: regionListSelected,
                  regionContract: regionContractListSelected,
                  regionId: params.region,
                  regionIdContract: params.regionContract,
                  country:
                    calAction === CAL_ACTION_COMPUTE_TAP
                      ? savedParams[resPD.key].country
                      : params.country,
                  countryContract:
                      calAction === CAL_ACTION_COMPUTE_TAP
                        ? savedParams[resPD.key].countryContract
                        : params.countryContract,
                    contagion:
                    calAction === CAL_ACTION_COMPUTE_TAP
                      ? params.contagion
                        ? "Included"
                        : "Excluded"
                      : params.include_contagion
                      ? "Included"
                      : "Excluded",
                  contagionId:
                    calAction === CAL_ACTION_COMPUTE_TAP
                      ? params.contagion
                      : params.include_contagion,
                  cdc_crt: selectedCdcCrt,
                  cdc_crtId:
                    calAction === CAL_ACTION_COMPUTE_TAP
                      ? params.crossDefaultClauseContract
                      : params.crossDefaultClause_cntrct,
                  currency_type: selectedCurrencyType,
                  currency_typeId: params.currencyType,
                  loan_type: selectedLoanType,
                  loan_typeId: params.loanType,
                  resolution_type: selectedResolutionType,
                  resolution_typeId: params.resolutionType,
                  sector_classification: selectedSectorClassification,
                  sector_classificationId: params.sectorClassification,
                  loan_amount: selectedLoanAmountType,
                  loan_amountId:
                    calAction === CAL_ACTION_COMPUTE_TAP
                      ? params.loanAmount
                      : params.originalAmountLoan,
                  default_period:
                    calAction === CAL_ACTION_COMPUTE_TAP
                      ? savedParams[resPD.key].defaultPeriod180 === 1
                        ? "180 days"
                        : "90 days"
                      : params.defaultPeriod180 === 1
                      ? "180 days"
                      : "90 days",
                  default_periodId:
                    calAction === CAL_ACTION_COMPUTE_TAP
                      ? savedParams[resPD.key].defaultPeriod180
                      : params.defaultPeriod180,
                  isNewPeriod:
                    calAction === CAL_ACTION_COMPUTE_TAP
                      ? savedParams[resPD.key].dataset_year ===
                        new Date().getFullYear() - 1
                        ? "New data"
                        : "Old data"
                      : params.dataset_year === new Date().getFullYear() - 1
                      ? "New data"
                      : "Old data",
                  calType: PD,
                  dataset_year:
                    calAction === CAL_ACTION_COMPUTE_TAP
                      ? queryParams.dataset_year
                      : params.dataset_year,
                  sovDefaultDefinition:
                    savedParams[resPD.key].sovDefaultDefinition,
				  excludeDevelopedCountriesCounterpart: 
					savedParams[resPD.key].excludeDevelopedCountriesCounterpart,
				  excludeDevelopedCountriesContract: 
					savedParams[resPD.key].excludeDevelopedCountriesContract,
                  sovFlag: savedParams[resPD.key].sovFlag,
                  includeOutlook:
                    calAction === CAL_ACTION_COMPUTE_TAP
                      ? savedParams[resPD.key].includeOutlook
                      : this.state.includeOutlook,
                },
                noData: !resPD.resultDisplay,
                displayRating: resPD.sovereginRating,
              };
              this.props.computeCallback(PD, pdResponse);
            }
          } else {
            this.props.deleteBeingExecutedCallbackIfError(PD, dateTime);
            showToast("No result", TOAST_TYPE_SUCCESS);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              sessionStorage.clear();
              window.location.replace("/");
            } else {
              this.props.deleteBeingExecutedCallbackIfError(PD, dateTime);
              showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
            }
          } else {
            this.props.deleteBeingExecutedCallbackIfError(PD, dateTime);
            showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
          }
        });
    }
  }

  
  computeMM(e, params, calAction) {
    this.focusOutOfDropdown();
    let startYear = null;
    let endYear = null;
    let isEoy = null;
    if (calAction === CAL_ACTION_SAVED_QUERY_TAP) {
      startYear = params.active_year_from;
      endYear = params.active_year_to;
      isEoy = !params.isWorkEnvironment;
    } else {
      e.preventDefault();
      startYear = this.state.startYear;
      endYear = this.state.endYear;
      isEoy = this.state.isEoy;
    }
    if (this.validateYears(startYear, endYear)) {
      if (calAction === CAL_ACTION_COMPUTE_TAP) {
        if (!isEoy && this.props.userData.userRole !== 1) {
          let userInst = [
            this.props.memberMap[this.props.userData.institutionAbbr],
          ];
          params.members = userInst;
        }
      }

      let date = new Date();

      let _rating_data = null;
      let _rating_mdr_dynamic_data = null;
      let _rating_mdr_static_data = null;
      let _dynamic_wmdr_cdr_data = null;
      let _static_wmdr_cdr_data = null;

      let dateTime =
        date.getFullYear() +
        "-" +
        date.getDate() +
        "-" +
        (date.getMonth() + 1) +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds();

      let queryParams = null;
      if (calAction === CAL_ACTION_SAVED_QUERY_TAP) {
        queryParams = {
          dataset_year: params.dataset_year,
          active_year_from: startYear,
          active_year_to: endYear,
          isWorkEnvironment: !isEoy,
          memberInstititute: params.memberInstititute,
          sector: params.sector,
          subIndustry: params.subIndustry,
          subSubIndustry : params.subSubIndustry,
          industry: params.industry,
          region: params.region,
          regionContract: params.regionContract,
          country: params.country,
          countryContract: params.countryContract,
          crossDefaultClause_cprt: params.crossDefaultClause_cprt,
          crossDefaultClause_cntrct: params.crossDefaultClause_cntrct,
          category: params.category,
          include_contagion: params.include_contagion,
          currencyType: params.currencyType,
          loanType: params.loanType,
          resolutionType: params.resolutionType,
          sectorClassification: params.sectorClassification,
          originalAmountLoan: params.originalAmountLoan,
          defaultPeriod180: params.defaultPeriod180 ? 1 : 0,
          dateTime: dateTime,
          seqNum: this.putAndGetComputeSeqNo(),
          period: params.period,
          sovDefaultDefinition: params.sovDefaultDefinition,
		  excludeDevelopedCountriesCounterpart: params.excludeDevelopedCountriesCounterpart,
		  excludeDevelopedCountriesContract: params.excludeDevelopedCountriesContract,
          sovFlag: params.sovFlag,
          includeOutlook: params.includeOutlook,
        };
      } else {

//          params.sector = params.sector;
//          params.industry = params.industry;
//          params.sub_industry = params.sub_industry;

        queryParams = {
          dataset_year:
            new Date().getFullYear() - (this.state.isNewPeriod ? 1 : 2),
          active_year_from: this.state.startYear,
          active_year_to: this.state.endYear,
          isWorkEnvironment: !this.state.isEoy,
          memberInstititute: params.members,
          sector: params.sector,
          subIndustry: params.sub_industry,
          subSubIndustry : params.sub_sub_industry,
          industry: params.industry,
          region: params.region,
          regionContract: params.regionContract,
          country: params.country,
          countryContract: params.countryContract,
          crossDefaultClause_cprt: this.getCrossDefaultListFromIds(
            params.crossDefaultClauseCounterpart
          ),
          crossDefaultClause_cntrct: this.getCrossDefaultListFromIds(
            params.crossDefaultClauseContract
          ),
          category: params.category,
          include_contagion: params.contagion,
          currencyType: params.currencyType,
          loanType: params.loanType,
          resolutionType: params.resolutionType,
          sectorClassification: params.sectorClassification,
          originalAmountLoan: params.loanAmount,
          defaultPeriod180: this.state.isDefault180 ? 1 : 0,
          dateTime: dateTime,
          seqNum: this.putAndGetComputeSeqNo(),
          period: this.state.period,
          sovDefaultDefinition: params.sovDefaultDefinition,
		  excludeDevelopedCountriesCounterpart: params.excludeDevelopedCountriesCounterpart,
		  excludeDevelopedCountriesContract: params.excludeDevelopedCountriesContract,
          sovFlag: params.sovFlag,
          includeOutlook: this.state.includeOutlook,
        };
      }
      const config = {
        headers: {
          ...authHeader(),
        },
      };
      if (calAction === CAL_ACTION_COMPUTE_TAP) {
        this.setState({ ...queryParams });
        this.cancelToggleModal("MMmodal");
      }

      let selectedMemberInstitute = null;
      let selectedCategory = null;
      let selectedCdcCprt = null;
      let selectedCdcCrt = null;
      let selectedCurrencyType = null;
      let selectedLoanType = null;
      let selectedResolutionType = null;
      let selectedSectorClassification = null;
      let selectedLoanAmountType = null;
      let regionListSelected = null;
      let regionContractListSelected = null;
      let sectorListSelected = null;
      let industryListSelected = null;
      let subIndustryListSelected = null;
      let subSubIndustryListSelected = null;
      if (calAction === CAL_ACTION_SAVED_QUERY_TAP) {
        selectedMemberInstitute = this.getMemberListFromIds(
          params.memberInstititute
        );
        selectedCategory = this.getCategoryListFromIds(params.category);
        selectedCdcCprt = params.crossDefaultClause_cprt;
        selectedCdcCrt = params.crossDefaultClause_cntrct;
        selectedCurrencyType = this.getCurrencyTypeListFromIds(
          params.currencyType
        );
        selectedLoanType = this.getLoanTypeListFromIds(params.loanType);
        selectedResolutionType = this.getResolutionTypeListFromIds(params.resolutionType);
        selectedSectorClassification = this.getSectorClassificationListFromIds(params.sectorClassification);
        selectedLoanAmountType = this.getLoanAmountTypeListFromIds(
          params.originalAmountLoan
        );
        regionListSelected = this.getRegionListFromIds(params.region);
        regionContractListSelected = this.getRegionListFromIds(params.regionContract);
        sectorListSelected = this.getSectorListFromIds(params.sector);
        industryListSelected = this.getIndustryListFroIds(params.industry);
        subIndustryListSelected = this.getSubIndustryListFromIds(
          params.subIndustry
        );
        subSubIndustryListSelected = this.getSubSubIndustryListFromIds(params.subSubIndustry);
      } else {
        selectedMemberInstitute = this.getMemberListFromIds(params.members);
        selectedCategory = this.getCategoryListFromIds(params.category);
        selectedCdcCprt = this.getCrossDefaultListFromIds(
          params.crossDefaultClauseCounterpart
        );
        selectedCdcCrt = this.getCrossDefaultListFromIds(
          params.crossDefaultClauseContract
        );
        selectedCurrencyType = this.getCurrencyTypeListFromIds(
          params.currencyType
        );
        selectedLoanType = this.getLoanTypeListFromIds(params.loanType);
        selectedResolutionType = this.getResolutionTypeListFromIds(params.resolutionType);
        selectedSectorClassification = this.getSectorClassificationListFromIds(params.sectorClassification);
        selectedLoanAmountType = this.getLoanAmountTypeListFromIds(
          params.loanAmount
        );
        regionListSelected = this.getRegionListFromIds(params.region);
        regionContractListSelected = this.getRegionListFromIds(params.regionContract);
        sectorListSelected = this.getSectorListFromIds(params.sector);
        industryListSelected = this.getIndustryListFroIds(params.industry);
        subIndustryListSelected = this.getSubIndustryListFromIds(
          params.sub_industry
        );
        subSubIndustryListSelected = this.getSubSubIndustryListFromIds(params.sub_sub_industry);
      }
      this.props.beingExecutedCallback(MM, dateTime, null);

      const mmComputeUniqueID = "ComputeMM" + randomComputeIDGenerator();
      this.saveComputeId(mmComputeUniqueID, queryParams);

      instance
        .post(
          "/compute/marginalMatrix/false/" + mmComputeUniqueID,
          queryParams,
          config
        )
        .then((response) => {
          if (
            response != null &&
            response.data != null &&
            Object.keys(response.data).length > 0
          ) {
            let resMM = response.data;
            let savedParams = {};
            if (savedParams != null) {
              savedParams = JSON.parse(sessionStorage.getItem("computeParams"));
            }
            let mmResponse = {
              mmMaps: resMM,
              rating_data: _rating_data,
              rating_mdr_dynamic_data: _rating_mdr_dynamic_data,
              rating_mdr_static_data: _rating_mdr_static_data,
              dynamic_wmdr_cdr_data: _dynamic_wmdr_cdr_data,
              static_wmdr_cdr_data: _static_wmdr_cdr_data,
              dateTime: dateTime,
              name: MIGRATION_MATRIX,
              type: MM,
              accr: "MM",
              computeKey: resMM.key,
              params: {
                members: selectedMemberInstitute,
                mode: isEoy ? "Annual Report Environment" : "Work Environment",
                startYear: startYear,
                endYear: endYear,
                category: selectedCategory,
                cdc_cprt: selectedCdcCprt,
                subSubIndustryGrp : subSubIndustryListSelected,
                subSubIndustryGrpId :   calAction === CAL_ACTION_COMPUTE_TAP
                ? params.sub_sub_industry
                : params.subSubIndustry,
                subIndustryGrp: subIndustryListSelected,
                industryGrp: industryListSelected,
                sector: sectorListSelected,
                region: regionListSelected,
                regionContract: regionContractListSelected,
                country: params.country,
                countryContract: params.countryContract,
                contagion:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? params.contagion
                      ? "Included"
                      : "Excluded"
                    : params.include_contagion
                    ? "Included"
                    : "Excluded",
                cdc_crt: selectedCdcCrt,
                currency_type: selectedCurrencyType,
                loan_type: selectedLoanType,
                resolution_type: selectedResolutionType,
                sector_classification: selectedSectorClassification,
                loan_amount: selectedLoanAmountType,
                default_period:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? savedParams[resMM.key].defaultPeriod180 === 1
                      ? "180 days"
                      : "90 days"
                    : params.defaultPeriod180 === 1
                    ? "180 days"
                    : "90 days",
                isNewPeriod:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? savedParams[resMM.key].dataset_year ===
                      new Date().getFullYear() - 1
                      ? "New data"
                      : "Old data"
                    : params.dataset_year === new Date().getFullYear() - 1
                    ? "New data"
                    : "Old data",
                period:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? savedParams[resMM.key].period
                    : params.period,
                calType: MM,
                dataset_year:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? queryParams.dataset_year
                    : params.dataset_year,
                membersId:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? params.members
                    : params.memberInstititute,
                categoryId: params.category,
                cdc_cprtId:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? params.crossDefaultClauseCounterpart
                    : params.crossDefaultClause_cprt,
                subIndustryGrpId:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? params.sub_industry
                    : params.subIndustry,
                industryGrpId: params.industry,
                sectorId: params.sector,
                regionId: params.region,
                regionIdContract: params.regionContract,
                contagionId:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? params.contagion
                    : params.include_contagion,
                cdc_crtId:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? params.crossDefaultClauseContract
                    : params.crossDefaultClause_cntrct,
                currency_typeId: params.currencyType,
                loan_typeId: params.loanType,
                resolution_typeId: params.resolutionType,
                sector_classificationId: params.sectorClassification,
                loan_amountId:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? params.loanAmount
                    : params.originalAmountLoan,
                default_periodId:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? savedParams[resMM.key].defaultPeriod180
                    : params.defaultPeriod180,
                sovDefaultDefinition:
                  savedParams[resMM.key].sovDefaultDefinition,
				excludeDevelopedCountriesCounterpart:
				  savedParams[resMM.key].excludeDevelopedCountriesCounterpart,
				excludeDevelopedCountriesContract: 
				  savedParams[resMM.key].excludeDevelopedCountriesContract,
                sovFlag: savedParams[resMM.key].sovFlag,
                includeOutlook:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? savedParams[resMM.key].includeOutlook
                    : this.state.includeOutlook,
              },
            };
            
            this.props.computeCallback(MM, mmResponse);
          } else {
            this.props.deleteBeingExecutedCallbackIfError(MM, dateTime);
            showToast("No result", TOAST_TYPE_SUCCESS);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              sessionStorage.clear();
              window.location.replace("/");
            } else {
              this.props.deleteBeingExecutedCallbackIfError(MM, dateTime);
              showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
            }
          } else {
            this.props.deleteBeingExecutedCallbackIfError(MM, dateTime);
            showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
          }
        });
    }
  }

  
  computeRR(e, params, calAction) {
    this.focusOutOfDropdown();
    let startYear = null;
    let endYear = null;
    let isEoy = null;
    if (calAction === CAL_ACTION_SAVED_QUERY_TAP) {
      startYear = params.active_year_from;
      endYear = params.active_year_to;
      isEoy = !params.isWorkEnvironment;
    } else {
      e.preventDefault();
      startYear = this.state.startYear;
      endYear = this.state.endYear;
      isEoy = this.state.isEoy;
    }
    if (this.validateYears(startYear, endYear)) {
      if (calAction === CAL_ACTION_COMPUTE_TAP) {
        if (!isEoy && this.props.userData.userRole !== 1) {
          let userInst = [
            this.props.memberMap[this.props.userData.institutionAbbr],
          ];
          params.members = userInst;
        }
      }

      let date = new Date();

      let _rating_data = null;
      let _rating_mdr_dynamic_data = null;
      let _rating_mdr_static_data = null;
      let _dynamic_wmdr_cdr_data = null;
      let _static_wmdr_cdr_data = null;

      let dateTime =
        date.getFullYear() +
        "-" +
        date.getDate() +
        "-" +
        (date.getMonth() + 1) +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds();

      let queryParams = null;
      if (calAction === CAL_ACTION_SAVED_QUERY_TAP) {
        queryParams = {
          dataset_year: params.dataset_year,
          active_year_from: startYear,
          active_year_to: endYear,
          isWorkEnvironment: !isEoy,
          memberInstititute: params.memberInstititute,
          sector: params.sector,
          industry: params.industry,
          subIndustry: params.subIndustry,
          subSubIndustry : params.subSubIndustry,
          region: params.region,
          regionContract: params.regionContract,
          country: params.country,
          countryContract: params.countryContract,
          crossDefaultClause_cprt: params.crossDefaultClause_cprt,
          crossDefaultClause_cntrct: params.crossDefaultClause_cntrct,
          category: params.category,
          include_contagion: params.include_contagion,
          currencyType: params.currencyType,
          loanType: params.loanType,
          resolutionType: params.resolutionType,
          sectorClassification: params.sectorClassification,
          originalAmountLoan: params.originalAmountLoan,
          defaultPeriod180: params.defaultPeriod180 ? 1 : 0,
          dateTime: dateTime,
          seqNum: this.putAndGetComputeSeqNo(),
          nodaysbeforedefault: params.nodaysbeforedefault,
          thresholdUnresolvedContracts: params.thresholdUnresolvedContracts,
          includeUnresolvedContracts: params.includeUnresolvedContracts,
          sovDefaultDefinition: params.sovDefaultDefinition,
		  excludeDevelopedCountriesCounterpart: params.excludeDevelopedCountriesCounterpart,
		  excludeDevelopedCountriesContract: params.excludeDevelopedCountriesContract,
          sovFlag: params.sovFlag,
        };
      } else {

//          params.sector = params.sector;
//          params.industry = params.industry;
//          params.sub_industry = params.sub_industry;

        queryParams = {
          dataset_year:
            new Date().getFullYear() - (this.state.isNewPeriod ? 1 : 2),
          active_year_from: this.state.startYear,
          active_year_to: this.state.endYear,
          isWorkEnvironment: !this.state.isEoy,
          memberInstititute: params.members,
          sector: params.sector,
          industry: params.industry,
          subIndustry: params.sub_industry,
          subSubIndustry : params.sub_sub_industry,
          region: params.region,
          regionContract: params.regionContract,
          country: params.country,
          countryContract: params.countryContract,
          crossDefaultClause_cprt: this.getCrossDefaultListFromIds(
            params.crossDefaultClauseCounterpart
          ),
          crossDefaultClause_cntrct: this.getCrossDefaultListFromIds(
            params.crossDefaultClauseContract
          ),
          category: params.category,
          include_contagion: params.contagion,
          currencyType: params.currencyType,
          loanType: params.loanType,
          resolutionType: params.resolutionType,
          sectorClassification: params.sectorClassification,
          originalAmountLoan: params.loanAmount,
          defaultPeriod180: this.state.isDefault180 ? 1 : 0,
          dateTime: dateTime,
          seqNum: this.putAndGetComputeSeqNo(),
          nodaysbeforedefault: this.state.nodaysbeforedefault,
          thresholdUnresolvedContracts: this.state.thresholdUnresolvedContracts,
          includeUnresolvedContracts: this.state.includeUnresolvedContracts,
          sovDefaultDefinition: params.sovDefaultDefinition,
		  excludeDevelopedCountriesCounterpart: params.excludeDevelopedCountriesCounterpart,
		  excludeDevelopedCountriesContract: params.excludeDevelopedCountriesContract,
          sovFlag: params.sovFlag,
        };
      }

      const config = {
        headers: {
          ...authHeader(),
        },
      };
      if (calAction === CAL_ACTION_COMPUTE_TAP) {
        this.setState({ ...queryParams });
        this.cancelToggleModal("RRmodal");
      }

      let selectedMemberInstitute = null;
      let selectedCategory = null;
      let selectedCdcCprt = null;
      let selectedCdcCrt = null;
      let selectedCurrencyType = null;
      let selectedLoanType = null;
      let selectedResolutionType = null;
      let selectedSectorClassification = null;
      let selectedLoanAmountType = null;

      let regionListSelected = null;
      let regionContractListSelected = null;

      let sectorListSelected = null;
      let industryListSelected = null;
      let subIndustryListSelected = null;
      let subSubIndustryListSelected = null;
      if (calAction === CAL_ACTION_SAVED_QUERY_TAP) {
        selectedMemberInstitute = this.getMemberListFromIds(
          params.memberInstititute
        );
        selectedCategory = this.getCategoryListFromIds(params.category);
        selectedCdcCprt = params.crossDefaultClause_cprt;
        selectedCdcCrt = params.crossDefaultClause_cntrct;
        selectedCurrencyType = this.getCurrencyTypeListFromIds(
          params.currencyType
        );
        selectedLoanType = this.getLoanTypeListFromIds(params.loanType);
        selectedResolutionType = this.getResolutionTypeListFromIds(params.resolutionType);
        selectedSectorClassification = this.getSectorClassificationListFromIds(params.sectorClassification);
        selectedLoanAmountType = this.getLoanAmountTypeListFromIds(
          params.originalAmountLoan
        );

        regionListSelected = this.getRegionListFromIds(params.region);
        regionContractListSelected = this.getRegionListFromIds(params.regionContract);

        sectorListSelected = this.getSectorListFromIds(params.sector);
        industryListSelected = this.getIndustryListFroIds(params.industry);
        subIndustryListSelected = this.getSubIndustryListFromIds(
          params.subIndustry
        );
        subSubIndustryListSelected = this.getSubSubIndustryListFromIds(params.subSubIndustry);
      } else {
        selectedMemberInstitute = this.getMemberListFromIds(params.members);
        selectedCategory = this.getCategoryListFromIds(params.category);
        selectedCdcCprt = this.getCrossDefaultListFromIds(
          params.crossDefaultClauseCounterpart
        );
        selectedCdcCrt = this.getCrossDefaultListFromIds(
          params.crossDefaultClauseContract
        );
        selectedCurrencyType = this.getCurrencyTypeListFromIds(
          params.currencyType
        );
        selectedLoanType = this.getLoanTypeListFromIds(params.loanType);
        selectedResolutionType = this.getResolutionTypeListFromIds(params.resolutionType);
        selectedSectorClassification = this.getSectorClassificationListFromIds(params.sectorClassification);
        selectedLoanAmountType = this.getLoanAmountTypeListFromIds(
          params.loanAmount
        );

        regionListSelected = this.getRegionListFromIds(params.region);

        regionContractListSelected = this.getRegionListFromIds(params.regionContract);

        sectorListSelected = this.getSectorListFromIds(params.sector);
        industryListSelected = this.getIndustryListFroIds(params.industry);
        subIndustryListSelected = this.getSubIndustryListFromIds(
          params.sub_industry
        );
        subSubIndustryListSelected = this.getSubSubIndustryListFromIds(params.sub_sub_industry);
      }

      this.props.beingExecutedCallback(RR, dateTime, null);
      const rrComputeUniqueID = "ComputeRR" + randomComputeIDGenerator();
      this.saveComputeId(rrComputeUniqueID, queryParams);

      instance
        .post(
          "/compute/recoveryRate/false/" + rrComputeUniqueID,
          queryParams,
          config
        )
        .then((response) => {
          if (
            response != null &&
            response.data != null &&
            Object.keys(response.data).length > 0
          ) {
            let resRR = response.data;
            let savedParams = {};
            if (savedParams != null) {
              savedParams = JSON.parse(sessionStorage.getItem("computeParams"));
            }
            let rrResponse = {
              rrMaps: resRR,
              rating_data: _rating_data,
              rating_mdr_dynamic_data: _rating_mdr_dynamic_data,
              rating_mdr_static_data: _rating_mdr_static_data,
              dynamic_wmdr_cdr_data: _dynamic_wmdr_cdr_data,
              static_wmdr_cdr_data: _static_wmdr_cdr_data,
              dateTime: dateTime,
              name: RECOVERY_RATE,
              type: RR,
              accr: "RR",
              computeKey: resRR.key,
              params: {
                members: selectedMemberInstitute,
                mode: isEoy ? "Annual Report Environment" : "Work Environment",
                startYear: startYear,
                endYear: endYear,
                category: selectedCategory,
                cdc_cprt: selectedCdcCprt,
                subSubIndustryGrp : subSubIndustryListSelected,
                subSubIndustryGrpId :   calAction === CAL_ACTION_COMPUTE_TAP
                ? params.sub_sub_industry
                : params.subSubIndustry,
                subIndustryGrp: subIndustryListSelected,
                industryGrp: industryListSelected,
                sector: sectorListSelected,
                region: regionListSelected,
                regionContract: regionContractListSelected,
                country: params.country,
                countryContract: params.countryContract,
                contagion:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? params.contagion
                      ? "Included"
                      : "Excluded"
                    : params.include_contagion
                    ? "Included"
                    : "Excluded",
                cdc_crt: selectedCdcCrt,
                currency_type: selectedCurrencyType,
                loan_type: selectedLoanType,
                resolution_type: selectedResolutionType,
                sector_classification: selectedSectorClassification,
                loan_amount: selectedLoanAmountType,
                default_period:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? savedParams[resRR.key].defaultPeriod180 === 1
                      ? "180 days"
                      : "90 days"
                    : params.defaultPeriod180 === 1
                    ? "180 days"
                    : "90 days",
                isNewPeriod:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? savedParams[resRR.key].dataset_year ===
                      new Date().getFullYear() - 1
                      ? "New data"
                      : "Old data"
                    : params.dataset_year === new Date().getFullYear() - 1
                    ? "New data"
                    : "Old data",
                period: params.period,
                calType: RR,
                includeUnresolvedContracts:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? savedParams[resRR.key].includeUnresolvedContracts
                    : params.includeUnresolvedContracts,
            dataset_year:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? queryParams.dataset_year
                    : params.dataset_year,
                membersId:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? params.members
                    : params.memberInstititute,
                categoryId: params.category,
                cdc_cprtId:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? params.crossDefaultClauseCounterpart
                    : params.crossDefaultClause_cprt,
                subIndustryGrpId:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? params.sub_industry
                    : params.subIndustry,
                industryGrpId: params.industry,
                sectorId: params.sector,
                regionId: params.region,
                regionIdContract: params.regionContract,
                contagionId:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? params.contagion
                    : params.include_contagion,
                cdc_crtId:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? params.crossDefaultClauseContract
                    : params.crossDefaultClause_cntrct,
                currency_typeId: params.currencyType,
                loan_typeId: params.loanType,
                resolution_typeId:params.resolutionType,
                sector_classificationId: params.sectorClassification,
                loan_amountId:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? params.loanAmount
                    : params.originalAmountLoan,
                default_periodId:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? savedParams[resRR.key].defaultPeriod180
                    : params.defaultPeriod180,
                nodaysbeforedefault:
                  calAction === CAL_ACTION_COMPUTE_TAP
                    ? savedParams[resRR.key].nodaysbeforedefault
                    : params.nodaysbeforedefault,
                thresholdUnresolvedContracts:
                  calAction === CAL_ACTION_COMPUTE_TAP
                  ? savedParams[resRR.key].thresholdUnresolvedContracts
                  : params.thresholdUnresolvedContracts,
                sovDefaultDefinition:
                  savedParams[resRR.key].sovDefaultDefinition,
				excludeDevelopedCountriesCounterpart:  
				  savedParams[resRR.key].excludeDevelopedCountriesCounterpart,
				excludeDevelopedCountriesContract: 
				  savedParams[resRR.key].excludeDevelopedCountriesContract,
                sovFlag: savedParams[resRR.key].sovFlag,
              },
            };
            this.props.computeCallback(RR, rrResponse);
          } else {
            this.props.deleteBeingExecutedCallbackIfError(RR, dateTime);
            showToast("No result", TOAST_TYPE_SUCCESS);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              sessionStorage.clear();
              window.location.replace("/");
            } else {
              this.props.deleteBeingExecutedCallbackIfError(RR, dateTime);
              showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
            }
          } else {
            this.props.deleteBeingExecutedCallbackIfError(RR, dateTime);
            showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
          }
        });
    }
  }

  
  computeFC(e, params, calAction) {
    this.focusOutOfDropdown();
    let startYear = null;
    let endYear = null;
    let isEoy = null;
    if (calAction === CAL_ACTION_SAVED_QUERY_TAP) {
      startYear = params.active_year_from;
      endYear = params.active_year_to;
      isEoy = !params.isWorkEnvironment;
    } else {
      e.preventDefault();
      startYear = this.state.startYear;
      endYear = this.state.endYear;
      isEoy = this.state.isEoy;
    }
    if (this.validateYears(startYear, endYear)) {
      if (calAction === CAL_ACTION_COMPUTE_TAP) {
        if (!isEoy && this.props.userData.userRole !== 1) {
          let userInst = [
            this.props.memberMap[this.props.userData.institutionAbbr],
          ];
          params.members = userInst;
        }
      }

      let date = new Date();
      let dateTime =
        date.getFullYear() +
        "-" +
        date.getDate() +
        "-" +
        (date.getMonth() + 1) +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds();

      let queryParams = null;
      if (calAction === CAL_ACTION_SAVED_QUERY_TAP) {
        queryParams = {
          dataset_year: params.dataset_year,
          active_year_from: startYear,
          active_year_to: endYear,
          isWorkEnvironment: !isEoy,
          memberInstititute: params.memberInstititute,
          sector: params.sector,
          industry: params.industry,
          subIndustry: params.subIndustry,
          subSubIndustry : params.subSubIndustry,
          region: params.region,
          regionContract: params.regionContract,
          country: params.country,
          countryContract: params.countryContract,
          crossDefaultClause_cprt: params.crossDefaultClause_cprt,
          crossDefaultClause_cntrct: params.crossDefaultClause_cntrct,
          category: params.category,
          include_contagion: params.include_contagion,
          currencyType: params.currencyType,
          loanType: params.loanType,
          resolutionType: params.resolutionType,
          sectorClassification: params.sectorClassification,
          originalAmountLoan: params.originalAmountLoan,
          defaultPeriod180: params.defaultPeriod180 ? 1 : 0,
          dateTime: dateTime,
          seqNum: this.putAndGetComputeSeqNo(),
          nodaysbeforedefault: params.nodaysbeforedefault,
          thresholdUnresolvedContracts: params.thresholdUnresolvedContracts,
          includeUnresolvedContracts: params.includeUnresolvedContracts,
          sovDefaultDefinition: params.sovDefaultDefinition,
		  excludeDevelopedCountriesCounterpart: params.excludeDevelopedCountriesCounterpart,
		  excludeDevelopedCountriesContract: params.excludeDevelopedCountriesContract,
          sovFlag: params.sovFlag,
          includeOutlook: params.includeOutlook,
        };
      } else {

//          params.sector = params.sector;
//          params.industry = params.industry;
//          params.sub_industry = params.sub_industry;

        queryParams = {
          dataset_year:
            new Date().getFullYear() - (this.state.isNewPeriod ? 1 : 2),
          active_year_from: this.state.startYear,
          active_year_to: this.state.endYear,
          isWorkEnvironment: !this.state.isEoy,
          memberInstititute: params.members,
          sector: params.sector,
          industry: params.industry,
          subIndustry: params.sub_industry,
          subSubIndustry : params.sub_sub_industry,
          region: params.region,
          regionContract: params.regionContract,
          country: params.country,
          countryContract: params.countryContract,
          crossDefaultClause_cprt: this.getCrossDefaultListFromIds(
            params.crossDefaultClauseCounterpart
          ),
          crossDefaultClause_cntrct: this.getCrossDefaultListFromIds(
            params.crossDefaultClauseContract
          ),
          category: params.category,
          include_contagion: params.contagion,
          currencyType: params.currencyType,
          loanType: params.loanType,
          resolutionType: params.resolutionType,
          sectorClassification: params.sectorClassification,
          originalAmountLoan: params.loanAmount,
          defaultPeriod180: this.state.isDefault180 ? 1 : 0,
          dateTime: dateTime,
          seqNum: this.putAndGetComputeSeqNo(),
          nodaysbeforedefault: this.state.nodaysbeforedefault,
          thresholdUnresolvedContracts: this.state.thresholdUnresolvedContracts,
          includeUnresolvedContracts: this.state.includeUnresolvedContracts,
          sovDefaultDefinition: params.sovDefaultDefinition,
		  excludeDevelopedCountriesCounterpart: params.excludeDevelopedCountriesCounterpart,
		  excludeDevelopedCountriesContract: params.excludeDevelopedCountriesContract,
          sovFlag: params.sovFlag,
          includeOutlook: this.state.includeOutlook,
        };
      }

      const config = {
        headers: {
          ...authHeader(),
        },
      };
      if (calAction === CAL_ACTION_COMPUTE_TAP) {
        this.setState({ ...queryParams });
        this.cancelToggleModal("FCmodal");
      }

      const fullComputeUniqueID = randomComputeIDGenerator();

      instance
        .post(
          "/compute/fullCompute/false/" + fullComputeUniqueID + "/0",
          queryParams,
          config
        )
        .then((response) => {
          this.saveFullComputeId({
            id: fullComputeUniqueID,
            dateTime: dateTime,
            queryCount: 104,
          });
          this.setState({
            computedResultKeys: {
              ...this.state.computedResultKeys,
              [fullComputeUniqueID]: [],
            },
          });
          this.props.beingExecutedCallback(FC, dateTime, fullComputeUniqueID);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              sessionStorage.clear();
              window.location.replace("/");
            } else {
              
              showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
            }
          } else {
            
            showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
          }
        });
    }
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  cancelToggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      formErrors: {
        startYear: "",
        endYear: "",
      },
    }));
  }

  toggleModal(modalName) {
    this.setState((prevState) => ({
      [modalName]: !prevState[modalName],
    }));
  }

  cancelToggleModal(modalName) {
    this.setState((prevState) => ({
      [modalName]: !prevState[modalName],
      formErrors: {
        startYear: "",
        endYear: "",
        period: "",
        nodaysbeforedefault: "",
        thresholdUnresolvedContracts: "",
      },
    }));
  }

  setYearForParam(e) {
    let name = e.target.name;
    let value = e.target.value;
    if (value.length <= 4) {
      this.setState({ [name]: value });
      let fieldValidationErrors = this.state.formErrors;
      fieldValidationErrors[name] = "";
      this.setState({
        formErrors: fieldValidationErrors,
        [name + "Valid"]: true,
      });
    }
  }

  setFieldValues(e, max_length) {
    let name = e.target.name;
    let value = e.target.value;
    if (value.length <= max_length) {
      this.setState({ [name]: value });
      let fieldValidationErrors = this.state.formErrors;
      fieldValidationErrors[name] = "";
      this.setState({
        formErrors: fieldValidationErrors,
        [name + "Valid"]: true,
      });
    }
  }

  preventUnwantedNumericChars(event) {
    var code = event.keyCode || event.which;
    
    if ([69, 101, 46, 43, 45, 190].includes(code)) {
      event.preventDefault();
    }
  }

  render() {
    return (
      <div className="computeoption-sec">
        {this.state.modal ? (
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            backdrop={false}
          >
            <ModalHeader toggle={this.cancelToggle}>
              PD Calculation Parameters
            </ModalHeader>
            <ModalBody>
              <Form
                className="lib-model"
                onSubmit={(e) =>
                  this.computePD(
                    e,
                    this.props.allParams,
                    CAL_ACTION_COMPUTE_TAP
                  )
                }
              >
                <FormGroup className="pt-1">
                  <div className="row">
                    <label className="col-sm-4 col-form-label">
                      Starting Year
                    </label>
                    <div className="col-sm-8">
                      <div
                        className={`form-group ${this.errorClass(
                          this.state.formErrors.startYear
                        )}`}
                      >
                        <input
                          type="number"
                          onChange={(e) => this.setYearForParam(e)}
                          name="startYear"
                          value={this.state.startYear}
                          className="form-control"
                          onKeyPress={(event) =>
                            this.preventUnwantedNumericChars(event)
                          }
                        />
                      </div>
                      <span>
                        {this.state.formErrors.startYear !== "" ? (
                          <FormErrors
                            formErrors={this.state.formErrors.startYear}
                          />
                        ) : null}
                      </span>
                    </div>
                  </div>
                </FormGroup>

                <FormGroup className="pt-1">
                  <div className="row">
                    <label className="col-sm-4 col-form-label">
                      End Year (31 Dec.)
                    </label>
                    <div className="col-sm-8">
                      <div
                        className={`form-group ${this.errorClass(
                          this.state.formErrors.endYear
                        )}`}
                      >
                        <input
                          type="number"
                          onChange={(e) => this.setYearForParam(e)}
                          name="endYear"
                          value={this.state.endYear}
                          className="form-control"
                          onKeyPress={(event) =>
                            this.preventUnwantedNumericChars(event)
                          }
                        />
                      </div>
                      <span>
                        {this.state.formErrors.endYear !== "" ? (
                          <FormErrors
                            formErrors={this.state.formErrors.endYear}
                          />
                        ) : null}
                      </span>
                    </div>
                  </div>
                </FormGroup>

                <FormGroup className="pt-1">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className={`form-group`}>
                        <div className="form-check form-check-inline">
                          <label>
                            <input
                              type="checkbox"
                              className="margin-bottom-16"
                              name="includeOutlook"
                              value={false}
                              checked={this.state.includeOutlook}
                              onChange={(e) =>
                                this.setState({
                                  includeOutlook: e.target.checked,
                                })
                              }
                            />{" "}
                            Include outlook from external rating agencies*
                          </label>
                        </div>
                        <label className="outlook-help">
                          * Applicable only for Sovereign counterparts
                        </label>
                      </div>
                    </div>
                  </div>
                </FormGroup>

                <div className="pd-mm-footer-margin lib-model-foot">
                  <Button
                    className="btn btn-default"
                    type="submit"
                  >
                    OK
                  </Button>
                  <Button
                    className="btn btn-cancel"
                    onClick={this.cancelToggle}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        ) : null}

        {this.state.MMmodal ? (
          <Modal
            isOpen={this.state.MMmodal}
            toggle={this.toggleModal.bind(this, "MMmodal")}
            backdrop={false}
          >
            <ModalHeader toggle={this.cancelToggleModal.bind(this, "MMmodal")}>
              MM Calculation Parameters
            </ModalHeader>
            <ModalBody>
              <Form
                className="lib-model"
                onSubmit={(e) =>
                  this.computeMM(
                    e,
                    this.props.allParams,
                    CAL_ACTION_COMPUTE_TAP
                  )
                }
              >
                <FormGroup className="pt-1">
                  <div className="row">
                    <label className="col-sm-4 col-form-label">
                      Starting Year
                    </label>
                    <div className="col-sm-8">
                      <div
                        className={`form-group ${this.errorClass(
                          this.state.formErrors.startYear
                        )}`}
                      >
                        <input
                          type="number"
                          onChange={(e) => this.setYearForParam(e)}
                          name="startYear"
                          value={this.state.startYear}
                          className="form-control"
                          onKeyPress={(event) =>
                            this.preventUnwantedNumericChars(event)
                          }
                        />
                      </div>
                      <span>
                        {this.state.formErrors.startYear !== "" ? (
                          <FormErrors
                            formErrors={this.state.formErrors.startYear}
                          />
                        ) : null}
                      </span>
                    </div>
                  </div>
                </FormGroup>

                <FormGroup className="pt-1">
                  <div className="row">
                    <label className="col-sm-4 col-form-label">
                      End Year (31 Dec.)
                    </label>
                    <div className="col-sm-8">
                      <div
                        className={`form-group ${this.errorClass(
                          this.state.formErrors.endYear
                        )}`}
                      >
                        <input
                          type="number"
                          onChange={(e) => this.setYearForParam(e)}
                          name="endYear"
                          value={this.state.endYear}
                          className="form-control"
                          onKeyPress={(event) =>
                            this.preventUnwantedNumericChars(event)
                          }
                        />
                      </div>

                      <span>
                        {this.state.formErrors.endYear !== "" ? (
                          <FormErrors
                            formErrors={this.state.formErrors.endYear}
                          />
                        ) : null}
                      </span>
                    </div>
                  </div>
                </FormGroup>

                <FormGroup className="pt-1">
                  <div className="row">
                    <label className="col-sm-4 col-form-label">
                      Enter the Period
                    </label>
                    <div className="col-sm-8">
                      <div
                        className={`form-group ${this.errorClass(
                          this.state.formErrors.period
                        )}`}
                      >
                        <input
                          type="number"
                          onChange={(e) => this.setFieldValues(e, 3)}
                          name="period"
                          value={this.state.period}
                          className="form-control"
                          onKeyPress={(event) =>
                            this.preventUnwantedNumericChars(event)
                          }
                        />
                      </div>
                      <span>
                        {this.state.formErrors.period !== "" ? (
                          <FormErrors
                            formErrors={this.state.formErrors.period}
                          />
                        ) : null}
                      </span>
                    </div>
                  </div>
                </FormGroup>

                <FormGroup className="pt-1">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className={`form-group`}>
                        <div className="form-check form-check-inline">
                          <label>
                            <input
                              type="checkbox"
                              className="margin-bottom-16"
                              name="includeOutlook"
                              value={false}
                              checked={this.state.includeOutlook}
                              onChange={(e) =>
                                this.setState({
                                  includeOutlook: e.target.checked,
                                })
                              }
                            />{" "}
                            Include outlook from external rating agencies*
                          </label>
                        </div>
                        <label className="outlook-help">
                          * Applicable only for Sovereign counterparts
                        </label>
                      </div>
                    </div>
                  </div>
                </FormGroup>

                <div className="pd-mm-footer-margin lib-model-foot">
                  <Button className="btn btn-default" type="submit">
                    OK
                  </Button>
                  <Button
                    className="btn btn-cancel"
                    onClick={this.cancelToggleModal.bind(this, "MMmodal")}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        ) : null}

        {this.state.RRmodal ? (
          <Modal
            isOpen={this.state.RRmodal}
            toggle={this.toggleModal.bind(this, "RRmodal")}
            backdrop={false}
          >
            <ModalHeader toggle={this.cancelToggleModal.bind(this, "RRmodal")}>
              RR Calculation Parameters
            </ModalHeader>
            <ModalBody>
              <Form
                className="lib-model"
                onSubmit={(e) =>
                  this.computeRR(
                    e,
                    this.props.allParams,
                    CAL_ACTION_COMPUTE_TAP
                  )
                }
              >
                <FormGroup className="pt-1">
                  <div className="row">
                    <label className="col-sm-5 col-form-label">
                      Starting Year
                    </label>
                    <div className="col-sm-7">
                      <div
                        className={`form-group ${this.errorClass(
                          this.state.formErrors.startYear
                        )}`}
                      >
                        <input
                          type="number"
                          onChange={(e) => this.setYearForParam(e)}
                          name="startYear"
                          value={this.state.startYear}
                          className="form-control"
                          onKeyPress={(event) =>
                            this.preventUnwantedNumericChars(event)
                          }
                        />
                      </div>
                      <span>
                        {this.state.formErrors.startYear !== "" ? (
                          <FormErrors
                            formErrors={this.state.formErrors.startYear}
                          />
                        ) : null}
                      </span>
                    </div>
                  </div>
                </FormGroup>

                <FormGroup className="pt-1">
                  <div className="row">
                    <label className="col-sm-5 col-form-label">
                      Resolution before (31 Dec.)
                    </label>
                    <div className="col-sm-7">
                      <div
                        className={`form-group ${this.errorClass(
                          this.state.formErrors.endYear
                        )}`}
                      >
                        <input
                          type="number"
                          onChange={(e) => this.setYearForParam(e)}
                          name="endYear"
                          value={this.state.endYear}
                          className="form-control"
                          onKeyPress={(event) =>
                            this.preventUnwantedNumericChars(event)
                          }
                        />
                      </div>
                      <span>
                        {this.state.formErrors.endYear !== "" ? (
                          <FormErrors
                            formErrors={this.state.formErrors.endYear}
                          />
                        ) : null}
                      </span>
                    </div>
                  </div>
                </FormGroup>

                <FormGroup className="pt-1">
                  <div className="row">
                    <label className="col-sm-5 col-form-label">
                      Nb Days before Default
                    </label>
                    <div className="col-sm-7">
                      <div
                        className={`form-group ${this.errorClass(
                          this.state.formErrors.nodaysbeforedefault
                        )}`}
                      >
                        <input
                          type="number"
                          onChange={(e) => this.setFieldValues(e, 3)}
                          name="nodaysbeforedefault"
                          value={this.state.nodaysbeforedefault}
                          className="form-control"
                          onKeyPress={(event) =>
                            this.preventUnwantedNumericChars(event)
                          }
                        />
                      </div>
                      <span>
                        {this.state.formErrors.nodaysbeforedefault !== "" ? (
                          <FormErrors
                            formErrors={
                              this.state.formErrors.nodaysbeforedefault
                            }
                          />
                        ) : null}
                      </span>
                    </div>
                  </div>
                </FormGroup>
                  {this.props.userData ? (
                    <FormGroup className="pt-1">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className={`form-group`}>
                            <div className="form-check form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="includeUnresolvedContracts"
                                  value={false}
                                  checked={!this.state.includeUnresolvedContracts}
                                  onChange={(e) => this.handleInput(e)}
                                />{" "}
                                Exclude unresolved contracts
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Inclusion of option 'Include resolved contracts' with its options depends on filter selection on catetory!!
                          Using categoryMap for easier understanding and not having to know the IDs (magic number)
                          Further, using Object.keys(resolutionTypeMap).length means that all values resolutionType are checked*/}
                      {(this.props.allParams.category.includes(this.props.categoryMap.PRIVATE) ||
                        this.props.allParams.category.includes(this.props.categoryMap.PUBLIC) ||
                        this.props.allParams.category.length == 0 ) && 
                      (this.props.allParams.resolutionType.length == 0 ||
                        this.props.allParams.resolutionType.length == Object.keys(this.props.resolutionTypeMap).length) ?
                      (
                        <div className="row">
                          <div className="col-sm-6  col-form-label">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    name="includeUnresolvedContracts"
                                    value={true}
                                    checked={this.state.includeUnresolvedContracts}
                                    onChange={(e) => this.handleInput(e)}
                                  />{" "}
                                  Include unresolved contracts
                                </label>
                          </div>
                          <label 
                            className="col-sm col-form-label"
                            style={this.state.includeUnresolvedContracts ? {}:{fontStyle: 'italic', color: 'grey'}}> threshold (Y)
                          </label>
                          <div className="col-sm">
                            <div
                              className={`form-group ${this.errorClass(
                                this.state.formErrors.thresholdUnresolvedContracts
                              )}`}
                            >
                              <input
                                type="number"
                                disabled = {!this.state.includeUnresolvedContracts}
                                onChange={(e) => this.setFieldValues(e, 2)}
                                name="thresholdUnresolvedContracts"
                                value={this.state.thresholdUnresolvedContracts}
                                className="form-control"
                                onKeyPress={(event) =>
                                  this.preventUnwantedNumericChars(event)
                                }
                              />
                            </div>
                            <span>
                              {this.state.formErrors.thresholdUnresolvedContracts !== "" ? (
                                <FormErrors
                                  formErrors={
                                    this.state.formErrors.thresholdUnresolvedContracts
                                  }
                                />
                              ): null }
                            </span>
                          </div>
                        </div>)
                        : null}  {/*  row of option 'include unresolved contracts' may have to be null depending on filter selection*/}
                    </FormGroup>
                  ) : (
                    this.props.callUserDetails()
                  )}

                <div className="mt-2 lib-model-foot">
                  <Button className="btn btn-default" type="submit">
                    OK
                  </Button>
                  <Button
                    className="btn btn-cancel"
                    onClick={this.cancelToggleModal.bind(this, "RRmodal")}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        ) : null}

        {this.state.FCmodal ? (
          <Modal
            isOpen={this.state.FCmodal}
            toggle={this.toggleModal.bind(this, "FCmodal")}
            backdrop={false}
          >
            <ModalHeader toggle={this.cancelToggleModal.bind(this, "FCmodal")}>
              Full Compute Parameters
            </ModalHeader>
            <ModalBody>
              <Form
                className="lib-model"
                onSubmit={(e) =>
                  this.computeFC(
                    e,
                    this.props.allParams,
                    CAL_ACTION_COMPUTE_TAP
                  )
                }
              >
                <FormGroup className="pt-1">
                  <div className="row">
                    <label className="col-sm-5 col-form-label">
                      Starting Year
                    </label>
                    <div className="col-sm-7">
                      <div
                        className={`form-group ${this.errorClass(
                          this.state.formErrors.startYear
                        )}`}
                      >
                        <input
                          type="number"
                          onChange={(e) => this.setYearForParam(e)}
                          name="startYear"
                          value={this.state.startYear}
                          className="form-control"
                          onKeyPress={(event) =>
                            this.preventUnwantedNumericChars(event)
                          }
                        />
                      </div>
                      <span>
                        {this.state.formErrors.startYear !== "" ? (
                          <FormErrors
                            formErrors={this.state.formErrors.startYear}
                          />
                        ) : null}
                      </span>
                    </div>
                  </div>
                </FormGroup>

                <FormGroup className="pt-1">
                  <div className="row">
                    <label className="col-sm-5 col-form-label">
                      End Year (31 Dec.)
                    </label>
                    <div className="col-sm-7">
                      <div
                        className={`form-group ${this.errorClass(
                          this.state.formErrors.endYear
                        )}`}
                      >
                        <input
                          type="number"
                          onChange={(e) => this.setYearForParam(e)}
                          name="endYear"
                          value={this.state.endYear}
                          className="form-control"
                          onKeyPress={(event) =>
                            this.preventUnwantedNumericChars(event)
                          }
                        />
                      </div>
                      <span>
                        {this.state.formErrors.endYear !== "" ? (
                          <FormErrors
                            formErrors={this.state.formErrors.endYear}
                          />
                        ) : null}
                      </span>
                    </div>
                  </div>
                </FormGroup>

                <div className="mt-2 lib-model-foot">
                  <Button className="btn btn-default" type="submit">
                    OK
                  </Button>
                  <Button
                    className="btn btn-cancel"
                    onClick={this.cancelToggleModal.bind(this, "FCmodal")}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        ) : null}

        <h1 className="heading">Compute Options</h1>
        <div className="filter">
          <div className="env">
            <input
              id="Work_Environment"
              type="radio"
              name="isEoy"
              value={false}
              checked={!this.state.isEoy}
              onChange={(e) => this.handleInput(e)}
            />{" "}
            <label htmlFor="Work_Environment">Work Environment</label>
          </div>
          <div className="env">
            <input
              id="Annual_Report_Environment"
              type="radio"
              name="isEoy"
              value={true}
              checked={this.state.isEoy}
              onChange={(e) => this.handleInput(e)}
            />{" "}
            <label htmlFor="Annual_Report_Environment">
              Annual Report Environment
            </label>
          </div>
          <h4>Report Period</h4>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                id="New_Period"
                name="isNewPeriod"
                type="radio"
                
                value={true}
                checked={this.state.isNewPeriod}
                onChange={(e) => this.handleInput(e)}
              />{" "}
              <label htmlFor="New_Period">New Period</label>
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                id="Old_Period"
                name="isNewPeriod"
                type="radio"
                
                value={false}
                checked={!this.state.isNewPeriod}
                onChange={(e) => this.handleInput(e)}
              />{" "}
              <label htmlFor="Old_Period">Old Period</label>
            </label>
          </div>

          <h4>Default Period</h4>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                id="90days"
                name="isDefault180"
                type="radio"
                
                value={false}
                checked={!this.state.isDefault180}
                onChange={(e) => this.handleInput(e)}
              />{" "}
              <label htmlFor="90days">90 days</label>
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                id="180days"
                name="isDefault180"
                type="radio"
                
                value={true}
                checked={this.state.isDefault180}
                onChange={(e) => this.handleInput(e)}
              />{" "}
              <label htmlFor="180days">180 days</label>
            </label>
          </div>
        </div>
        <div className="compute-btn">
          <button
            className="btn btn-default"
            onClick={() => this.openParamsModal("RRmodal")}
          >
            Compute RR
          </button>
          <button
            className="btn btn-default"
            onClick={() => this.openParamsModal("MMmodal")}
          >
            Compute MM
          </button>
          <button
            className="btn btn-default"
            onClick={(e) => this.openParamsModal("modal")}
          >
            Compute PD
          </button>
          {this.props.userData ? (
            this.props.userData.userRole === 1 ? (
              <button
                className="btn btn-default"
                onClick={() => this.openParamsModal("FCmodal")}
              >
                Full Compute
              </button>
            ) : null
          ) : (
            this.props.callUserDetails()
          )}
        </div>
      </div>
    );
  }
}

ComputeOption.propsType = {
  computeCallback: PropTypes.func,
  beingExecutedCallback: PropTypes.func,
  deleteBeingExecutedCallbackIfError: PropTypes.func,
  envCallback: PropTypes.func,
  synchronizeParamOnEnvChange: PropTypes.func,
  clearFocusDd: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,
    savedQueryPlaylist: state.auth.savedQueryPlaylist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSavedQueryPlaylist: (savedQueryPlaylist) => {
      dispatch(actions.setSavedQueryPlaylist(savedQueryPlaylist));
    },
    callUserDetails: () => {
      dispatch(actions.callUserDetails());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComputeOption);
